import { React } from 'react';

function View() {


    return (
        <>
            <div className='top_heading_button'>
                <h1 className='main_heading_top'>CMS View</h1>
            </div>

            <div className='cms_page_common_section'>
            <h1>Lorem ipsum dolor sit amet.</h1>
            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Fuga recusandae dolor soluta quisquam eligendi saepe id, officiis, aliquid voluptates excepturi temporibus atque ut. Labore distinctio sunt expedita minus debitis iure fuga facilis totam, eaque accusantium voluptatum amet, ullam quaerat iste eum aliquid pariatur sapiente vero tempore exercitationem odio laudantium magnam.</p>

            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Fuga recusandae dolor soluta quisquam eligendi saepe id, officiis, aliquid voluptates excepturi temporibus atque ut. Labore distinctio sunt expedita minus debitis iure fuga facilis totam, eaque accusantium voluptatum amet, ullam quaerat iste eum aliquid pariatur sapiente vero tempore exercitationem odio laudantium magnam.</p>
            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Fuga recusandae dolor soluta quisquam eligendi saepe id, officiis, aliquid voluptates excepturi temporibus atque ut. Labore distinctio sunt expedita minus debitis iure fuga facilis totam, eaque accusantium voluptatum amet, ullam quaerat iste eum aliquid pariatur sapiente vero tempore exercitationem odio laudantium magnam.</p>
            <h1>Lorem ipsum dolor sit.</h1>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quidem fugiat sit illo quae. Ullam molestiae numquam aperiam quia quos iusto!</p>
            <h1>Lorem ipsum dolor sit amet consectetur adipisicing elit.</h1>
            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Fuga recusandae dolor soluta quisquam eligendi saepe id, officiis, aliquid voluptates excepturi temporibus atque ut. Labore distinctio sunt expedita minus debitis iure fuga facilis totam, eaque accusantium voluptatum amet, ullam quaerat iste eum aliquid pariatur sapiente vero tempore exercitationem odio laudantium magnam.</p>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam fugiat architecto impedit officiis dolorum quibusdam adipisci illo ipsum cumque nisi!</p>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit tempora aperiam porro harum nesciunt suscipit atque necessitatibus itaque quam totam, aut sunt ullam nam odio accusamus iusto, quia minima eum architecto illum nihil deserunt ipsum at? Molestiae, necessitatibus quidem laboriosam consequatur quibusdam repudiandae sint facilis, non aspernatur itaque esse obcaecati!</p>

            <h1>Lorem ipsu adipisicing elit. Deserunt, esse!</h1>
            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Fuga recusandae dolor soluta quisquam eligendi saepe id, officiis, aliquid voluptates excepturi temporibus atque ut. Labore distinctio sunt expedita minus debitis iure fuga facilis totam, eaque accusantium voluptatum amet, ullam quaerat iste eum aliquid pariatur sapiente vero tempore exercitationem odio laudantium magnam.</p>
            <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Libero incidunt atque reprehenderit hic ipsa cum, modi eaque distinctio possimus in culpa numquam architecto. Quasi doloribus quidem maxime mollitia et inventore, quibusdam accusantium rerum excepturi sint unde repellat voluptas ut. Minima.</p>

            <h1>Lorem ipsum dolor </h1>
            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Fuga recusandae dolor soluta quisquam eligendi saepe id, officiis, aliquid voluptates excepturi temporibus atque ut. Labore distinctio sunt expedita minus debitis iure fuga facilis totam, eaque accusantium voluptatum amet, ullam quaerat iste eum aliquid pariatur sapiente vero tempore exercitationem odio laudantium magnam.</p>
            <h1>Lorem ipsum dolor sit amet consectetur adipisicing elit. Deserunt, esse!</h1>
            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Fuga recusandae dolor soluta quisquam eligendi saepe id, officiis, aliquid voluptates excepturi temporibus atque ut. Labore distinctio sunt expedita minus debitis iure fuga facilis totam, eaque accusantium voluptatum amet, ullam quaerat iste eum aliquid pariatur sapiente vero tempore exercitationem odio laudantium magnam.</p>
      </div>

        </>
    )
}

export default View;