import React, { useState } from 'react';
import "../../components/Modal/Modal.scss";
import { Formik, Form } from 'formik';
import { InputText } from '../../components/Common/InputText';
import * as Yup from "yup";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { Regex } from '../../utils/Regex';
import { PaymentGatewayIntegrationAction } from '../../redux/Slices/CommonSlice';
import { Button } from '../../components/Common/Button';
function AddAmount(props) {
    const { onClickButton1, heading, setStatusUpdate } = props
    const [loader, setLoader] = useState(false);
    return (
        <>
            <div className='delete_content_camp pb-0'>
                <h1>{heading}</h1>
                <Formik
                    initialValues={{
                        amount: '',
                    }}
                    validationSchema={Yup.object().shape({
                        amount: Yup.string().min(2, "Value must be greater then 1 USD and less to 4,000 USD").max(6, "'Value must be greater then 1 USD and less to 4,000 USD'").matches(Regex.ALLOW_NUMBER_ONLY, 'Value must be greater then 9 and less to 1 lakh').required('Required')
                    })}
                    onSubmit={(value) => {
                        console.log("value", value)
                    }}
                >
                    {(formik) => {
                        return (
                            <Form>
                                <div className='amount_add_section'>
                                    <InputText
                                        name="amount"
                                        type="number"
                                        placeholder='Amount'
                                        value={formik.values?.amount}
                                        className="common_input_filed"
                                    />
                                </div>

                                <div className='new_Campaigns_input tow_btn_row'>
                                     {/* <Button
                                        className="btn_blue"
                                        text='Submit'
                                        type="submit"
                                        loader={loader}
                                        disabled={!(formik.isValid && formik.dirty) || loader}
                                    />  */}

                                    <PayPalScriptProvider
                                        options={{ "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID }}
                                    >
                                        <PayPalButtons
                                            createOrder={(_, actions) => {
                                                return actions.order
                                                    .create({
                                                        purchase_units: [
                                                            {
                                                                amount: {
                                                                    value: formik.values?.amount,
                                                                },
                                                            },
                                                        ],
                                                    })
                                                    .then((orderId) => {
                                                        return orderId;
                                                    });
                                            }}
                                            forceReRender={[formik.values?.amount]}
                                            className="w-100"
                                            onApprove={(_, actions) => {
                                                return actions.order.capture().then((details) => {
                                                    let data = {
                                                        amount: formik.values?.amount,
                                                        order_id: details.id,
                                                        order_status: details.status,
                                                        payer_detail: details.payer
                                                    }
                                                    setLoader(true)
                                                    PaymentGatewayIntegrationAction(data, (response) => {
                                                        if (response?.status == true) {
                                                            onClickButton1();
                                                            setStatusUpdate(prev => prev + 1)
                                                        }
                                                        setLoader(false)
                                                    })();
                                                });
                                            }}
                                            style={{
                                                layout: 'horizontal',
                                                color: 'blue',
                                                shape: 'rect',
                                                label: 'pay',
                                                height: 48,
                                                tagline: false,
                                                width: "100%"
                                            }}
                                            disabled={!(formik.isValid && formik.dirty) || loader}
                                        />
                                    </PayPalScriptProvider>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>

        </>
    )
}

export default AddAmount;