import React from 'react'
import { useNavigate } from "react-router-dom";
import './Auth.scss'
import { SocialLoginAction } from '../../redux/Slices/AuthSlice';
import { useDispatch } from 'react-redux';
import socialMediaAuth from './SocialAuth';
import { FcGoogle } from 'react-icons/fc';
import { googleProvider } from './firebase';


function SocialLoginButton({btnText}) {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleSocialLogin = async (Provider) => {
        let UserData = null;
        try {
            const res = await socialMediaAuth(Provider);
            UserData = res;
            
            if (UserData?.name !== 'FirebaseError' || UserData?._tokenResponse?.displayName !== undefined) {
                let data = {
                    name: UserData?._tokenResponse?.displayName,
                    email: UserData?._tokenResponse?.email,
                    profile_image: UserData?._tokenResponse?.photoUrl,
                    google_id: UserData?.user?.uid,
                };

                SocialLoginAction(data, (response) => {
                    if (response?.status === true) {
                        navigate("/campaigns");
                    } else {
                        // Handle login error
                    }
                }, dispatch)();
            }
        } catch (error) {
            console.error('Error:', error);
            // Handle the error here
        }
    };


    return (
        <>
            <button type='button' className='btn sign-in' onClick={() => handleSocialLogin(googleProvider)}><FcGoogle />{btnText} with Google</button>
        </>
    )
}

export default SocialLoginButton
