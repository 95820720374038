import { React, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Form, Formik, useFormikContext } from 'formik';
import * as Yup from "yup";
import { InputText } from '../../../components/Common/InputText';
import { Button } from '../../../components/Common/Button';
import { emailUpdateAction, emailViewAction } from '../../../redux/AdminSlices/Common';
import { ToastOverError } from '../../../components/Common/ToastOver';
import { checkKeysReplaced } from '../../../utils/Function';

function EditEmailManagement() {
    const { id } = useParams()
    const navigate = useNavigate()
    const [loader, setLoader] = useState(false)
    const [ckeditorData, setCkeditorData] = useState()
    const [formDataSaved, setFormDataSaved] = useState(false)
    const [emailViewData, setEmailViewData] = useState()

    useEffect(() => {
        emailViewAction({ _id: id }, (response) => {
            if (response?.status === true) {
                setEmailViewData(response.data)
            }
        })();
    }, [emailViewAction])

    const FormikFromFunc = () => {
        const formikFrom = useFormikContext();
        useEffect(() => {
            if (emailViewData && formDataSaved === false) {
                formikFrom.setValues({
                    title: emailViewData?.title,
                })
                setCkeditorData(emailViewData?.description)
                setFormDataSaved(true)
            }
        }, [emailViewData])
    }
    return (
        <>
            <div className='top_heading_button'>
                <h1 className='main_heading_top'> Email Edit </h1>
            </div>

            <div className='admin_main_content_section edit_cms_page'>
                <Formik
                    initialValues={{
                        title: '',
                    }}
                    validationSchema={Yup.object().shape({
                        title: Yup.string().required("Title is Required!"),
                    })}
                    onSubmit={(value) => {
                        if (emailViewData?.keys && checkKeysReplaced(ckeditorData, emailViewData?.keys)) {
                            const payload = {
                                title: value?.title,
                                _id: id,
                                description: ckeditorData
                            }
                            setLoader(true)
                            emailUpdateAction(payload, (response) => {
                                if (response?.status === true) {
                                    navigate("/email-management")
                                }
                                setLoader(false)
                            })();
                        } else {
                            ToastOverError("You don't change the dynamic variables on the description field")
                        }
                    }}
                >
                    {(formik) => {
                        return (
                            <Form>
                                <FormikFromFunc />
                                <div className='row'>
                                    <div className='col-12 mb-4 mb-md-5'>
                                        <InputText
                                            name="title"
                                            type="text"
                                            placeholder='Title'
                                            label="Title"
                                            value={formik.values?.title}
                                            className="common_input_filed"
                                        />
                                    </div>
                                    <div className='col-12 mb-4 mb-md-5'>
                                        <CKEditor
                                            editor={ClassicEditor}
                                            data={ckeditorData}
                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                setCkeditorData(data)
                                            }}
                                        />
                                    </div>
                                    <div className='col-12'>
                                        <div className='cancel_submit_btn_admin'>
                                            <Link to="/email-management" className='btn_blue'>Cancel</Link>
                                            <Button
                                                className="btn_blue"
                                                text='Submit'
                                                type="submit"
                                                loader={loader}
                                                disabled={!(formik.isValid && formik.dirty) || loader}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>

        </>
    )
}

export default EditEmailManagement;