import React, { useEffect, useState } from 'react';
import { AiOutlineSound } from 'react-icons/ai';
import { HiOutlineTicket, HiOutlineUser } from 'react-icons/hi';
import { MdOutlineSpeakerPhone } from 'react-icons/md';
import CanvasJSReact from '@canvasjs/react-charts';
import { DashboardAction } from '../../../redux/AdminSlices/Common';

var CanvasJSChart = CanvasJSReact.CanvasJSChart;
function Dashboard() {
    const [dashboardData, setDashboardData] = useState({})

    useEffect(() => {
        DashboardAction((response) => {
            if (response?.status === true) {
                setDashboardData(response?.data)
            } else {
            }
        })();
    }, [DashboardAction])

    const calculatePercentage = (current, total) => {
        return (current / total) * 100
    }

    const options = {
        animationEnabled: true,
        indexLabelFontSize: 45,
        title: {
            text: "Manager & Marketer"
        },
        subtitles: [{
            text: "",
            verticalAlign: "center",
            fontSize: 24,
            dockInsidePlotArea: true
        }],
        data: [{
            type: "doughnut",
            showInLegend: true,
            indexLabelFontSize: 18,
            indexLabel: "{name}: {y}",
            yValueFormatString: "#,###'%'",
            dataPoints: [
                { name: "Marketer", y: calculatePercentage(dashboardData?.totalmarketer, (dashboardData?.totalmarketer + dashboardData?.totalmanager)) },
                { name: "Manager", y: calculatePercentage(dashboardData?.totalmanager, (dashboardData?.totalmarketer + dashboardData?.totalmanager)) },
            ]
        }]
    }

    const options2 = {
        animationEnabled: true,
        // exportEnabled: true,
        title: {
            text: "Campaigns"
        },
        data: [{
            // type: "line",
            showInLegend: true,
            legendText: "{label}",
            toolTipContent: "{label}: <strong>{y}%</strong>",
            indexLabel: "{y}%",
            indexLabelPlacement: "inside",
            dataPoints: [
                { y: 10, label: "Browse Campaigns" },
                { y: 10, label: "Joined Campaigns" },
                { y: 10, label: "Request Campaigns" },
                { y: 10, label: "Report" },
            ]
        }]
    }


    return (
        <>
            <div className='top_heading_button'>
                <h1 className='main_heading_top'>Dashboard</h1>
            </div>

            <div className='dashboard_page_admin admin_list_table'>
                <div className='row g-5'>
                    <div className="col-lg-6 col-xl-4">
                        <div className="custom-card">
                            <div className="card-body2">
                                <div className="icon_card">
                                    <span className="icon_box"> <HiOutlineUser /> </span>
                                </div>
                                <div className="text_card">
                                    <div className="title_name">Total Manager</div>
                                    <div className="text-count"> <span className="text-dark fw-semibold fs-20 lh-1 vertical-bottom">
                                        {dashboardData?.totalmanager ? dashboardData?.totalmanager : 0}
                                    </span> </div>
                                    {/* <div> <span className="small_text">Increase by <span className="percentage_text">+4.2%</span> this month</span> </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-xl-4">
                        <div className="custom-card three">
                            <div className="card-body2">
                                <div className="icon_card">
                                    <span className="icon_box three"> <AiOutlineSound /> </span>
                                </div>
                                <div className="text_card">
                                    <div className="title_name">Total Campaigns</div>
                                    <div className="text-count"> <span className="text-dark fw-semibold fs-20 lh-1 vertical-bottom">
                                        {dashboardData?.totatcampaing ? dashboardData?.totatcampaing : 0}
                                    </span> </div>
                                    {/* <div> <span className="small_text">Increase by <span className="percentage_text three">+5.2%</span> this month</span> </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-xl-4">
                        <div className="custom-card tow">
                            <div className="card-body2">
                                <div className="icon_card">
                                    <span className="icon_box tow"> <MdOutlineSpeakerPhone /> </span>
                                </div>
                                <div className="text_card">
                                    <div className="title_name">Total Marketer</div>
                                    <div className="text-count"> <span className="text-dark fw-semibold fs-20 lh-1 vertical-bottom">
                                        {dashboardData?.totalmarketer ? dashboardData?.totalmarketer : 0}
                                    </span> </div>
                                    {/* <div> <span className="small_text">Increase by <span className="percentage_text tow">+7.2%</span> this month</span> </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-xl-4">
                        <div className="custom-card tow">
                            <div className="card-body2">
                                <div className="icon_card">
                                    <span className="icon_box tow"> <HiOutlineTicket /> </span>
                                </div>
                                <div className="text_card">
                                    <div className="title_name">Total Ticket</div>
                                    <div className="text-count"> <span className="text-dark fw-semibold fs-20 lh-1 vertical-bottom">
                                        {dashboardData?.totatticket ? dashboardData?.totatticket : 0}
                                    </span> </div>
                                    {/* <div> <span className="small_text">Increase by <span className="percentage_text three">+5.2%</span> this month</span> </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className='row g-5'>
                            <div className='col-lg-6 col-xl-6'>
                                <div className='cart_manager_marketer'>
                                    <CanvasJSChart options={options} />
                                </div>
                            </div>
                            <div className='col-lg-6 col-xl-6'>
                                <div className='cart_manager_marketer'>
                                    <CanvasJSChart options={options2} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Dashboard;