import { React, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Form, Formik, useFormikContext } from 'formik';
import * as Yup from "yup";
import { InputText, TextArea } from '../../../components/Common/InputText';
import { Button } from '../../../components/Common/Button';
import { NotificationUpdateAction } from '../../../redux/AdminSlices/Common';
import { checkKeysReplaced } from '../../../utils/Function';
import { ToastOverError } from '../../../components/Common/ToastOver';

function EditNotificationManagement() {
    const { id } = useParams();
    const { state } = useLocation()
    const navigate = useNavigate()
    const [loader, setLoader] = useState(false)
    const [formDataSaved, setFormDataSaved] = useState(false)

    const FormikFromFunc = () => {
        const formikFrom = useFormikContext();
        useEffect(() => {
            if (state && formDataSaved === false) {
                formikFrom.setValues({
                    type: state?.title,
                    description: state?.description
                })
                setFormDataSaved(true)
            }
        }, [state])
    }

    return (
        <>
            <div className='top_heading_button'>
                <h1 className='main_heading_top'> Email Edit </h1>
            </div>

            <div className='admin_main_content_section edit_cms_page'>
                <Formik
                    initialValues={{
                        type: '',
                        description: '',
                    }}
                    validationSchema={Yup.object().shape({
                        description: Yup.string().required("Description is Required!"),
                    })}
                    onSubmit={(value) => {
                        if (state?.keys && checkKeysReplaced(value?.description, state?.keys)) {
                            const payload = {
                                _id: id,
                                description: value?.description
                            }
                            setLoader(true)
                            NotificationUpdateAction(payload, (response) => {
                                if (response?.status === true) {
                                    navigate("/notification-management")
                                }
                                setLoader(false)
                            })();
                        }else{
                            ToastOverError("You don't change the dynamic variables on the description field")
                        }
                    }}
                >
                    {(formik) => {
                        return (
                            <Form>
                                <FormikFromFunc />
                                <div className='row'>
                                    <div className='col-12 mb-4 mb-md-5'>
                                        <InputText
                                            name="type"
                                            type="text"
                                            placeholder='Type'
                                            label="Type"
                                            value={formik.values?.type}
                                            className="common_input_filed"
                                            disabled
                                        />
                                    </div>
                                    <div className='col-12 mb-4 mb-md-5'>
                                        <TextArea
                                            label="Description"
                                            name="description"
                                            className='text_area_common'
                                            rows="4"
                                            maxlength={500}
                                            value={formik.values.description}
                                        />
                                    </div>
                                    <div className='col-12'>
                                        <div className='cancel_submit_btn_admin'>
                                            <Link to="/email-management" className='btn_blue'>Cancel</Link>
                                            <Button
                                                className="btn_blue"
                                                text='Submit'
                                                type="submit"
                                                loader={loader}
                                                disabled={!(formik.isValid && formik.dirty) || loader}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>

        </>
    )
}

export default EditNotificationManagement;