import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../../components/Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import Footer from '../Footer/Footer';
import './TheLayout.scss';

function TheLayout() {
  return (
    <>
      <div id='main_top_section'>
        <Header />
        <div className='main_content_section'>
          <Outlet />
          <Footer />
        </div>
        <Sidebar />
      </div>
    </>
  )
}

export default TheLayout;