import React, { useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom';
import { AiOutlineSound, AiOutlineVideoCamera } from 'react-icons/ai';
import { TbFileSettings, TbReportSearch } from 'react-icons/tb';
import { MdCreditScore, MdOutlineDashboard, MdOutlineMailOutline, MdOutlineProductionQuantityLimits, MdOutlineSpeakerPhone } from 'react-icons/md';
import { IoMdNotificationsOutline } from 'react-icons/io';
import { HiOutlineTicket, HiOutlineUser } from 'react-icons/hi';
import { FiSettings } from "react-icons/fi";
import { IoCloseSharp } from "react-icons/io5";
import '../Sidebar/Sidebar.scss';
import { useSelector } from 'react-redux';

const AdminSidebar = () => {

  const [switchState, setSwitchState] = useState(false)
  const { adminProfile } = useSelector((state) => state?.common)

  useEffect(() => {
    if (switchState) {
      document.body.classList.remove('side_nav_open');
    } else {
      document.body.classList.remove('side_nav_open')
    }
  }, [switchState]);

  const handleToggleButton = () => {
    setSwitchState(!switchState)
  }


  return (
    <div className='Side_bar_section'>
      <button type='button' className='close_sidebar_cross' onClick={handleToggleButton} ><IoCloseSharp /></button>
      <div className='logo_side_bar'>
        <Link to="/"><img className='side_bar_open_logo' src="/images/glide_scale_logo.svg" alt="" />
          <img className='side_bar_close_logo' src="/images/logo_mini.svg" alt="" /></Link>
      </div>

      <div className='menu_card_sidebar'>
        <NavLink className='menu_item' to='/admin' onClick={handleToggleButton}> <MdOutlineDashboard /> <span> Dashboard </span></NavLink>
        <NavLink className='menu_item' to='/users-management' onClick={handleToggleButton}> <HiOutlineUser /> <span> Users Management </span></NavLink>
        <NavLink className='menu_item' to='/campaigns-management' onClick={handleToggleButton}>  <AiOutlineSound /> <span> Campaigns Management </span></NavLink>
        {/* <NavLink className='menu_item' to='/credits-management' onClick={handleToggleButton}> <MdCreditScore /> <span>Credits Management</span></NavLink> */}
        <NavLink className='menu_item' to='/type-of-product-management' onClick={handleToggleButton}> <MdOutlineProductionQuantityLimits /> <span> Types of Product </span></NavLink>
        <NavLink className='menu_item' to='/ticket-support-management' onClick={handleToggleButton}> <HiOutlineTicket /> <span className='inner-flex'> Ticket Support  {adminProfile?.unread__message_count != 0 ? <small className='count'>{adminProfile?.unread__message_count}</small> : ""} </span> </NavLink>
        <NavLink className='menu_item' to='/watch-tutorial-list' onClick={handleToggleButton}> <AiOutlineVideoCamera /> <span> Watch Tutorials Management </span></NavLink>
        <NavLink className='menu_item' to='/email-management' onClick={handleToggleButton}> <MdOutlineMailOutline /> <span> Email Management </span></NavLink>
        <NavLink className='menu_item' to='/notification-management' onClick={handleToggleButton}> <IoMdNotificationsOutline /> <span> Notification Management </span></NavLink>
        <NavLink className='menu_item' to='/report-management' onClick={handleToggleButton}> <TbReportSearch /> <span> Reports Management </span></NavLink>
        <NavLink className='menu_item' to='/cms-list' onClick={handleToggleButton}> <FiSettings /><span> CMS Management </span></NavLink>
        <NavLink className='menu_item' to='/transaction-list' onClick={handleToggleButton}> <FiSettings /><span> Transaction Management </span></NavLink>

        <NavLink className='menu_item' to='/qa-management' onClick={handleToggleButton}> <TbFileSettings /><span> Q&A Management </span></NavLink>
      </div>
    </div>
  )
}

export default AdminSidebar

