import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { CampaignManagementDetailAction } from "../../../redux/AdminSlices/Common";
import NoDataFound from "../../../components/NoDataFound/NoDataFound";
import { BsDownload } from "react-icons/bs";
import { HasConsecutiveSpaces, debounceSearch } from "../../../utils/Function";
import { Pagination } from "../../../components/Hooks/Pagination";

const CampaignManagementDetails = () => {
    const { id } = useParams();
    const [campaignDetailData, setCampaignDetailData] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [perPageCount, setPerPageCount] = useState(10);
    const [statusUpdate, setStatusUpdate] = useState(1)
    const [searchString, setSearchString] = useState("");

    useEffect(() => {
        let payload = {
            searchText: searchString,
            perPage: perPageCount,
            page: currentPage,
            "_id": id
        }
        CampaignManagementDetailAction(payload, (response) => {
            if (response?.status === true) {
                setCampaignDetailData(response?.data)
            }
        })();
    }, [CampaignManagementDetailAction, perPageCount, currentPage, statusUpdate, searchString])

    const handleSearchFilter = debounceSearch((e) => {
        if (HasConsecutiveSpaces(e.target.value)) return <></>;
        setSearchString(e.target.value)
    }, 300);

    return (
        <>
            <div className='top_heading_button'>
                <h1 className='main_heading_top'> Campaign Details </h1>
            </div>

            <div className='view_page_management'>
                <div className='profile_view_page'>
                    <div className='view_page_content_section mt-0'>
                        <div className='heading_description'>
                            <h1> Campaign name</h1>
                            <p> {campaignDetailData?.view?.campaign_name ? campaignDetailData?.view?.campaign_name : "-"} </p>
                        </div>

                        <div className='heading_description'>
                            <h1> Product Type </h1>
                            <p> {campaignDetailData?.view?.product_type?.type_name ? campaignDetailData?.view?.product_type?.type_name : "-"} </p>
                        </div>

                        <div className='heading_description'>
                            <h1> Tag </h1>
                            <p className="tag-block">
                                {campaignDetailData?.view?.tags ?
                                    JSON.parse(campaignDetailData?.view?.tags)?.map((value, index) =>
                                        <span key={index}> {value} </span>
                                    )
                                    : ""
                                }
                            </p>
                        </div>

                        <div className='heading_description'>
                            <h1> PPC Rate </h1>
                            <p> {campaignDetailData?.view?.ppc_rate ? campaignDetailData?.view?.ppc_rate : "-"} </p>
                        </div>
                    </div>

                    <div className='heading_description marketing_img_assets'>
                        <h1> Remaining Credit </h1>
                        <p> {campaignDetailData?.view?.no_of_credits ? campaignDetailData?.view?.no_of_credits : "0"} </p>
                    </div>

                    <div className='heading_description marketing_img_assets'>
                        <h1> Target Link </h1>
                        <p> {campaignDetailData?.view?.target_link ? campaignDetailData?.view?.target_link : "-"} </p>
                    </div>
                    <div className='heading_description marketing_img_assets'>
                        <h1> Description </h1>
                        <p> {campaignDetailData?.view?.description ? campaignDetailData?.view?.description : "-"} </p>
                    </div>
                    <div className='heading_description'>
                        <h1> Marketing Assets </h1>
                        <p> {campaignDetailData?.view?.marketing_assets ?
                            <img src={campaignDetailData?.view?.marketing_assets} alt={campaignDetailData?.view?.campaign_name} />
                            : "-"}
                        </p>
                    </div>
                </div>
            </div>



            <div className='request_campaigns_list common_tab_section'>
                <div className='filter_number_search_input'>
                    <h1 className='main_heading_top'> Marketer List </h1>
                    <div className='filter_select_number filter_search_common '>
                        <label className='common_label'>Show:</label>
                        <select className='common_input_filed' onChange={(e) => setPerPageCount(e.target.value)}>
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                        </select>
                    </div>

                    <div className='search_table_content filter_search_common'>
                        <label className='common_label'>Search:</label>
                        <div className="headerSearchBx">
                            <input
                                placeholder="Search"
                                aria-label="Search"
                                type="search"
                                className="common_input_filed"
                                onChange={handleSearchFilter}
                            />
                        </div>
                    </div>
                </div>
                <div className='content_campaigns_user table-responsive'>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th> Image </th>
                                <th> User Name </th>
                                <th> Phone </th>
                                <th> Email </th>
                                <th> Status </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                campaignDetailData?.join_list?.length > 0 ?
                                    campaignDetailData?.join_list?.map((value, index) => (
                                        <tr key={index}>
                                            <td> {(currentPage - 1) * Number(campaignDetailData?.pagination?.perPage) + (index + 1)}. </td>
                                            <td>
                                                <img
                                                    className='rounded-circle'
                                                    src={value.profile ? value.profile : "/images/dummy-image.jpg"}
                                                    alt={value.user_name}
                                                    width={50}
                                                    height={50}
                                                />
                                            </td>
                                            <td> {value?.user_name ? value?.user_name : "-"} </td>
                                            <td> {value?.phone ? value?.phone : "-"} </td>
                                            <td> {value?.email ? value?.email : "-"} </td>
                                            <td>
                                                <button
                                                    type='button'
                                                    className={`table_btn ${value?.status == 1 ? "active" : "reject"}`}
                                                // onClick={() => handleStatusUpdate(value._id, value.status)}
                                                >
                                                    {value?.status == 1 ? "Active" : "Inactive"}
                                                </button>
                                                {/* <button
                                                    type='button'
                                                    className={`table_btn active`}
                                                >
                                                    Joined
                                                </button> */}
                                            </td>
                                        </tr>
                                    ))
                                    :
                                    <tr>
                                        <td colSpan={7}>
                                            <NoDataFound />
                                        </td>
                                    </tr>
                            }
                        </tbody>
                    </table>
                    <div className='pagination_entries_section'>
                        <div className='entries_text'>
                            Showing {(currentPage - 1) * Number(campaignDetailData?.pagination?.perPage) + 1} of {campaignDetailData?.pagination?.totalItems} entries
                        </div>
                        {campaignDetailData?.join_list?.length > 0 ?
                            <Pagination
                                className="pagination-bar"
                                currentPage={currentPage}
                                totalCount={campaignDetailData?.pagination?.totalItems}
                                pageSize={campaignDetailData?.pagination?.perPage}
                                onPageChange={(page) => setCurrentPage(page)}
                            />
                            :
                            null
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
export default CampaignManagementDetails;