import { ToastOverSuccess } from "../components/Common/ToastOver";

var CryptoJS = require("crypto-js");
export const isValidHttpUrl = (string) => {
    let url;
    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
}

export const GetUserSettings = () => {
    var token = CryptoJS.AES.decrypt(localStorage.glidescale_security, process.env.REACT_APP_JWT_SECRET);
    var decryptedData = JSON.parse(token.toString(CryptoJS.enc.Utf8));
    return decryptedData?.user || {};
}

export const debounceSearch = (func, delay) => {
    let timerId;
    return function (...args) {
        clearTimeout(timerId);
        timerId = setTimeout(() => func.apply(this, args), delay);
    };
};

export const HasConsecutiveSpaces = (str) => {
    const string = /  +/.test(str);
    return string
}


export const DownloadAnyTypeOfFile = (fileName, fileType) => {
    const fileContents = {
        pdf: '%PDF-1.3\n1 0 obj\n<< /Type /Catalog /Pages 2 0 R >>\nendobj\n2 0 obj\n<< /Type /Pages /Kids [3 0 R] /Count 1 >>\nendobj\n3 0 obj\n<< /Type /Page /Parent 2 0 R /Contents 4 0 R >>\nendobj\n4 0 obj\n<< /Length 45 >>\nstream\nBT\n/F1 12 Tf\n72 712 Td\n(Hello, PDF!) Tj\nET\nendstream\nendobj\n5 0 obj\n<< /Type /Font /Subtype /Type1 /BaseFont /Helvetica >>\nendobj',
        image: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABmJLR0QA/wD/AP+gvaeTAAAAB3RJTUUH5QUPEgEfVyKP5AAAAANQTFRF////AAAA////b28v8QAAAAlwSFlzAAAOwwAADsMBx2+oZAAAAWtJREFUeNqkU8FqwzAQvHnMnagm7opdSmD/v/FUyusg2mVAClVOQQ7og3IEBa4BJiIJEqC8iP2GOi6nX/qS+R1DbFnayFE0iGwaEQQLCFoF4tIsOo9sawQROBF/wCgKt9E8BajzErqt3ATZjEVvOAMnRVbPZy2m+MnOJtgeMvCOYsxEX9HQipV6rIip7wRzqA4rBFtAtHAl7Q3rflg5Hn9OyIe6TAAqC3I+11Ms5yAitSHzHkflbwt+o/hSYmiJsbJTWlFCn8uBFL+D0oAAAAASUVORK5CYII=',
        xml: '<?xml version="1.0" encoding="UTF-8" ?><root><data>Hello, XML!</data></root>',
        doc: 'data:application/msword;base64,UEsFBgAAAAAAAAAAAAAAAAAAAAAAAA==',
        docx: 'data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,UEsFBgAAAAAAAAAAAAAAAAAAAAAAAA==',
    };

    const content = fileContents[fileType];
    let mimeType = '';
    switch (fileType) {
        case 'pdf':
            mimeType = 'application/pdf';
            break;
        case 'png':
            mimeType = 'image/png';
            break;
        case 'jpg':
            mimeType = 'image/jpg';
            break;
        case 'jpeg':
            mimeType = 'image/jpeg';
            break;
        case 'xml':
            mimeType = 'application/xml';
            break;
        case 'doc':
            mimeType = 'application/msword';
            break;
        case 'docx':
            mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
            break;
        default:
            return;
    }

    const blob = new Blob([content], { type: mimeType });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = `${fileName}.${fileType}`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
};


export const CopyLinkToClipboard = (link) => {
    let copyInput = document.createElement('input');
    copyInput.setAttribute('value', link);
    document.body.appendChild(copyInput);
    copyInput.select();
    let copyResult = document.execCommand('copy');
    document.body.removeChild(copyInput);
    ToastOverSuccess("Link copied successfully!")
    return copyResult;
}

export const DownloadFileInNewWindow = (downloadUrl) => {
    // Open the download URL in a new window
    const newWindow = window.open(downloadUrl, '_blank');

    // Check if the new window is opened successfully
    if (newWindow) {
        // Simulate download completion after 3 seconds (adjust as needed)
        setTimeout(() => {
            // Close the window/tab
            newWindow.close();
        }, 3000); // 3 seconds delay
    } else {
        // Handle the case where pop-up blocking is enabled
        alert('Please allow pop-ups for this site to download the file.');
    }
};

export function checkKeysReplaced(description, keys) {
    for (let key of keys) {
        if (!description.includes(key)) {
            return false;
        }
    }
    return true;
}