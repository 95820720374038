import { React, useEffect, useState } from 'react';
import '../../pages/Style.scss';
import { BiMessage } from "react-icons/bi";
import { Link } from 'react-router-dom';
import { UserSupportListAction } from '../../redux/Slices/CommonSlice';
import moment from 'moment';
import NoDataFound from '../../components/NoDataFound/NoDataFound';


function ContactTicket() {
    const [loader, setLoader] = useState(false);
    const [listDataArr, setListDataArr] = useState({});

    useEffect(() => {
        setLoader(true)
        UserSupportListAction((response) => {
            if (response?.status === true) {
                setListDataArr(response)
            }
            setLoader(false)
        })();
    }, [UserSupportListAction])

    return (
        <>
            <div className='top_heading_button'>
                <h1 className='main_heading_top'>Contact Ticket</h1>
            </div>

            <div className='contact_ticket_page'>
                <div className='row g-3 g-md-5'>
                    {
                        !loader ?
                            listDataArr?.data?.length > 0 ?
                                listDataArr?.data?.map((value, index) => (
                                    <div className='col-md-4 col-sm-6' key={index}>
                                        <Link to={`/contact-ticket/detail/${value?.title?.replace(/[\/\s]+/g, '_').toLowerCase()}/${value?._id}`} className='content_box_ticket'>
                                            <h3> {value?.title} </h3>
                                            <p> {value?.message} {value?.message?.length == 100 ? "..." : ""} </p>

                                            <div className='date_comment_box'>
                                                <div className='date'> {moment(value?.createdAt).format("DD MMM, YYYY")} </div>
                                                <div className='comment_number'> <BiMessage /> 0 </div>
                                            </div>

                                            <div className='date_comment_box ticket_code_status'>
                                                <div className='ticket_code'>
                                                    Ticket : <span> {value?._id} </span>
                                                </div>
                                                <button className={`ticket_status_btn ${value?.status == 0 ? "active" : "closed"}`} type='button'>
                                                    {value?.status == 0 ? "Active" : "Closed"}
                                                </button>
                                            </div>
                                        </Link>
                                    </div>
                                ))
                                :
                                <NoDataFound />
                            :
                            <div> Loading...</div>
                    }
                </div>
            </div>

        </>
    )
}

export default ContactTicket;