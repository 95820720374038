import { React, useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import DeleteCampaigns from '../Modal/DeleteCampaigns';
import { AiOutlineSearch } from 'react-icons/ai';
import '../Header/Header.scss';
import { CommonModal } from '../Modal/CommonModal';
import { useDispatch, useSelector } from 'react-redux';
import { LogoutUser } from '../../redux/Slices/AuthSlice';
import ChangePassword from '../Modal/ChangePassword';
import { AdminProfileAction } from '../../redux/AdminSlices/Common';

function AdminHeader() {
    const dispatch = useDispatch()

    const [switchState, setSwitchState] = useState(false)
    const [DeleteCampaignsShow, setDeleteCampaignsShow] = useState(false)
    const [ChangePasswordShow, setChangePasswordShow] = useState(false)

    const { AuthLoading, login } = useSelector((state) => state.auth)

    const LogoutUserAction = () => dispatch(LogoutUser())

    useEffect(() => {
        dispatch(AdminProfileAction())
    }, [AdminProfileAction])

    useEffect(() => {
        if (switchState) {
            document.body.classList.add('side_nav_open');
        } else {
            document.body.classList.remove('side_nav_open')
        }
    }, [switchState]);

    const handleToggleButton = () => {
        setSwitchState(!switchState)
    }

    return (
        <>
            <header className='main_header_top'>
                <div className='top-header-sidebar'>
                    <div className='top_header_logo'>
                        <img className='header_logo_img' src="/images/logo_mini.svg" alt="" />
                    </div>
                    <button type='btn' className='toggle_btn_button' onClick={handleToggleButton}>
                        <span className='line_btn'></span>
                        <span className='line_btn'></span>
                        <span className='line_btn'></span>
                    </button>
                    <div className='search_input_profile ms-auto w-auto'>
                        {/* <div className="headerSearchBx">
                            <input
                                placeholder="Find campaigns"
                                aria-label="Search"
                                type="search"
                                className="common_input_filed"
                            />
                            <div className="search_header_icon"><AiOutlineSearch /></div>
                        </div> */}
                        <div className='profile drop_card_common'>
                            <Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    <img src={login?.profile ? login?.profile : "/images/dummy-image.jpg"} alt="" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item as={Link} className="nav-link" to="/admin-profile">Profile</Dropdown.Item>
                                    <Dropdown.Item as={Link} className="nav-link" onClick={() => setChangePasswordShow(true)}>Change Password</Dropdown.Item>
                                    <Dropdown.Item as={Link} className="nav-link" onClick={() => setDeleteCampaignsShow(true)}>Log Out</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </header>

            <CommonModal
                show={DeleteCampaignsShow}
                onHide={() => setDeleteCampaignsShow(false)}
                modalClass="delete_Campaigns_modal"
                body={
                    <DeleteCampaigns
                        heading="Do you want to exit?"
                        logOutIcon={true}
                        description_text="Are you sure want to log out? All your unsaved data will be lost."
                        btn1="Not Now"
                        btn2="Yes, Exit"
                        loader={AuthLoading}
                        onClickButton1={() => setDeleteCampaignsShow(false)}
                        onClickButton2={LogoutUserAction}
                    />
                }
            />

            <CommonModal
                show={ChangePasswordShow}
                onHide={() => setChangePasswordShow(false)}
                modalClass="delete_Campaigns_modal change_password_modal"
                body={
                    <ChangePassword
                        hideModal={() => setChangePasswordShow(false)}
                    />
                }
            />
        </>
    )
}

export default AdminHeader