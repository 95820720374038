import { CopyLinkToClipboard } from "../../utils/Function";

const CampaignsInviteLinkModel = (props) => {
    const { btn1, heading, onClickButton1, data } = props

    return (
        <>
            <div className='delete_content_camp'>
                <h1>{heading}</h1>
            </div>
            <div className="text_copy_btn_section">
                <div className="link-holder common_input_filed"> {data?.genrate_link}  </div>
                <button className='btn_blue copy_btn' type="button" onClick={() => CopyLinkToClipboard(data?.genrate_link)}> Copy </button>
            </div>
            <div className='new_Campaigns_input tow_btn_row'>
                <button type='button' className='btn_blue' onClick={onClickButton1}>{btn1}</button>
            </div>
        </>
    )
}
export default CampaignsInviteLinkModel