import React, { useState, useEffect } from 'react';
import '../../pages/Style.scss';
import { MyCampaignViewDetailsAction } from '../../redux/Slices/CampaignSlice';
import { useParams } from 'react-router-dom';
import { CopyLinkToClipboard, HasConsecutiveSpaces, debounceSearch } from '../../utils/Function';
import { Pagination } from '../../components/Hooks/Pagination';
import NoDataFound from '../../components/NoDataFound/NoDataFound';
import { GrDownload } from "react-icons/gr";

const CampaingnsView = () => {
    const { id, name } = useParams();
    const [campaignDetailData, setCampaignDetailData] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [perPageCount, setPerPageCount] = useState(10);
    const [statusUpdate, setStatusUpdate] = useState(1)
    const [searchString, setSearchString] = useState("");

    useEffect(() => {
        let payload = {
            searchText: searchString,
            perPage: perPageCount,
            page: currentPage,
            "_id": id
        }
        MyCampaignViewDetailsAction(payload, (response) => {
            if (response?.status === true) {
                setCampaignDetailData(response?.data)
            } else {
            }
        })();
    }, [MyCampaignViewDetailsAction, perPageCount, currentPage, statusUpdate, searchString, id])

    const handleSearchFilter = debounceSearch((e) => {
        if (HasConsecutiveSpaces(e.target.value)) return <></>;
        setSearchString(e.target.value)
    }, 300);

    return (
        <>
            {/* <div className='top_heading_button'>
                <h1 className='main_heading_top'>Campaign View</h1>
            </div> */}

            <div className='new_Campaigns_create_section new_Campaigns_view common_tab_section mt-0 p-0'>

                <div className='view_img'>
                    <img src={campaignDetailData?.view?.marketing_assets ? campaignDetailData?.view?.marketing_assets : "-"} alt="" />
                </div>
                <div className='campaings_content_view'>
                    <div className='campaigns_name'>
                        <div>
                            {campaignDetailData?.view?.campaign_name ? campaignDetailData?.view?.campaign_name : "-"}
                        </div>
                        <div className='price_text'>
                            $ {campaignDetailData?.view?.ppc_rate ? campaignDetailData?.view?.ppc_rate : "-"}
                        </div>
                    </div>
                    <div className='content_view_complete'>
                        <div className='text_description text-break'>
                            {campaignDetailData?.view?.description}
                        </div>

                        <div className='heading_text'>
                            Types of product(s) :
                        </div>
                        <p className='text_description'> {campaignDetailData?.view?.product_type?.type_name ? campaignDetailData?.view?.product_type?.type_name : "-"}  </p>

                        <div className='heading_text'> Remaining Credit :</div>
                        <p className='text_description'> {campaignDetailData?.view?.no_of_credits ? campaignDetailData?.view?.no_of_credits : "0"}  </p>

                        <div className='heading_text'>
                            Tags (Ex:- abc, bgc) :
                        </div>
                        <p className='text_description'>
                            {campaignDetailData?.view?.tags ?
                                JSON.parse(campaignDetailData?.view?.tags)?.map((value) =>
                                    <span> {value} </span>
                                )
                                : ""
                            }
                        </p>

                        <div className='heading_text'>
                            Target Link :
                        </div>
                        <p className='text_description'> {campaignDetailData?.view?.target_link ? campaignDetailData?.view?.target_link : "-"} </p>

                        <div className='heading_text'>Advanced options :</div>
                        <p className='text_description'>
                            {campaignDetailData?.view?.advanced_option ?
                                <span>
                                    {campaignDetailData?.view?.advanced_option == 3 ? "Link Invite Only" :
                                        campaignDetailData?.view?.advanced_option == 4 ? "Manual Invite Only" :
                                            campaignDetailData?.view?.advanced_option == 2 ? "Approved Only" :
                                                "Public"
                                    }
                                </span>
                                :
                                null
                            }
                        </p>

                        {campaignDetailData?.view?.genrate_link ?
                            <>
                                <div className='heading_text'> Genrated Link : </div>
                                <p className='text_description'>
                                    {campaignDetailData?.view?.genrate_link}
                                    <button className='btn_blue copy_btn' type="button" onClick={() => CopyLinkToClipboard(campaignDetailData?.view?.genrate_link)}> Copy </button>
                                </p>
                            </>
                            :
                            null
                        }
                    {campaignDetailData?.view?.marketing_assets_multiple && campaignDetailData?.view?.marketing_assets_multiple.length > 0 && (
                         <div className='heading_text'>
                             Marketing Assets:
                            <div className='five_upload_img'>
                    {JSON.parse(campaignDetailData?.view?.marketing_assets_multiple)?.map((item,index) => (
                       <>
                        <div key={item.key}>
                            {item.key?.split('.').pop() === "png" || 
                            item.key?.split('.').pop() === "jpg" || 
                            item.key?.split('.').pop() === "jpeg" ? (
                            <img src={item.Location} className="img-fluid" referrerPolicy="no-referrer" alt={item.Location} />
                            ) : item.key?.split('.').pop() === "pdf" ? (
                            <img className="img-fluid" referrerPolicy="no-referrer" src="/images/file/PDF_file_icon.svg" alt="PDF" />
                            ) : item.key?.split('.').pop() === "doc" || 
                            item.key?.split('.').pop() === "docx" ? (
                            <img className="img-fluid" referrerPolicy="no-referrer" src="/images/file/word_icon.svg" alt="DOC" />
                            ) : item.key?.split('.').pop() === "ppt" ? (
                            <img className="img-fluid" referrerPolicy="no-referrer" src="/images/file/microsoft_icon.svg" alt="PPT" />
                            ) : null}
                        </div>
                        </>
                    ))}
                    </div>
                    </div>
                    )}
                    </div>
                </div>
            </div>

            <div className='request_campaigns_list common_tab_section'>
                <div className='filter_number_search_input'>
                    <div className='filter_select_number filter_search_common '>
                        <label className='common_label'>Show:</label>
                        <select className='common_input_filed' onChange={(e) => setPerPageCount(e.target.value)}>
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                        </select>
                    </div>

                    <div className='search_table_content filter_search_common'>
                        <label className='common_label'>Search:</label>
                        <div className="headerSearchBx">
                            <input
                                placeholder="Search"
                                aria-label="Search"
                                type="search"
                                className="common_input_filed"
                                onChange={handleSearchFilter}
                            />
                        </div>
                    </div>
                </div>
                <div className='content_campaigns_user table-responsive'>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th> Image </th>
                                <th> User Name </th>
                                <th> Phone </th>
                                <th> Email </th>
                                <th> Total Paid </th>
                                <th> Credits </th>
                                <th> Status </th>
                                {/* <th className='text-end'>Action</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                campaignDetailData?.join_list?.length > 0 ?
                                    campaignDetailData?.join_list?.map((value, index) => (
                                        <tr key={index}>
                                            <td> {(currentPage - 1) * Number(campaignDetailData?.pagination?.perPage) + (index + 1)}. </td>
                                            <td>
                                                <img
                                                    className='rounded-circle'
                                                    src={value.profile ? value.profile : "/images/dummy-image.jpg"}
                                                    alt={value.user_name}
                                                    width={50}
                                                    height={50}
                                                />
                                            </td>
                                            <td> {value?.user_name ? value?.user_name : "-"} </td>
                                            <td> {value?.phone ? value?.phone : "-"} </td>
                                            <td> {value?.email ? value?.email : "-"} </td>
                                            <td> {value?.total_ppc} </td>
                                            <td> {value?.earn_credits} </td>
                                            <td>
                                                <button
                                                    type='button'
                                                    className={`table_btn ${value?.status == 1 ? "active" : "reject"}`}
                                                // onClick={() => handleStatusUpdate(value._id, value.status)}
                                                >
                                                    {value?.status == 1 ? "Active" : "Inactive"}
                                                </button>
                                            </td>
                                            {/* <td className='text-end fixed-side'>
                                                <button type='button' className='table_btn reject'>Reject</button>
                                                <button type='button' className='table_btn approve'>Approve</button>
                                            </td> */}
                                        </tr>
                                    ))
                                    :
                                    <tr>
                                        <td colSpan={7}>
                                            <NoDataFound />
                                        </td>
                                    </tr>
                            }
                        </tbody>
                    </table>
                    <div className='pagination_entries_section'>
                        <div className='entries_text'>
                            Showing {(currentPage - 1) * Number(campaignDetailData?.pagination?.perPage) + 1} of {campaignDetailData?.pagination?.totalItems} entries
                        </div>
                        {campaignDetailData?.join_list?.length > 0 ?
                            <Pagination
                                className="pagination-bar"
                                currentPage={currentPage}
                                totalCount={campaignDetailData?.pagination?.totalItems}
                                pageSize={campaignDetailData?.pagination?.perPage}
                                onPageChange={(page) => setCurrentPage(page)}
                            />
                            :
                            null
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default CampaingnsView;