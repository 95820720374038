import { createSlice } from '@reduxjs/toolkit';
import api from '../Api';
import { authHeader } from '../authHeader';
import { ToastOverError, ToastOverSuccess } from '../../components/Common/ToastOver';


const initialState = {
	capmaignList: null,
	joinedCapmaignList: null,
	myCapmaignList: null,
	AuthError: null,
	Loading: false,
	requestCapmaignList: null
};

const CampaignSlice = createSlice({
	name: 'CampaignSlice',
	initialState,
	reducers: {
		fetchCampaignStart(state) {
			state.Loading = true;
		},
		fetchCampaingnListSuccess(state, action) {
			state.Loading = false;
			state.capmaignList = action.payload;
		},
		fetchMyCampaingnListSuccess(state, action) {
			state.Loading = false;
			state.myCapmaignList = action.payload;
		},
		fetchJoinedCampaingnListSuccess(state, action) {
			state.Loading = false;
			state.joinedCapmaignList = action.payload;
		},
		fetchRequestCampaingnListSuccess(state, action) {
			state.Loading = false;
			state.requestCapmaignList = action.payload;
		},
		fetchApiFailure(state, action) {
			state.Loading = false;
			state.AuthError = action.payload;
		},
	},
});

export const {
	fetchCampaignStart,
	fetchApiFailure,
	fetchCampaingnListSuccess,
	fetchJoinedCampaingnListSuccess,
	fetchMyCampaingnListSuccess,
	fetchRequestCampaingnListSuccess
} = CampaignSlice.actions;

export const FetchCampaignListAction = (value) => async (dispatch) => {
	try {
		dispatch(fetchCampaignStart());
		const data = await api?.api.post('app/users/browse_campaign_list', value, {
			headers: authHeader(),
		});
		dispatch(fetchCampaingnListSuccess(data?.data));
	} catch (error) {
		dispatch(fetchApiFailure(error.message));
	}
};

export const FetchMyCampaignListAction = (value) => async (dispatch) => {
	try {
		dispatch(fetchCampaignStart());
		const data = await api?.api.post('app/users/my_campaign_list', value, {
			headers: authHeader(),
		});
		dispatch(fetchMyCampaingnListSuccess(data?.data));
	} catch (error) {
		dispatch(fetchApiFailure(error.message));
	}
};

export const FetchJoinedCampaignListAction = () => async (dispatch) => {
	try {
		dispatch(fetchCampaignStart());
		const data = await api?.api.get('app/users/joined_campaign_list', {
			headers: authHeader(),
		});
		dispatch(fetchJoinedCampaingnListSuccess(data?.data));
	} catch (error) {
		dispatch(fetchApiFailure(error.message));
	}
};

export const FetchRequestCampaignListAction = () => async (dispatch) => {
	try {
		dispatch(fetchCampaignStart());
		const data = await api?.api.get('app/users/request_campaign_list', {
			headers: authHeader(),
		});
		dispatch(fetchRequestCampaingnListSuccess(data?.data));
	} catch (error) {
		dispatch(fetchApiFailure(error.message));
	}
};

export const CreateCampaignsAction = (value, callBack, mode) => async () => {
	try {
		const data = await api?.api.post(`app/users/${mode ? 'campaign_update' : 'campaign_create'}`, value, {
			headers: authHeader(),
		});
		ToastOverSuccess(data?.message)
		callBack(data)
	} catch (error) {
		ToastOverError(error?.response?.data?.message)
		callBack(error?.response?.data)
	}
};

export const CampaignsAddCreditAction = (value, callBack) => async () => {
	try {
		const data = await api?.api.post(`app/users/campaign_add_credits`, value, {
			headers: authHeader(),
		});
		ToastOverSuccess(data?.message)
		callBack(data)
	} catch (error) {
		ToastOverError(error?.response?.data?.message)
		callBack(error?.response?.data)
	}
};

export const ProductTypeListAction = (callBack) => async () => {
	try {
		const data = await api?.api.get(`app/users/campaign_product_list`, {
			headers: authHeader(),
		});
		callBack(data)
	} catch (error) {
		ToastOverError(error?.response?.data?.message)
		callBack(error?.response?.data)
	}
};

export const DeleteCampaignsAction = (value, callBack) => async () => {
	try {
		const data = await api?.api.post('app/users/campaign_delete', value, {
			headers: authHeader(),
		});
		ToastOverSuccess(data?.message)
		callBack(data)
	} catch (error) {
		ToastOverError(error?.response?.data?.message)
		callBack(error?.response?.data)
	}
};

export const JoinedCampaignAction = (value, callBack) => async () => {
	try {
		const data = await api?.api.post('app/users/join_campaign', value, {
			headers: authHeader(),
		});
		ToastOverSuccess(data?.message)
		callBack(data)
	} catch (error) {
		ToastOverError(error?.response?.data?.message)
		callBack(error?.response?.data)
	}
};

export const MyCampaignStatusUpdateAction = (value, callBack) => async () => {
	try {
		const data = await api?.api.post('app/users/campaign_status_update', value, {
			headers: authHeader(),
		});
		ToastOverSuccess(data?.message)
		callBack(data)
	} catch (error) {
		ToastOverError(error?.response?.data?.message)
		callBack(error?.response?.data)
	}
};

export const MyCampaignViewDetailsAction = (value, callBack) => async () => {
	try {
		const data = await api?.api.post(`app/users/campaign_view?perPage=${value.perPage}&page=${value.page}&searchText=${value.searchText}`, value, {
			headers: authHeader(),
		});
		callBack(data)
	} catch (error) {
		ToastOverError(error?.response?.data?.message)
		callBack(error?.response?.data)
	}
};

export const CampaignPPCRequestAction = (value, callBack) => async () => {
	try {
		const data = await api?.api.get(`app/users/ppc?short_link_id=${value.short_link_id}&fingerprint=${value.fingerprint}&local_ip=${value.local_ip}`, {
			headers: authHeader(true),
		});
		callBack(data)
	} catch (error) {
		ToastOverError(error?.response?.data?.message)
		callBack(error?.response?.data)
	}
};

export const CampaignDetail = (value, callBack) => async () => {
	try {
		const data = await api?.api.get(`app/users/campaign_model_detail?short_link_id=${value.short_link_id}`, {
			headers: authHeader(true),
		});
		callBack(data)
	} catch (error) {
		ToastOverError(error?.response?.data?.message)
		callBack(error?.response?.data)
	}
};

export const RequestCampaignStatusAction = (value, callBack) => async () => {
	try {
		const data = await api?.api.post(`app/users/campaign_req_approve_reject`, value, {
			headers: authHeader(),
		});
		callBack(data)
		ToastOverSuccess(data?.message)
	} catch (error) {
		ToastOverError(error?.response?.data?.message)
		callBack(error?.response?.data)
	}
};




export const ManagerReportListingAction = (params, callBack) => async () => {
	try {
		const data = await api?.api.get(`app/users/manager_report?perPage=${params.perPage}&page=${params.page}&searchText=${params.searchText}&download=${params.download}`, {
			headers: authHeader(),
		});
		callBack(data)
	} catch (error) {
		ToastOverError(error?.response?.data?.message)
		callBack(error?.response?.data)
	}
};
export const MaarketerReportListingAction = (params, callBack) => async () => {
	try {
		const data = await api?.api.get(`app/users/marketer_report?perPage=${params.perPage}&page=${params.page}&searchText=${params.searchText}&download=${params.download}`, {
			headers: authHeader(),
		});
		callBack(data)
	} catch (error) {
		ToastOverError(error?.response?.data?.message)
		callBack(error?.response?.data)
	}
};
export default CampaignSlice.reducer;
