import React, { useState } from 'react';
import '../../pages/Style.scss';
import { Form, Formik } from 'formik';
import * as Yup from "yup";
import { InputText, TextArea } from '../../components/Common/InputText';
import { Button } from '../../components/Common/Button';
import { ContactSupportAction } from '../../redux/Slices/CommonSlice';
import { GetUserSettings } from '../../utils/Function';

function ContactSupport() {
    const [loader, setLoader] = useState(false)
    const userDetail = GetUserSettings();
    return (
        <>
            <div className='top_heading_button'>
                <h1 className='main_heading_top'>Contact Support</h1>
            </div>
            <div className='support_contact_section'>
                <div className='row g-5 justify-content-center'>
                    <div className='col-md-7'>
                        <Formik
                            initialValues={{
                                name: userDetail ? userDetail?.full_name : "",
                                email: userDetail ? userDetail?.email : "",
                                title: '',
                                message: '',
                            }}
                            validationSchema={Yup.object().shape({
                                name: Yup.string().required("Name is Required!"),
                                email: Yup.string().email("Invalid email address").required("Email is Required!"),
                                title: Yup.string().required("Title is Required!"),
                                message: Yup.string().required("Message is Required!"),
                            })}
                            onSubmit={(value, { resetForm }) => {
                                setLoader(true)
                                let data = {
                                    name: userDetail?.full_name ? userDetail?.full_name : value?.name,
                                    email: userDetail?.email ? userDetail?.email : value?.email,
                                    title: value?.title,
                                    message: value?.message
                                }
                                ContactSupportAction(data, (response) => {
                                    if (response?.status === true) {
                                        setLoader(false)
                                        resetForm();
                                    } else {
                                        setLoader(false)
                                    }
                                })();
                            }}
                        >
                            {(formik) => {
                                return (
                                    <Form className='support_contact_form'>
                                        <div className='support_contact_input'>
                                            <InputText
                                                label="Name"
                                                name="name"
                                                type="text"
                                                placeholder='Name'
                                                value={formik.values.name}
                                                className="common_input_filed"
                                                disabled={userDetail?.full_name != undefined}
                                            />
                                        </div>

                                        <div className='support_contact_input'>
                                            <InputText
                                                label="Email"
                                                name="email"
                                                type="email"
                                                placeholder='Email'
                                                value={formik.values.email}
                                                className="common_input_filed"
                                                disabled
                                            />
                                        </div>

                                        <div className='support_contact_input'>
                                            <InputText
                                                label="Ticket title"
                                                name="title"
                                                type="text"
                                                placeholder='Ticket title'
                                                value={formik.values.title}
                                                className="common_input_filed"
                                            />
                                        </div>

                                        <div className='support_contact_input'>
                                            <TextArea
                                                label="Message"
                                                name="message"
                                                placeholder='Message'
                                                className='text_area_common'
                                                rows="10"
                                                id="txtid"
                                                value={formik.values.message}
                                            />
                                        </div>

                                        <div className='support_contact_input contact_btn'>
                                            <Button
                                                className="btn_blue"
                                                text="Submit"
                                                type="submit"
                                                loader={loader}
                                                disabled={!(formik.isValid && formik.dirty) || loader}
                                            />
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>

                    </div>
                    {/* <div className='col-md-6'>
                        <div className='support_contact_form right_side_img'>
                            <div className='user_name_id'>
                                <h3>Email</h3>
                                <p>blozzombuzz244@gmail.com</p>
                            </div>
                            <div className='user_name_id'>
                                <h3>Phone Number</h3>
                                <p>+453 8263762681</p>
                            </div>
                            <div className='user_name_id'>
                                <h3>Address</h3>
                                <p>Cecilia Chapman 711-2880 Nulla St. Mankato Mississippi 96522</p>
                            </div>
                            <div className='support_img'>
                                <img src="/images/contact_support.svg" alt="" />
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default ContactSupport;