import { React, useState } from 'react';
import '../../pages/Style.scss';
import DeleteCampaigns from '../../components/Modal/DeleteCampaigns';


function List() {

    const [DeleteCampaignsShow, setDeleteCampaignsShow] = useState(false);

    return (
        <>
            <div className='top_heading_button'>
                <h1 className='main_heading_top'>Request Campaigns</h1>
            </div>
            <div className='request_campaigns_list'>
                <div className='content_campaigns_user table-responsive'>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Marketing Assets</th>
                                <th>Campaign Name</th>
                                <th>Product</th>
                                <th>Tags</th>
                                <th>Target Link</th>
                                <th>PPC Rate</th>
                                <th className='text-end'>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((value, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td><div className='profile_img_table'><img className='common_design_img' src="/images/dummy-image.jpg" alt="" /></div></td>
                                    <td>Campaign Name</td>
                                    <td>Product Name</td>
                                    <td>Tags, Tags, Tags, Tags</td>
                                    <td>Target Link</td>
                                    <td>$400</td>
                                    <td className='text-end fixed-side'><button type='button' className='table_btn reject' onClick={() => setDeleteCampaignsShow(true)}>Reject</button>
                                        <button type='button' className='table_btn approve' onClick={() => setDeleteCampaignsShow(true)}>Approve</button></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                </div>
            </div>


            <DeleteCampaigns
                show={DeleteCampaignsShow}
                onHide={() => setDeleteCampaignsShow(false)}
                heading="Do you want to remove Campaigns?"
                btn1="No"
                btn2="Yes"
            />

        </>
    )
}

export default List;