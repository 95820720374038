import React, { useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import "./Modal.scss";
import { ErrorMessage, Field, Form, Formik, useFormikContext } from 'formik';
import * as Yup from "yup";
import { InputText, SelectBox, TextArea } from '../Common/InputText';
import { CampaignsAddCreditAction, CreateCampaignsAction, FetchCampaignListAction, FetchMyCampaignListAction, ProductTypeListAction } from '../../redux/Slices/CampaignSlice';
import { Button } from '../Common/Button';
import { useDispatch } from 'react-redux';
import TextErrorMsg from '../Common/TextErrorMsg';
import { ToastOverError } from '../Common/ToastOver';
import { MuiChipsInput } from 'mui-chips-input'
import { CopyLinkToClipboard } from '../../utils/Function';
import { BsFilterLeft, BsEmojiSmile, BsFileEarmark } from 'react-icons/bs';

function CampaignsModal({ onClickButton1, editMode, setInviteLinkOptionData }) {
	const dispatch = useDispatch()
	const [loader, setLoader] = useState(false)
	const [formDataSaved, setFormDataSaved] = useState(false)
	const [productTypeList, setProductTypeList] = useState();
	const [campaignsUpdatedCredits, setCampaignsUpdatedCredits] = useState("0");
	const [campaignsPriceModelShow, setCampaignsPriceModelShow] = useState(false)
	const [creditLoader, setCreditLoader] = useState(false)
	const SUPPORTED_FILE_FORMAT = [
		"image/jpg",
		"image/png",
		"image/jpeg",
		"application/pdf",
		"application/msword",
		"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
		"application/vnd.ms-powerpoint",
		"application/vnd.openxmlformats-officedocument.presentationml.presentation",
		"application/vnd.google-apps.document", // Google Docs
		"application/vnd.ms-excel", // Excel (old format)
		"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" // Excel (new format)
	];

	const advancedOption = [
		{ id: "1", value: 3, label: "Link Invite Only" },
		{ id: "2", value: 4, label: "Manual Invite Only" },
		{ id: "3", value: 2, label: "Approved Only" }
	]
	useEffect(() => {
		ProductTypeListAction((response) => {
			if (response?.status === true) {
				setProductTypeList(response?.data)
			}
		})();
	}, [ProductTypeListAction])
	const FormikFromFunc = () => {
		const formikFrom = useFormikContext();
		useEffect(() => {
			if (Object.keys(editMode).length > 0 && formDataSaved === false) {
				formikFrom.setValues({
					_id: editMode?._id,
					campaign_name: editMode?.campaign_name,
					product_type: editMode?.product_type?._id,
					tags: JSON.parse(editMode?.tags),
					target_link: editMode?.target_link,
					ppc_rate: editMode?.ppc_rate,
					no_of_credits: editMode?.no_of_credits,
					advanced_option: editMode?.advanced_option,
					description: editMode?.description,
					marketing_assets: editMode?.marketing_assets,
					marketing_assets_multiple: editMode?.marketing_assets_multiple ? JSON.parse(editMode?.marketing_assets_multiple) : [],
					genrate_link: editMode?.genrate_link
				})
				setFormDataSaved(true)
			}
		}, [editMode])
	}
	return (
		<>
			<Formik
				initialValues={{
					campaign_name: '',
					product_type: '',
					tags: '',
					target_link: '',
					ppc_rate: '',
					no_of_credits: "",
					marketing_assets: '',
					marketing_assets_multiple: [],
					description: '',
					product_type_other: ''
				}}
				validationSchema={Yup.object().shape({
					campaign_name: Yup.string().required("Campaign Name is Required!"),
					description: Yup.string().required("Campaign description is Required!"),
					product_type: Yup.string().required("Product Type is Required!"),
					product_type_other: Yup.string().when('product_type', function (product_type, schema) {
						return product_type == "other" ? schema.required("Product type is required!") : schema.notRequired();
					}),
					target_link: Yup.string().required("Target Link is Required!"),
					ppc_rate: Yup.string().required("PPC Rate is Required!"),
					no_of_credits: Yup.string().required("No of credits is Required!"),
					tags: Yup.array().required("Tags is required!"),
					marketing_assets: Yup.mixed().nullable().notRequired()
						.test(
							"FILE_FORMAT",
							"Unsupported file format",
							(value) => !value || (value && (typeof value === "object" ? SUPPORTED_FILE_FORMAT.includes(value?.type) : true))
						),
						marketing_assets_multiple: editMode?._id ? Yup.array().nullable().notRequired() : Yup.array()
                        .nullable()
                        .notRequired()
                        .of(
                            Yup.mixed()
                                .test(
                                    "FILE_FORMAT",
                                    "Unsupported file format",
                                    (value) => !value || (value && (typeof value === "object" ? SUPPORTED_FILE_FORMAT.includes(value?.type) : true))
                                )
                        )
				})}
				onSubmit={(value) => {
					const formData = new FormData();
					if (editMode?._id) {
						formData.append("_id", editMode?._id);
					} else {
						formData.append("no_of_credits", value?.no_of_credits);
					}
					formData.append("campaign_name", value?.campaign_name);
					formData.append("description", value?.description);
					formData.append("product_type", value?.product_type == "other" ? value?.product_type_other : value?.product_type);
					formData.append("target_link", value?.target_link);
					formData.append("tags", JSON.stringify(value?.tags));
					formData.append("ppc_rate", value?.ppc_rate);
					formData.append("advanced_option", value?.advanced_option ? Number(value?.advanced_option) : 1);
					formData.append("marketing_assets", value?.marketing_assets);

					if (value?.marketing_assets_multiple.length > 0) {
						value?.marketing_assets_multiple.forEach((file, index) => {
							formData.append(`marketing_assets_multiple`, file);
						});
					}
					if (value?.product_type == "other") {
						formData.append("other_type", "other")
					}
					setLoader(true)
					CreateCampaignsAction(formData, (response) => {
						if (response?.status === true) {
							dispatch(FetchCampaignListAction())
							dispatch(FetchMyCampaignListAction())
							onClickButton1()
							if (response?.data?.advanced_option == 3) {
								setInviteLinkOptionData(response?.data)
							}
						}
						setLoader(false)
					}, Object.keys(editMode).length > 0 ? true : false)();
				}}
			>
				{(formik) => {
					return (
						<Form>
							<FormikFromFunc />
							<div className='new_Campaigns_create_section'>
								<InputText
									name="campaign_name"
									type="text"
									label="Name*"
									inputDivClass="new_Campaigns_input"
									value={formik.values?.campaign_name}
									className="common_input_filed"
								/>

								<div className='form_filed_common new_Campaigns_input'>
									<TextArea
										label="Description"
										name="description"
										className='text_area_common'
										rows="4"
										maxlength={5000}
										value={formik.values.description}
									/>
								</div>

								<SelectBox
									name="product_type"
									label="Types of product(s)*"
									inputDivClass="new_Campaigns_input"
									value={formik.values.product_type}
									className="common_input_filed"
									options={
										<>
											<option value=""> Select product type </option>
											{productTypeList?.map((value, index) => (
												<option value={value?._id} key={index}> {value?.type_name} </option>
											))}
											<option value="other">Other</option>
										</>
									}
								/>

								{formik.values.product_type == "other" ?
									<InputText
										name="product_type_other"
										type="text"
										label="Other*"
										inputDivClass="new_Campaigns_input"
										value={formik.values?.product_type_other}
										className="common_input_filed"
									/>
									:
									null
								}

								<Field name="tags">
									{({ form, field }) => {
										return (
											<div className={`form_filed_common new_Campaigns_input common_input_filed_file`}>
												<div className={`user_input common_input_filed_file_common type_input`}>
													<label className='common_label'> Tags <small>(Ex:- abc, bgc)</small> </label>
													<MuiChipsInput
														name={field.name}
														value={formik.values.tags}
														onChange={(value) => form.setFieldValue(field.name, value)}
													/>
												</div>
												<small> <strong> </strong>Note: Please press Enter ↵ for create the tags  </small>
												<ErrorMessage name={field.name} component={TextErrorMsg} />
											</div>
										);
									}}
								</Field>
								<ErrorMessage name="tags" component={TextErrorMsg} />

								<InputText
									name="target_link"
									type="text"
									label="Target Link"
									inputDivClass="new_Campaigns_input"
									value={formik.values?.target_link}
									className="common_input_filed"
								/>

								<div className='form_filed_common new_Campaigns_input addCredit-point-block'>
									<InputText
										name="no_of_credits"
										type="text"
										label="No of credits*"
										placeholder='$'
										inputDivClass="new_Campaigns_input mb-0 w-100"
										value={formik.values?.no_of_credits}
										className="common_input_filed"
										disabled={editMode?._id ? true : false}
									/>
									{editMode?._id ?
										<button type='button' className='btn_blue' onClick={() => {
											setCampaignsPriceModelShow(true)
										}}> Add more credits </button>
										:
										""
									}
								</div>

								{campaignsPriceModelShow ?
									<div className='addCredit-point-block form_filed_common new_Campaigns_input'>
										<input
											maxLength="10000000"
											minLength="1"
											name="ppc_rate"
											type="text"
											placeholder="$"
											class="common_input_filed"
											value={campaignsUpdatedCredits}
											onChange={(e) => setCampaignsUpdatedCredits(e.target.value)}
										/>
										<Button
											className="btn_blue"
											text="Update"
											type="button"
											onClick={() => {
												setCreditLoader(true)
												campaignsUpdatedCredits && campaignsUpdatedCredits > "0" ?
													CampaignsAddCreditAction({ _id: editMode?._id, add_credits: Number(campaignsUpdatedCredits) }, (response) => {
														if (response?.status === true) {
															dispatch(FetchMyCampaignListAction())
															setCampaignsUpdatedCredits("")
															setCampaignsPriceModelShow(false)
															onClickButton1()
															// formik.setFieldValue("no_of_credits", response?.data?.no_of_credits)
														}
														setCreditLoader(false)
													})()
													:
													ToastOverError("Credit must be greater then or equal to 1")
											}}
											loader={creditLoader}
											disabled={campaignsUpdatedCredits == "0"}
										/>
									</div>
									: ""}

								<InputText
									name="ppc_rate"
									type="text"
									label="PPC Rate*"
									placeholder='$'
									inputDivClass="new_Campaigns_input"
									value={formik.values?.ppc_rate}
									className="common_input_filed"
									disabled={editMode?._id ? true : false}
								/>

								<Field name="marketing_assets">
									{({ form, field }) => {
										return (
											<div className={`form_filed_common new_Campaigns_input common_input_filed_file`}>
												<div className={`user_input common_input_filed_file_common`}>
													<label className='common_label'> Marketing Banner </label>
													<input name={field.name} type="file" id='profileEdit'
														onChange={(e) => {
															if (SUPPORTED_FILE_FORMAT.includes(e.target.files[0]?.type)) {
																form.setFieldValue(field.name, e.target.files[0])
															} else {
																form.setFieldValue(field.name, "")
																e.target.value = null
																ToastOverError("Unsupported file format")
															}
														}} />
												</div>
												<ErrorMessage name={field.name} component={TextErrorMsg} />
											</div>
										)
									}}
								</Field>
								{formik.values?.marketing_assets && typeof formik.values?.marketing_assets == "string" ?
									<img src={formik.values?.marketing_assets} className='marketing_img_pdf' alt='Marketing Banner' />
									:
									null
								}

								<Field name="marketing_assets_multiple">
									{({ form, field }) => {
										return (
											<div className={`form_filed_common new_Campaigns_input common_input_filed_file`}>
												<div className={`user_input common_input_filed_file_common`}>
													<label className='common_label'> Marketing Assets </label>
													<input name={field.name} type="file" id='profileEdit'
													multiple
													onChange={(e) => {
														const files = Array.from(e.target.files);
														const validFiles = files.filter(file => SUPPORTED_FILE_FORMAT.includes(file.type));
				
														if (validFiles.length !== files.length) {
															form.setFieldValue(field.name, "")
															e.target.value = null
															ToastOverError("Unsupported file format");
														}else{
															form.setFieldValue(field.name, validFiles);
														}
													}} />
												</div>
												<ErrorMessage name={field.name} component={TextErrorMsg} />
											</div>
										)
									}}
								</Field>
								{console.log("formik.values?.marketing_assets_multiple",formik.values?.marketing_assets_multiple)}
								<div className='five_upload_img'>
								{formik.values?.marketing_assets_multiple.length > 0 ? formik.values?.marketing_assets_multiple?.map((item)=> 
								 	item.key?.split('.').pop('') === "png" || item.key?.split('.').pop('') === "jpg" || item.key?.split('.').pop('') === "jpeg" ? 
								// 	<>
								// 	<div className='file_icon'>
								// 	<BsFileEarmark />
								// 	</div>
							 	// 	<span>{item.key}</span>
								// 	</>
								//  : null)}
									<img src={item.Location} className="img-fluid" referrerPolicy="no-referrer" alt={item.Location} />
								:
								item.key?.split('.').pop('') === "pdf" ?
								<img className="img-fluid" referrerPolicy="no-referrer" src="/images/file/PDF_file_icon.svg" alt="PDF" />
								:
								item.key?.split('.').pop('') === "doc" || item.key?.split('.').pop('') === "docx" ?
									<img className="img-fluid" referrerPolicy="no-referrer" src="/images/file/word_icon.svg" alt="DOC" />
									:
									item.key?.split('.').pop('') === "ppt" ?
										<img className="img-fluid" referrerPolicy="no-referrer" src="/images/file/microsoft_icon.svg" alt="PPT" />
										:
										null
    							):null}
								</div>
								
								<div className='new_Campaigns_input'>
									<Accordion defaultActiveKey="">
										<Accordion.Item eventKey="0">
										<Accordion.Header>Advanced options</Accordion.Header>
										<Accordion.Body>
											<div className='three_check_box'>
											<Field name="advanced_option">
												{({ form, field }) => {
												const handleClear = (e) => {
													e.preventDefault();
													form.setFieldValue(field.name, null);
												};

												return (
													<>
													<button className='btn_clear_plan' onClick={handleClear}>Clear</button>
													{advancedOption?.map((value, index) => (
														<div className='check_box_first check_box_common' key={index}>
														<input
															type="radio"
															id={`${field.name}_${value.id}`}
															name={field.name}
															value={value.value}
															onChange={(e) => form.setFieldValue(field.name, e.target.value)}
															checked={formik.values.advanced_option == value.value}
														/>
														<label htmlFor={`${field.name}_${value.id}`}>{value.label}</label>
														</div>
													))}
													</>
												);
												}}
											</Field>
											</div>
										</Accordion.Body>
										</Accordion.Item>
									</Accordion>
									</div>

								{formik.values?.genrate_link ?
									<>
										<div className='text_copy_btn_section'>
											<div className="link-holder common_input_filed"> {formik.values?.genrate_link} </div>
											<button type="button" className='btn_blue copy_btn' onClick={() => CopyLinkToClipboard(formik.values?.genrate_link)}> Copy </button>
										</div>
									</>
									:
									null
								}
								<div className='new_Campaigns_input tow_btn_row'>
									<button type='button' className='btn_blue' onClick={onClickButton1}>Cancel</button>
									<Button
										className="btn_blue"
										text={formDataSaved ? 'Update campaign' : 'Create campaign'}
										type="submit"
										loader={loader}
										disabled={!(formik.isValid && formik.dirty) || loader}
									/>
								</div>
							</div>
						</Form>
					);
				}}
			</Formik>
		</>
	)
}

export default CampaignsModal;