import { React, useEffect, useState } from 'react';
import '../../../pages/Style.scss';
import { EmailManagementListAction } from '../../../redux/AdminSlices/Common';
import NoDataFound from '../../../components/NoDataFound/NoDataFound';
import { Link } from 'react-router-dom';
import { FaRegEdit, FaEye } from 'react-icons/fa';
import moment from 'moment';

const EmailManagement = () => {
    const [loader, setLoader] = useState(false);
    const [listDataArr, setListDataArr] = useState({});

    useEffect(() => {
        EmailManagementListAction((response) => {
            if (response?.status === true) {
                setListDataArr(response)
            }
            setLoader(false)
        })();
    }, [EmailManagementListAction])

    return (
        <>
            <div className='top_heading_button'>
                <h1 className='main_heading_top'>Email Management</h1>
            </div>

            <div className='tab_section_card'>
                <div className='common_tab_section'>
                    <div className='request_campaigns_list'>
                        <div className='content_campaigns_user table-responsive'>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Title</th>
                                        <th>Type</th>
                                        <th>Created At</th>
                                        <th>Updated At</th>
                                        <th className='text-end'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        !loader ?
                                            listDataArr?.data?.length > 0 ?
                                                listDataArr?.data?.map((value, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}. </td>
                                                        <td>{value?.title}</td>
                                                        <td>{value?.type}</td>
                                                        <td>{moment(value?.createdAt).format("DD MMM, YYYY")}</td>
                                                        <td>{moment(value?.updatedAt).format("DD MMM, YYYY")}</td>
                                                        <td className='text-end fixed-side'>
                                                            <Link to={`/email-management/edit/${value._id}`} className='common_btn_admin edit_btn_admin'><FaRegEdit /></Link>
                                                        </td>
                                                    </tr>
                                                ))
                                                :
                                                <tr>
                                                    <td colSpan={5}>
                                                        <NoDataFound />
                                                    </td>
                                                </tr>
                                            :
                                            <tr>
                                                <td colSpan={5}>Loading...</td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default EmailManagement