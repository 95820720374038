import api from '../Api';
import { authHeader } from '../authHeader';
import { ToastOverError, ToastOverSuccess } from '../../components/Common/ToastOver';

export const ContactSupportAction = (value, callBack) => async () => {
	try {
		const data = await api?.api.post(`app/users/contact_support`, value, {
			headers: authHeader(),
		});
		ToastOverSuccess(data?.message)
		callBack(data)
	} catch (error) {
		ToastOverError(error?.response?.data?.message)
		callBack(error?.response?.data)
	}
};

export const CMSFrontViewAction = (id, callBack) => async () => {
	try {
		const data = await api?.api.get(`app/users/cmspage-view/${id}`, {
			headers: authHeader(),
		});
		callBack(data)
	} catch (error) {
		ToastOverError(error?.response?.data?.message)
		callBack(error?.response?.data)
	}
};


export const QAListingAction = (callBack) => async () => {
	try {
		const data = await api?.api.get(`app/users/QA_listing`, {
			headers: authHeader(),
		});
		callBack(data)
	} catch (error) {
		ToastOverError(error?.response?.data?.message)
		callBack(error?.response?.data)
	}
};

export const WatchTutorialListAction = (callBack) => async () => {
	try {
		const data = await api?.api.get(`app/users/watch_tutorial_list`, {
			headers: authHeader(),
		});
		callBack(data)
	} catch (error) {
		ToastOverError(error?.response?.data?.message)
		callBack(error?.response?.data)
	}
};

export const UserSupportListAction = (callBack) => async () => {
	try {
		const data = await api?.api.get(`app/users/user_support_list`, {
			headers: authHeader(),
		});
		callBack(data)
	} catch (error) {
		ToastOverError(error?.response?.data?.message)
		callBack(error?.response?.data)
	}
};

export const UserSupportDetailAction = (value, callBack) => async () => {
	try {
		const data = await api?.api.post(`app/users/ticket_support_detail`, value, {
			headers: authHeader(),
		});
		callBack(data)
	} catch (error) {
		ToastOverError(error?.response?.data?.message)
		callBack(error?.response?.data)
	}
};

export const UserSupportChatAction = (value, callBack) => async () => {
	try {
		const data = await api?.api.post(`app/users/support_chat`, value, {
			headers: authHeader(),
		});
		callBack(data)
	} catch (error) {
		ToastOverError(error?.response?.data?.message)
		callBack(error?.response?.data)
	}
};


export const PaymentGatewayIntegrationAction = (value, callBack) => async () => {
	try {
		const data = await api?.api.post(`app/users/payment_order_verify`, value, {
			headers: authHeader(),
		});
		ToastOverSuccess(data?.message)
		callBack(data)
	} catch (error) {
		ToastOverError(error?.response?.data?.message)
		callBack(error?.response?.data)
	}
};

export const PaymentTransactionListAction = (value, callBack) => async () => {
	try {
		const data = await api?.api.post(`app/users/transaction_list`, value, {
			headers: authHeader(),
		});
		callBack(data)
	} catch (error) {
		ToastOverError(error?.response?.data?.message)
		callBack(error?.response?.data)
	}
};

export const WithDrawalPayoutAction = (value, callBack) => async () => {
	try {
		const data = await api?.api.post(`app/users/payout`, value, {
			headers: authHeader(),
		});
		ToastOverSuccess(data?.message)
		callBack(data)
	} catch (error) {
		ToastOverError(error?.response?.data?.message)
		callBack(error?.response?.data)
	}
};

export const notificationListAction = (callBack) => async () => {
	try {
		const data = await api?.api.get(`app/users/notification_list`, {
			headers: authHeader(),
		});
		callBack(data)
	} catch (error) {
		ToastOverError(error?.response?.data?.message)
		callBack(error?.response?.data)
	}
};

export const notificationCountAction = (callBack) => async () => {
	try {
		const data = await api?.api.get(`app/users/unread_notification_count`, {
			headers: authHeader(),
		});
		callBack(data)
	} catch (error) {
		ToastOverError(error?.response?.data?.message)
		callBack(error?.response?.data)
	}
}


export const globalCampaignSearchAction = (searchText, callBack) => async () => {
	try {
		const data = await api?.api.get(`app/users/campaign_search?search=${searchText}`, {
			headers: authHeader(),
		});
		callBack(data)
	} catch (error) {
		ToastOverError(error?.response?.data?.message)
		callBack(error?.response?.data)
	}
}