import React, { useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import './Auth.scss';
import { Button } from '../../components/Common/Button';
import { OTPVerificationAction, ResendOTP, fetchLoginSuccess } from '../../redux/Slices/AuthSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
var CryptoJS = require("crypto-js");

function Otp() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()

    const [otp, setOtp] = useState('');
    const [time, setTime] = useState(60)
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        if (time > 0) {
            const timer = setInterval(() => {
                setTime((prevValue) => prevValue - 1)
            }, 1000)

            return () => {
                clearInterval(timer)
            }
        }
    }, [time])

    const handleSubmit = (e) => {
		e.preventDefault()
		let payload = {
			otp: otp,
            email: location?.state?.email,
		}
        setLoader(true)
        OTPVerificationAction(payload, (response) => {
			if (response?.status === true) {
				setLoader(false)
                if(location?.state?.login_verify === 1){
                    var token = CryptoJS.AES.encrypt(JSON.stringify(response?.data), process.env.REACT_APP_JWT_SECRET).toString();
			        localStorage.setItem("glidescale_security", token)
                    dispatch(fetchLoginSuccess(response?.data?.user));
                    navigate("/campaigns", { replace: true })
                } else {
                    navigate("/login", { replace: true })
                }
			} else {
				setLoader(false)
			}
		})();
	}

    const resendOtpHandle = () => {
		const payload = {
			otp: otp,
            email: location?.state?.email,
		}
		ResendOTP(payload, (response) => {
			if (response?.status === true) {
				setTime(60)
			} else {
			}
		})();
	}
    return (

        <>
            <section className='login_register_section otp_screen'>
                <div className='left_side_content_filed'>
                    <form onSubmit={handleSubmit} className='login_input_filed'>
                        <div className='logo_images'>
                            <img src="/images/glide_scale_logo.svg" alt="" />
                        </div>
                        <div className='text_login_description'>
                            <h1>OTP Verification</h1>
                            <p>We've sent you an OTP to your register phone number/Email address!</p>
                        </div>

                        <div className='input_filed_login_register'>

                            <div className='form_filed_common'>
                                <OtpInput
                                    value={otp}
                                    onChange={setOtp}
                                    numInputs={6}
                                    renderInput={(props) => <input {...props} />}
                                />
                            </div>
                            <div className='resend_otp'>
                                <span>00:{time < 10 ? `0${time}` : time}</span> 
                                <button type='button' className={`resend_otp_btn ${time !== 0 && 'disabled'}`} onClick={resendOtpHandle}>Resend OTP</button>
                            </div>
                        </div>

                        <div className='sign_in_btn'>
                            <Button
                                className="sign-in"
                                text="Verify"
                                type="submit"
                                loader={loader}
                                disabled={otp.length !== 6}
                            />
                        </div>
                    </form>
                </div>

                <div className='right_side_content'>
                    <div className='right_login_description'>
                        <div className='img_right_side'>
                            <img src="/images/login_right_side.svg" alt="" />
                        </div>
                        <div className='content_right_side'>
                            <h2>Your Security First, Efficiency Paramount.</h2>
                            <p> Glidescale employs an array of defensive measures to ensure you and your marketing is as safe and reliable as possible. Freely create your own or join a PPC Affiliate Program without the hassle or worry.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Otp