import React from 'react';
import { Modal } from 'react-bootstrap';
import "./Modal.scss";
import moment from 'moment';

function NotificationsModal(props) {
    const { data, ...rest } = props
    return (
        <>
            <Modal {...rest} size="md" aria-labelledby="contained-modal-title-vcenter" className='new_Campaigns_modal notifications_modal' centered>
                <Modal.Header closeButton>
                    <h4> Notifications </h4>
                </Modal.Header>
                <Modal.Body>
                    <div className='notification-content'>
                        {data?.user_notification_list?.length > 0 ?

                            data?.user_notification_list.map((value, index) => (
                                <div className='campaigns_list_not' key={index}>
                                    <div className='heading_time_section'>
                                        <div className='heading_campaigns_req'> {value.message} </div>
                                        <p className='time_notification'> {moment(value.createdAt).fromNow()} </p>
                                    </div>
                                </div>
                            ))
                            :
                            <> Notification not found </>
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default NotificationsModal;