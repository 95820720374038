import React, { useEffect, useState } from 'react';
import "./../../../components/Modal/Modal.scss";
import { ProudctTypeAddAction, ProudctTypeUpdateAction } from '../../../redux/AdminSlices/Common';
import { Form, Formik, useFormikContext } from 'formik';
import * as Yup from "yup";
import { InputText } from '../../../components/Common/InputText';
import { Button } from '../../../components/Common/Button';

function AddEditProductType(props) {
    const { setAddTypeModalShow, detail, setStatusUpdate, ...rest } = props
    const [loader, setLoader] = useState(false);
    const [formDataSaved, setFormDataSaved] = useState(false)

    const FormikFromFunc = () => {
        const formikFrom = useFormikContext();
        useEffect(() => {
            if (detail && formDataSaved === false) {
                formikFrom.setValues({
                    type_name: detail?.type_name,
                })
                setFormDataSaved(true)
            }
        }, [detail])
    }

    return (
        <>
            <div className='delete_content_camp'>
                <h1> {detail ? "Edit" : "Add"} Product Type </h1>
            </div>

            <Formik
                initialValues={{
                    type_name: ""
                }}
                validationSchema={Yup.object().shape({
                    type_name: Yup.string().required("Type is Required!")
                })}
                onSubmit={(value) => {
                    setLoader(true)
                    if (detail?.mode == "edit") {
                        ProudctTypeUpdateAction({ ...value, type_id: detail?._id }, (response) => {
                            if (response?.status === true) {
                                setAddTypeModalShow(false)
                                setStatusUpdate(prev => prev + 1)
                            }
                            setLoader(false)
                        })();
                    } else {
                        ProudctTypeAddAction(value, (response) => {
                            if (response?.status === true) {
                                setAddTypeModalShow(false)
                                setStatusUpdate(prev => prev + 1)
                            }
                            setLoader(false)
                        })();
                    }
                }}
            >
                {(formik) => {
                    return (
                        <Form>
                            <FormikFromFunc />
                            <div className='row g-5'>
                                <div className='col-12'>
                                    <InputText
                                        name="type_name"
                                        type="text"
                                        placeholder='Type of product'
                                        label="Type of product"
                                        value={formik.values?.type_name}
                                        className="common_input_filed"
                                    />
                                </div>

                                <div className='col-12'>
                                    <Button
                                        className="btn_blue"
                                        text='Submit'
                                        type="submit"
                                        loader={loader}
                                        disabled={!(formik.isValid && formik.dirty) || loader}
                                    />
                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </>
    )
}

export default AddEditProductType;