import { React, useEffect, useState } from 'react';
import { ErrorMessage, Field, Form, Formik, useFormikContext } from 'formik';
import * as Yup from "yup";
import '../../../pages/Style.scss';
import { AdminProfileAction, AdminProfileUpdateAction, fileUploadAction } from '../../../redux/AdminSlices/Common';
import { useDispatch, useSelector } from 'react-redux';
import { InputText } from '../../../components/Common/InputText';
import { Button } from '../../../components/Common/Button';
import { ToastOverError } from '../../../components/Common/ToastOver';
import ImagePreview from '../../../components/ImagePreview';
import TextErrorMsg from '../../../components/Common/TextErrorMsg';


function AdminProfile() {
    const dispatch = useDispatch()
    const [formDataSaved, setFormDataSaved] = useState(false)
    const [loader, setLoader] = useState(false)

    const { adminProfile } = useSelector((state) => state?.common)

    const FormikFromFunc = () => {
        const formikFrom = useFormikContext();
        useEffect(() => {
            if (adminProfile && formDataSaved === false) {
                formikFrom.setValues({
                    user_name: adminProfile?.user_name,
                    email: adminProfile?.email,
                    profile_image: adminProfile?.profile,
                    service_fee: adminProfile?.service_fee
                })
                setFormDataSaved(true)
            }
        }, [adminProfile])
    }

    const SUPPORTED_FILE_FORMAT = ["image/jpg", "image/png", "image/jpeg"]
    return (
        <>
            <div className='top_heading_button'>
                <h1 className='main_heading_top'>Profile</h1>
            </div>
            <Formik
                initialValues={{
                    user_name: '',
                    email: '',
                    profile_image: '',
                    service_fee: "",
                }}
                validationSchema={Yup.object().shape({
                    user_name: Yup.string().required("User Name is Required!"),
                    email: Yup.string().email("Invalid email address").required("Email/Phone Number is Required!"),
                    service_fee: Yup.number()
                        .typeError('Commission percentage must be a number')
                        .required('Commission percentage is required')
                        .min(0, 'Commission percentage must be greater than or equal to 0')
                        .max(100, 'Commission percentage must be less than or equal to 100'),
                    profile_image: Yup.mixed().nullable().notRequired()
                        .test(
                            "FILE_FORMAT",
                            "Unsupported file format",
                            (value) => !value || (value && (typeof value === "object" ? SUPPORTED_FILE_FORMAT.includes(value?.type) : true))
                        ),
                })}
                onSubmit={(value) => {
                    const payload = {
                        user_name: value?.user_name,
                        email: value?.email,
                        profile: value.profile_image,
                        service_fee: Number(value.service_fee)
                    }
                    setLoader(true)
                    AdminProfileUpdateAction(payload, (response) => {
                        if (response?.status === true) {
                            setLoader(false)
                            dispatch(AdminProfileAction())
                        } else {
                            setLoader(false)
                        }
                    })();
                }}
            >
                {(formik) => {
                    return (
                        <Form>
                            <FormikFromFunc />
                            <div className='profile_page'>
                                <div className='profile-edit'>
                                    <Field name="profile_image">
                                        {({ form, field }) => {
                                            return (
                                                <>
                                                    <input name={field.name} type="file" hidden={true} id='profileEdit' onChange={(e) => {
                                                        if (SUPPORTED_FILE_FORMAT.includes(e.target.files[0]?.type)) {
                                                            setLoader(true)
                                                            let formData = new FormData();
                                                            formData.append("file", e.target.files[0]);
                                                            fileUploadAction(formData, (response) => {
                                                                if (response?.status === true) {
                                                                    setLoader(false)
                                                                    form.setFieldValue(field.name, response?.data[0].file_url)
                                                                } else {
                                                                    setLoader(false)
                                                                    ToastOverError(response?.data?.message)
                                                                }
                                                            })();
                                                        } else {
                                                            form.setFieldValue(field.name, "")
                                                            e.target.value = null
                                                            ToastOverError("Unsupported file format")
                                                        }
                                                    }} />
                                                    <label htmlFor="profileEdit" className='edit-label'>Change</label>
                                                </>
                                            )
                                        }}
                                    </Field>

                                    <div className='img-container'>
                                        {formik.values.profile_image ?
                                            <ImagePreview file={formik.values.profile_image} imageAlt={formik.values.user_name} />
                                            :
                                            <ImagePreview imageAlt={formik.values.user_name} />
                                        }
                                    </div>

                                </div>
                                <div className='text-center'> <ErrorMessage name="profile_image" component={TextErrorMsg} /> </div>

                                <div className='basic_details_user'>
                                    <div className='row gx-5'>
                                        <div className='col-md-6 mb-md-5 mb-4'>
                                            <InputText
                                                name="user_name"
                                                type="text"
                                                placeholder='Enter user name'
                                                label="User_name"
                                                value={formik.values?.user_name}
                                                className="common_input_filed"
                                            />
                                        </div>
                                        <div className='col-md-6 mb-md-5 mb-4'>
                                            <InputText
                                                name="email"
                                                type="email"
                                                placeholder='Enter Email'
                                                label="Email"
                                                value={formik.values?.email}
                                                className="common_input_filed"
                                            />
                                        </div>
                                        <div className='col-md-6 mb-md-5 mb-4'>
                                            <InputText
                                                name="service_fee"
                                                type="text"
                                                placeholder='Enter service commission'
                                                label="Service commission"
                                                value={formik.values?.service_fee}
                                                className="common_input_filed"
                                                maxLength={3}
                                            />
                                        </div>
                                        <div className='col-12'>
                                            <div className='profile_save_btn'>
                                                <Button
                                                    className="btn_blue"
                                                    text='Save'
                                                    type="submit"
                                                    loader={loader}
                                                    disabled={!(formik.isValid && formik.dirty) || loader}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </>
    )
}

export default AdminProfile;