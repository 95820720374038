var CryptoJS = require("crypto-js");
export function authHeader(unAuth = null) {
    if (unAuth) {
        return {
            Accept: "application/json",
            "Content-Type": "application/json",
        };
    } else {
        let authToken = localStorage.getItem('glidescale_security');
        var bytes  = CryptoJS.AES.decrypt(authToken, process.env.REACT_APP_JWT_SECRET);
        var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return {
            Authorization: 'Bearer ' + decryptedData?.user?.token
        };
    }
}