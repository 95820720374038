import React from 'react';
import { Outlet } from 'react-router-dom';
import AdminSidebar from '../AdminSidebar/AdminSidebar';
import AdminHeader from '../AdminHeader/AdminHeader';
import './TheLayout.scss';

function SuperAdminLayout() {
  return (
    <>
      <div id='main_top_section' className='super_admin_main_section'>
        <AdminHeader />
        <div className='main_content_section'>
          <Outlet />
        </div>
        <AdminSidebar />
      </div>
    </>
  )
}

export default SuperAdminLayout;