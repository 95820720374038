import { React, useEffect } from 'react';
import { FaRegEdit } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { cmsListAction } from '../../../redux/AdminSlices/Cms';
import moment from 'moment';
import NoDataFound from '../../../components/NoDataFound/NoDataFound';

function CmsList() {
    const dispatch = useDispatch()

    const { cmsList, cmsLoading } = useSelector((state) => state.cms)

    useEffect(() => {
        if (cmsList === null) {
            dispatch(cmsListAction())
        }
    }, [])

    return (
        <>
            <div className='top_heading_button'>
                <h1 className='main_heading_top'>CMS Management</h1>
            </div>

            <div className='tab_section_card'>
                <div className='common_tab_section'>
                    <div className='request_campaigns_list'>
                        <div className='content_campaigns_user table-responsive'>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Title</th>
                                        <th>Description</th>
                                        <th>Created At</th>
                                        <th>Updated At</th>
                                        <th className='text-end'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        !cmsLoading ?
                                            cmsList?.length > 0 ?
                                                cmsList?.map((value, index) => (
                                                    <tr key={index}>
                                                        <td>{value?.title}</td>
                                                        <td>{value?.description}</td>
                                                        <td>{moment(value?.createdAt).format("DD MMM, YYYY")}</td>
                                                        <td>{moment(value?.updatedAt).format("DD MMM, YYYY")}</td>
                                                        <td className='text-end fixed-side'>
                                                            {/* <Link to={`/cms-list/view/${value._id}`} className='common_btn_admin eye_btn_admin'><MdOutlineRemoveRedEye /></Link> */}
                                                            <Link to={`/cms-list/edit/${value._id}`} className='common_btn_admin edit_btn_admin'><FaRegEdit /></Link>
                                                        </td>
                                                    </tr>
                                                ))
                                                :
                                                <tr>
                                                    <td colSpan={5}>
                                                        <NoDataFound />
                                                    </td>
                                                </tr>
                                            :
                                            <tr>
                                                <td colSpan={5}>Loading...</td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default CmsList;