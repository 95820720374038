import { React, useEffect, useState } from 'react';
import '../../../pages/Style.scss';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { UserManagementListAction, UserManagementStatusUpdateAction } from '../../../redux/AdminSlices/Common';
import NoDataFound from '../../../components/NoDataFound/NoDataFound';
import { Pagination } from "../../../components/Hooks/Pagination"
import { HasConsecutiveSpaces, debounceSearch } from '../../../utils/Function';
import { useNavigate } from 'react-router-dom';


function UsersManagementList() {
    const navigate = useNavigate()
    const [loader, setLoader] = useState(false);
    const [listDataArr, setListDataArr] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [perPageCount, setPerPageCount] = useState(10);
    const [searchString, setSearchString] = useState("");
    const [statusUpdate, setStatusUpdate] = useState(1)
    const [userType, setUserType] = useState(0);

    useEffect(() => {
        let payload = {
            searchText: searchString,
            perPage: perPageCount,
            page: currentPage,
            type: userType
        }
        UserManagementListAction(payload, (response) => {
            if (response?.status === true) {
                setListDataArr(response)
                setLoader(false)
            } else {
                setLoader(false)
            }
        })();
    }, [UserManagementListAction, perPageCount, currentPage, searchString, userType, statusUpdate])

    const handleSearchFilter = debounceSearch((e) => {
        if (HasConsecutiveSpaces(e.target.value)) return <></>;
        setSearchString(e.target.value)
    }, 300);

    const handleStatusUpdate = (id, status) => {
        UserManagementStatusUpdateAction({ _id: id, status: status == 1 ? 2 : 1 }, (response) => {
            if (response?.status === true) {
                setStatusUpdate(statusUpdate + 1)
                setLoader(false)
            } else {
                setLoader(false)
            }
        })();
    }

    return (
        <>
            <div className='top_heading_button'>
                <h1 className='main_heading_top'> Users Management </h1>
            </div>

            <div className='admin_list_table'>
                <div className='filter_number_search_input'>
                    <div className='filter_select_number filter_search_common '>
                        <label className='common_label'>Show:</label>
                        <select className='common_input_filed' onChange={(e) => setPerPageCount(e.target.value)}>
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                        </select>
                    </div>

                    <div className='filter_select_number filter_search_common '>
                        <label className='common_label'> User Type:</label>
                        <select className='common_input_filed' onChange={(e) => setUserType(e.target.value)}>
                            <option value={0}> All </option>
                            <option value={1}> Marketer </option>
                            <option value={2}> Manager </option>
                        </select>
                    </div>

                    <div className='search_table_content filter_search_common'>
                        <label className='common_label'>Search:</label>
                        <div className="headerSearchBx">
                            <input
                                placeholder="Search"
                                aria-label="Search"
                                type="search"
                                className="common_input_filed"
                                onChange={handleSearchFilter}
                            />
                        </div>
                    </div>
                </div>
                <div className='request_campaigns_list'>
                    <div className='content_campaigns_user table-responsive'>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th> User Name </th>
                                    <th> Email </th>
                                    <th> Country Code </th>
                                    <th> Phone </th>
                                    <th> Role </th>
                                    <th> Status </th>
                                    <th className='text-end'>Action</th>
                                </tr>
                            </thead>
                            <tbody>

                                {
                                    !loader ?
                                        listDataArr?.data?.list?.length > 0 ?
                                            listDataArr?.data?.list?.map((value, index) => (
                                                <tr key={index}>
                                                    <td> {(currentPage - 1) * Number(listDataArr?.data?.pagination?.perPage) + (index + 1)}. </td>
                                                    <td> {value?.user_name ? value?.user_name : "-"} </td>
                                                    <td> {value?.email ? value?.email : "-"} </td>
                                                    <td> {value?.country_code ? value?.country_code : "-"} </td>
                                                    <td> {value?.phone ? value?.phone : "-"} </td>
                                                    <td>
                                                        <button
                                                            className={`table_btn ${value?.complete_profile == 0 ? "active" : "manager"}`}
                                                        >
                                                            {value?.complete_profile == 0 ? "Marketer" : "Manager"}
                                                        </button>
                                                    </td>
                                                    <td>
                                                        <button
                                                            type='button'
                                                            className={`table_btn ${value?.status == 1 ? "resolved" : "pending"}`}
                                                            onClick={() => handleStatusUpdate(value._id, value.status)}
                                                        >
                                                            {value?.status == 1 ? "Active" : "Inactive"}
                                                        </button>
                                                    </td>
                                                    <td className='text-end fixed-side'>
                                                        <button type='button' className='common_btn_admin eye_btn_admin'
                                                            onClick={() => navigate(`/users-management/${value?.user_name}/${value?._id}`, { state: value })}
                                                        >
                                                            <MdOutlineRemoveRedEye />
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))
                                            :
                                            <tr>
                                                <td colSpan={7}>
                                                    <NoDataFound />
                                                </td>
                                            </tr>
                                        :
                                        <tr>
                                            <td colSpan={5}>Loading...</td>
                                        </tr>
                                }
                            </tbody>
                        </table>

                        <div className='pagination_entries_section'>
                            <div className='entries_text'>
                                Showing {(currentPage - 1) * Number(listDataArr?.data?.pagination?.perPage) + 1} of {listDataArr?.data?.pagination?.totalItems} entries
                            </div>

                            {listDataArr?.data?.list?.length > 0 ?
                            <Pagination
                                className="pagination-bar"
                                currentPage={currentPage}
                                totalCount={listDataArr?.data?.pagination?.totalItems}
                                pageSize={listDataArr?.data?.pagination?.perPage}
                                onPageChange={(page) => setCurrentPage(page)}
                            />
                            :
                            null
                        }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UsersManagementList;