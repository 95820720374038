import React, { useEffect, useState } from 'react';
import '../../pages/Style.scss';
import { Link } from 'react-router-dom';
import { WatchTutorialListAction } from '../../redux/Slices/CommonSlice';
import NoDataFound from '../../components/NoDataFound/NoDataFound';

function WatchTutorials() {
    const [loader, setLoader] = useState(false);
    const [listDataArr, setListDataArr] = useState({});
    useEffect(() => {
        setLoader(true)
        WatchTutorialListAction((response) => {
            if (response?.status === true) {
                setListDataArr(response)
            }
            setLoader(false)
        })();
    }, [WatchTutorialListAction])

    return (
        <>
            <div className='top_heading_button'>
                <h1 className='main_heading_top'>Watch Tutorials</h1>
            </div>
            <div className='check_q_a_section'>
                <div className='video_link_section_main'>
                    {!loader ?
                        listDataArr?.data?.length > 0 ?
                            listDataArr?.data?.map((value, index) => (
                                <div className='title_link_video' key={index}>
                                    <iframe
                                        style={{ width: "100%" }}
                                        height="300"
                                        src={value?.link}
                                        title={value?.title}
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen
                                    />
                                    <div className='content_video'>
                                        <h5> {value?.title}</h5>
                                        <p>  {value?.description} </p>
                                    </div>
                                </div>
                            ))
                            :
                            <NoDataFound />
                        :
                        <div> Loading... </div>
                    }
                </div>
            </div>
        </>
    )
}

export default WatchTutorials;