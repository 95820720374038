import '../../../pages/Style.scss';
import { React, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, Formik, useFormikContext } from 'formik';
import * as Yup from "yup";
import { InputText, TextArea } from '../../../components/Common/InputText';
import { Button } from '../../../components/Common/Button';
import { CreateWatchTutorialAction, WatchTutorialUpdateAction } from '../../../redux/AdminSlices/Common';


function Addvideo() {
    const navigate = useNavigate()
    const { state } = useLocation()
    const [loader, setLoader] = useState(false)
    const [formDataSaved, setFormDataSaved] = useState(false)

    const FormikFromFunc = () => {
        const formikFrom = useFormikContext();
        useEffect(() => {
            if (state && formDataSaved === false) {
                formikFrom.setValues({
                    title: state?.title,
                    description: state?.description,
                    link: state?.link,
                })
                setFormDataSaved(true)
            }
        }, [state])
    }

    return (
        <>
            <div className='top_heading_button'>
                <h1 className='main_heading_top'> Add New Video </h1>
            </div>

            <div className='content_campaigns_user add_video common_tab_section'>

                <Formik
                    initialValues={{
                        title: "",
                        description: "",
                        link: ""
                    }}
                    validationSchema={Yup.object().shape({
                        title: Yup.string().required("Title is Required!"),
                        description: Yup.string().required("Description is Required!"),
                        link: Yup.string().required("Link is Required!"),
                    })}
                    onSubmit={(value) => {
                        setLoader(true)
                        if (state?.mode == "edit") {
                            WatchTutorialUpdateAction({ ...value, watch_id: state?._id }, (response) => {
                                if (response?.status === true) {
                                    navigate("/watch-tutorial-list")
                                }
                                setLoader(false)
                            })();
                        } else {
                            CreateWatchTutorialAction(value, (response) => {
                                if (response?.status === true) {
                                    navigate("/watch-tutorial-list")
                                }
                                setLoader(false)
                            })();
                        }
                    }}
                >
                    {(formik) => {
                        return (
                            <Form>
                                <FormikFromFunc />
                                <div className='row g-5'>
                                    <div className='col-12'>
                                        <InputText
                                            name="title"
                                            type="text"
                                            placeholder='Title'
                                            label="Title"
                                            value={formik.values?.title}
                                            className="common_input_filed"
                                            disabled={state?.mode == "view"}
                                        />
                                    </div>

                                    <div className='col-12'>
                                        <TextArea
                                            label="Description"
                                            name="description"
                                            placeholder='Description'
                                            className='text_area_common'
                                            rows="4"
                                            maxlength={500}
                                            value={formik.values.description}
                                            disabled={state?.mode == "view"}
                                        />
                                    </div>

                                    <div className='col-12'>
                                        <InputText
                                            name="link"
                                            type="url"
                                            placeholder='Video Link'
                                            label="Video Link"
                                            value={formik.values?.link}
                                            className="common_input_filed"
                                            disabled={state?.mode == "view"}
                                        />
                                    </div>
                                    {state?.mode !== "view" ?
                                        <div className='col-12'>
                                            <Button
                                                className="btn_blue"
                                                text='Submit'
                                                type="submit"
                                                loader={loader}
                                                disabled={!(formik.isValid && formik.dirty) || loader}
                                            />
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </>
    )
}

export default Addvideo;