import { React, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Form, Formik, useFormikContext } from 'formik';
import * as Yup from "yup";
import { cmsListAction, cmsUpdateAction, cmsViewAction } from '../../../redux/AdminSlices/Cms';
import { InputText } from '../../../components/Common/InputText';
import { Button } from '../../../components/Common/Button';
import { useDispatch } from 'react-redux';

function Edit() {
    const { id } = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [loader, setLoader] = useState(false)
    const [ckeditorData, setCkeditorData] = useState()
    const [formDataSaved, setFormDataSaved] = useState(false)
    const [CmsViewData, setCmsViewData] = useState()

    useEffect(() => {
        cmsViewAction({cms_id: id}, (response) => {
            if (response?.status === true) {
                setCmsViewData(response.data)
            } else {
            }
        })();
    }, [cmsViewAction])
    
    const FormikFromFunc = () => {
        const formikFrom = useFormikContext();
        useEffect(() => {
            if (CmsViewData && formDataSaved === false) {
                formikFrom.setValues({
                    title: CmsViewData?.title,
                })
                setCkeditorData(CmsViewData?.description)
                setFormDataSaved(true)
            }
        }, [CmsViewData])
    }
    return (
        <>
            <div className='top_heading_button'>
                <h1 className='main_heading_top'>CMS Edit</h1>
            </div>

            <div className='admin_main_content_section edit_cms_page'>
                <Formik
                    initialValues={{
                        title: '',
                    }}
                    validationSchema={Yup.object().shape({
                        title: Yup.string().required("Title is Required!"),
                    })}
                    onSubmit={(value) => {
                        const payload = {
                            title: value?.title,
                            cms_id: id,
                            description: ckeditorData
                        }
                        setLoader(true)
                        cmsUpdateAction(payload, (response) => {
                            if (response?.status === true) {
                                setLoader(false)
                                dispatch(cmsListAction())
                                navigate("/cms-list")
                            } else {
                                setLoader(false)
                            }
                        })();
                    }}
                >
                    {(formik) => {
                        return (
                            <Form>
                                <FormikFromFunc />
                                <div className='row'>
                                    <div className='col-12 mb-4 mb-md-5'>
                                        <InputText
                                            name="title"
                                            type="text"
                                            placeholder='Title'
                                            label="Title"
                                            value={formik.values?.title}
                                            className="common_input_filed"
                                        />
                                    </div>
                                    <div className='col-12 mb-4 mb-md-5'>
                                        <CKEditor
                                            editor={ClassicEditor}
                                            data={ckeditorData}
                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                setCkeditorData(data)
                                            }}
                                        />
                                    </div>
                                    <div className='col-12'>
                                        <div className='cancel_submit_btn_admin'>
                                            <Link to="/cms-list" className='btn_blue'>Cancel</Link>
                                            <Button
                                                className="btn_blue"
                                                text='Submit'
                                                type="submit"
                                                loader={loader}
                                                disabled={!(formik.isValid && formik.dirty) || loader}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>

        </>
    )
}

export default Edit;