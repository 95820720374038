import { React, useEffect, useState } from 'react';
import { FaRegEdit } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { cmsListAction } from '../../../redux/AdminSlices/Cms';
import moment from 'moment';
import NoDataFound from '../../../components/NoDataFound/NoDataFound';
import { ProudctTypeListingAction, ProudctTypeStatusUpdateAction } from '../../../redux/AdminSlices/Common';
import { AiOutlinePlus } from 'react-icons/ai';
import { CommonModal } from '../../../components/Modal/CommonModal';
import AddEditProductType from './AddEdit';
import { Pagination } from '../../../components/Hooks/Pagination';
import { HasConsecutiveSpaces, debounceSearch } from '../../../utils/Function';

function TypeOfProductList() {
    const [loader, setLoader] = useState(false);
    const [listDataArr, setListDataArr] = useState({});
    const [addTypeModalShow, setAddTypeModalShow] = useState(false)
    const [statusUpdate, setStatusUpdate] = useState(1)
    const [typeOfPrductDetail, setTypeOfPrductDetail] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [perPageCount, setPerPageCount] = useState(30);
    const [searchString, setSearchString] = useState("");

    useEffect(() => {
        setTypeOfPrductDetail("")
        let payload = {
            searchText: searchString,
            perPage: perPageCount,
            page: currentPage
        }
        ProudctTypeListingAction(payload, (response) => {
            if (response?.status === true) {
                setListDataArr(response)
            }
        })();
    }, [ProudctTypeListingAction, statusUpdate, searchString, perPageCount, currentPage])

    const handleStatusUpdate = (id) => {
        ProudctTypeStatusUpdateAction({ type_id: id }, (response) => {
            if (response?.status === true) {
                setStatusUpdate(statusUpdate + 1)
            }
        })();
    }

    const handleSearchFilter = debounceSearch((e) => {
        if (HasConsecutiveSpaces(e.target.value)) return <></>;
        setSearchString(e.target.value)
    }, 300);

    return (
        <>
            <div className='top_heading_button'>
                <h1 className='main_heading_top'>Type of product management</h1>
                <button className='btn_blue' type='button' onClick={() => setAddTypeModalShow(true)}><AiOutlinePlus /> Add New </button>
            </div>

            <div className='tab_section_card'>
                <div className='common_tab_section'>
                    <div className='filter_number_search_input'>
                        <div className='filter_select_number filter_search_common '>
                            <label className='common_label'>Show:</label>
                            <select className='common_input_filed' onChange={(e) => setPerPageCount(e.target.value)}>
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                            </select>
                        </div>

                        <div className='search_table_content filter_search_common'>
                            <label className='common_label'>Search:</label>
                            <div className="headerSearchBx">
                                <input
                                    placeholder="Search"
                                    aria-label="Search"
                                    type="search"
                                    className="common_input_filed"
                                    onChange={handleSearchFilter}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='request_campaigns_list'>
                        <div className='content_campaigns_user table-responsive'>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>S.NO</th>
                                        <th>Title</th>
                                        <th> Modifyed </th>
                                        <th>Status</th>
                                        <th className='text-end'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        listDataArr?.data?.product_type_List?.length > 0 ?
                                            listDataArr?.data?.product_type_List?.map((value, index) => (
                                                <tr key={index}>
                                                    <td> {(currentPage - 1) * Number(listDataArr?.data?.pagination?.perPage) + (index + 1)}. </td>
                                                    <td>{value?.type_name}</td>
                                                    <td>{moment(value?.updatedAt).format("DD MMM, YYYY")}</td>
                                                    <td>
                                                        <button
                                                            type='button'
                                                            className={`table_btn  ${value?.status == 1 ? "active" : "reject"}`}
                                                            onClick={() => handleStatusUpdate(value._id)}
                                                        >
                                                            {value?.status == 1 ? "Active" : "Inactive"}
                                                        </button>
                                                    </td>
                                                    <td className='text-end fixed-side'>
                                                        <button className='common_btn_admin edit_btn_admin' type='button' onClick={() => {
                                                            setAddTypeModalShow(true)
                                                            setTypeOfPrductDetail({ ...value, mode: "edit" })
                                                        }}> <FaRegEdit />  </button>
                                                    </td>
                                                </tr>
                                            ))
                                            :
                                            <tr>
                                                <td colSpan={5}>
                                                    <NoDataFound />
                                                </td>
                                            </tr>
                                    }
                                </tbody>
                            </table>

                            <div className='pagination_entries_section'>
                                <div className='entries_text'>
                                    Showing {(currentPage - 1) * Number(listDataArr?.data?.pagination?.perPage) + 1} of {listDataArr?.data?.pagination?.totalItems} entries
                                </div>
                                {listDataArr?.data?.product_type_List?.length > 0 ?
                                    <Pagination
                                        className="pagination-bar"
                                        currentPage={currentPage}
                                        totalCount={listDataArr?.data?.pagination?.totalItems}
                                        pageSize={listDataArr?.data?.pagination?.perPage}
                                        onPageChange={(page) => setCurrentPage(page)}
                                    />
                                    :
                                    null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <CommonModal
                show={addTypeModalShow}
                onHide={() => {
                    setAddTypeModalShow(false)
                    setTypeOfPrductDetail("")
                }}
                modalClass="delete_Campaigns_modal"
                body={
                    <AddEditProductType
                        setAddTypeModalShow={setAddTypeModalShow}
                        setStatusUpdate={setStatusUpdate}
                        detail={typeOfPrductDetail}
                    />
                }
            />
        </>
    )
}

export default TypeOfProductList;