import React, { useEffect, useState } from 'react';
import '../../pages/Style.scss';
import { Accordion } from 'react-bootstrap';
import { QAListingAction } from '../../redux/Slices/CommonSlice';
import NoDataFound from '../../components/NoDataFound/NoDataFound';
function CheckQA() {
    const [loader, setLoader] = useState(false);
    const [listDataArr, setListDataArr] = useState({});

    useEffect(() => {
        setLoader(true)
        QAListingAction((response) => {
            if (response?.status === true) {
                setListDataArr(response)
            }
            setLoader(false)
        })();
    }, [QAListingAction])

    return (
        <>
            <div className='top_heading_button'>
                <h1 className='main_heading_top'> Check Q&A </h1>
            </div>
            <div className='check_q_a_section'>
                <Accordion defaultActiveKey="0">
                    {
                        !loader ?
                            listDataArr?.data?.length > 0 ?
                                listDataArr?.data?.map((value, index) => (
                                    <Accordion.Item eventKey={index} key={index}>
                                        <Accordion.Header> {value?.question} </Accordion.Header>
                                        <Accordion.Body> {value?.answer} </Accordion.Body>
                                    </Accordion.Item>
                                ))
                                :
                                <NoDataFound />
                            :
                            <div> Loading...</div>
                    }
                </Accordion>
            </div>
        </>
    )
}

export default CheckQA;