import axios from 'axios';
import { fetchSetCurrentUserSuccess } from './Slices/AuthSlice';
export let MainBaseURL = ''

if (process.env.REACT_APP_URL === 'dev') {
	MainBaseURL = 'https://api.glidescale.com/'
} else if (process.env.REACT_APP_URL === 'local') {
	MainBaseURL = 'http://192.168.2.152:3005/'
} else if (process.env.REACT_APP_URL === 'production') {
	MainBaseURL = ''
}

const api = axios.create({
	baseURL: MainBaseURL,
});

export function baseURL() {
	return MainBaseURL;
}

// Add request interceptor
api.interceptors.request.use(
	(config) => {
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

// Add response interceptor
api.interceptors.response.use(
	(response) => {
		return response.data;
	},
	(error) => {
		if (error.response) {
			const { status_code, code, message } = error.response.data;
			if (code === "LOGOUT" || message === "Token Invalid" || message === "Unauthorized") {
				window.location.reload();
				localStorage.removeItem("glidescale_security");
			} else if (status_code === 404) {

			} else if (status_code === 500) {

			}
			// Show notification for specific APIs
			// if (data && data.showNotification) {
			//   showNotification(data.message, 'error');
			// }
		} else {

		}
		return Promise.reject(error);
	}
);

export default { MainBaseURL, api };