import React, { useState } from 'react';
import "../../components/Modal/Modal.scss";
import { Formik, Form } from 'formik';
import { InputText, SelectBox } from '../../components/Common/InputText';
import * as Yup from "yup";
import { Regex } from '../../utils/Regex';
import { WithDrawalPayoutAction } from '../../redux/Slices/CommonSlice';
import { Button } from '../../components/Common/Button';
import { useSelector } from 'react-redux';

function Withdrawal(props) {
    const { onClickButton1, heading, setStatusUpdate } = props
    const [loader, setLoader] = useState(false);
    const { profile } = useSelector((state) => state?.auth)

    function percentage(partialValue) {
        return (profile?.service_fee * partialValue) / 100;
    }

    return (
        <>
            <div className='delete_content_camp pb-0'>
                <h1>{heading}</h1>
                <Formik
                    initialValues={{
                        amount: '',
                        recipient_type: "EMAIL",
                        reciver_detail: ""
                    }}
                    validationSchema={Yup.object().shape({
                        amount: Yup.string().min(2, "Value must be greater then 9 and less to 1 lakh").max(6, "'Value must be greater then 9 and less to 1 lakh'").matches(Regex.ALLOW_NUMBER_ONLY, 'Value must be greater then 9 and less to 1 lakh').required('Required'),
                        recipient_type: Yup.string().required("Type is required"),
                        reciver_detail: Yup.string().required("Email/Phone number is required")
                    })}
                    onSubmit={(value) => {
                        setLoader(true)
                        WithDrawalPayoutAction(value, (response) => {
                            if (response?.status == true) {
                                onClickButton1();
                                setStatusUpdate(prev => prev + 1)
                            }
                            setLoader(false)
                        })();
                    }}
                >
                    {(formik) => {
                        return (
                            <Form>
                                <div className='amount_add_section text-start'>
                                    <InputText
                                        name="amount"
                                        type="number"
                                        placeholder='Amount'
                                        value={formik.values?.amount}
                                        className="common_input_filed"
                                    />
                                </div>
                                <div className='amount_add_section text-start pt-2'>
                                    <small> <strong>Note</strong>: Admin commission fee of {profile?.service_fee}% will be deducted, resulting in a net amount of ${percentage(formik.values?.amount)} </small>
                                </div>

                                <div className='amount_add_section text-start'>
                                    <SelectBox
                                        name="recipient_type"
                                        value={formik.values.recipient_type}
                                        className="common_input_filed"
                                        options={
                                            <>
                                                <option value="EMAIL">Email</option>
                                                <option value="PHONE">Phone Number</option>
                                            </>
                                        }
                                    />
                                </div>

                                <div className='amount_add_section text-start mb-1'>
                                    <InputText
                                        name="reciver_detail"
                                        type="text"
                                        placeholder='Reciver detail'
                                        value={formik.values?.reciver_detail}
                                        className="common_input_filed"
                                    />
                                </div>

                                <div className='new_Campaigns_input tow_btn_row'>
                                    <Button
                                        className="btn_blue"
                                        text='Submit'
                                        type="submit"
                                        loader={loader}
                                        disabled={!(formik.isValid && formik.dirty) || loader}
                                    />
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>

        </>
    )
}

export default Withdrawal;