import React, { useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom';
import { AiOutlineFileText, AiOutlineSetting, AiOutlineSound, AiOutlineUser, AiOutlineVideoCamera } from 'react-icons/ai';
import { BiMessageCheck } from "react-icons/bi";
import { IoCloseSharp, IoWalletOutline } from "react-icons/io5";
import './Sidebar.scss';
import { TbReportAnalytics } from 'react-icons/tb';
import { FiTool } from 'react-icons/fi';

const Sidebar = () => {

    const [switchState, setSwitchState] = useState(false)

    useEffect(() => {
        if (switchState) {
            document.body.classList.remove('side_nav_open');
        } else {
            document.body.classList.remove('side_nav_open')
        }
    }, [switchState]);

    const handleToggleButton = () => {
        setSwitchState(!switchState)
    }


    return (
        <div className='Side_bar_section'>
            <button type='button' className='close_sidebar_cross' onClick={handleToggleButton} ><IoCloseSharp /></button>
            <div className='logo_side_bar'>
                <Link to="/"><img className='side_bar_open_logo' src="/images/glide_scale_logo.svg" alt="" />
                    <img className='side_bar_close_logo' src="/images/logo_mini.svg" alt="" /></Link>
            </div>

            <div className='menu_card_sidebar'>
                <NavLink className='menu_item' to='/campaigns' onClick={handleToggleButton}> <AiOutlineSound /> <span>Campaigns</span></NavLink>
                <NavLink className='menu_item' to='/watch-tutorials' onClick={handleToggleButton}> <AiOutlineVideoCamera /> <span>Watch Tutorials</span></NavLink>
                <NavLink className='menu_item' to='/check-QA' onClick={handleToggleButton}>  <BiMessageCheck /> <span>Check Q&A</span></NavLink>
                <NavLink className='menu_item' to='/report' onClick={handleToggleButton}> <TbReportAnalytics /> <span>Report</span></NavLink>
                <NavLink className='menu_item' to='/wallet' onClick={handleToggleButton}> <IoWalletOutline /> <span>Wallet</span></NavLink>
                <NavLink className='menu_item' to='/contact-ticket' onClick={handleToggleButton}> <FiTool /> <span>Contact Ticket</span></NavLink>
                <NavLink className='menu_item' to='/about-us' onClick={handleToggleButton}> <AiOutlineUser /> <span>About Us</span></NavLink>
                <NavLink className='menu_item' to='/contact-support' onClick={handleToggleButton}> <AiOutlineFileText /> <span>Contact Support</span></NavLink>
                {/* <NavLink className='menu_item' to='/settings' onClick={handleToggleButton}> <AiOutlineSetting /> <span>Settings</span></NavLink> */}
                {/* <Accordion className='sub_menu_sidebar'>
          <Accordion.Item eventKey="0">
            <Accordion.Header className='sub_menu_heading'><TbReportAnalytics /> <span>Report</span></Accordion.Header>
            <Accordion.Body>
              <NavLink className='menu_item' to='/campaigns-report'> <span>Campaigns Report</span></NavLink>
              <NavLink className='menu_item' to='/marketing-report'> <span>Marketing Report</span></NavLink>
              <NavLink className='menu_item' to='/revenue-report'> <span>Revenue Report</span></NavLink>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion> */}
            </div>
        </div>
    )
}

export default Sidebar
