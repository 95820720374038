import React, { useState } from 'react';
import '../../pages/Payment/PaymentMethod.scss';
import { AiOutlinePlus } from 'react-icons/ai';
import { RiDeleteBinLine } from 'react-icons/ri';
import { CommonModal } from '../../components/Modal/CommonModal';
import NewCardModal from './NewCardModal';
import DeleteCampaigns from '../../components/Modal/DeleteCampaigns';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

function PaymentMethod() {
    const [submitClick, setSubmitClick] = useState(false)
    const [AddNewCardShow, setAddNewCardShow] = useState(false);
    const [DeleteCampaignsShow, setDeleteCampaignsShow] = useState(false);

    return (
        <>
            <div className='top_heading_button'>
                <h1 className='main_heading_top'>Payment Method</h1>
            </div>

            <div className='choose_method_section'>

                <div className='credit_card_heading mb-md-5 mb-4'>
                    <h2> Select Method</h2>
                </div>

                <PayPalScriptProvider
                    options={{ "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID }}
                >
                    <PayPalButtons
                        createOrder={(_, actions) => {
                            return actions.order
                                .create({
                                    purchase_units: [
                                        {
                                            amount: {
                                                value: 250,
                                            },
                                        },
                                    ],
                                })
                                .then((orderId) => {
                                    return orderId;
                                });
                        }}
                        forceReRender={[250]}
                        className="heroBtnbutton"
                        onApprove={(_, actions) => {
                            return actions.order.capture().then((details) => {
                                let data = {
                                    // amount: grandTotalAmount(amount, couponAmount),
                                    // pay_from: 4,
                                    order_id: details.id,
                                    order_status: details.status,
                                    payer_detail: details.payer
                                }
                                setSubmitClick(true)
                            });
                        }}
                        style={{
                            layout: 'horizontal',
                            color: 'blue',
                            shape: 'rect',
                            label: 'pay',
                            height: 55,
                            tagline: false
                        }}
                    />
                </PayPalScriptProvider>

                <div className='multi_payment_card'>
                    <div className='card_payment'>
                        <input type="radio" id="demoCheckbox" name="checkbox" hidden defaultChecked />
                        <label for="demoCheckbox"> Bank Account </label>
                    </div>
                    <div className='card_payment'>
                        <input type="radio" id="demoCheckbox2" name="checkbox" hidden />
                        <label for="demoCheckbox2"> Credit / Debit Card </label>
                    </div>
                </div>

                <div className='credit_card_section'>
                    <div className='credit_card_heading'>
                        <h2>Saved Cards</h2>
                        <button type='button' className='btn_blue' onClick={() => setAddNewCardShow(true)}><AiOutlinePlus />New Card</button>
                    </div>

                    <div className='multi_saved_card'>
                        <div className='card_payment'>
                            <input type="radio" id="savedcard1" name="savedcard" hidden defaultChecked />
                            <label for="savedcard1">
                                <div className='user_card_section'>
                                    <div className='card_user_name'>
                                        <div className='card_logo'>
                                            <img src="/images/master_card.svg" alt="" />
                                        </div>
                                        <div className='user_card_img'>
                                            <h3>Jacky Rewet</h3>
                                            <p>******** 2564</p>
                                        </div>
                                    </div>
                                    <div className='delete_card'>
                                        <button type='button' className='delete_card_icon' onClick={() => setDeleteCampaignsShow(true)}><RiDeleteBinLine /></button>
                                    </div>
                                </div>
                            </label>
                        </div>
                        <div className='card_payment'>
                            <input type="radio" id="savedcard2" name="savedcard" hidden />
                            <label for="savedcard2">
                                <div className='user_card_section'>
                                    <div className='card_user_name'>
                                        <div className='card_logo'>
                                            <img src="/images/master_card.svg" alt="" />
                                        </div>
                                        <div className='user_card_img'>
                                            <h3>Jacky Rewet</h3>
                                            <p>******** 2564</p>
                                        </div>
                                    </div>
                                    <div className='delete_card'>
                                        <button type='button' className='delete_card_icon' onClick={() => setDeleteCampaignsShow(true)}><RiDeleteBinLine /></button>
                                    </div>
                                </div>
                            </label>
                        </div>
                        <div className='card_payment'>
                            <input type="radio" id="savedcard3" name="savedcard" hidden />
                            <label for="savedcard3">
                                <div className='user_card_section'>
                                    <div className='card_user_name'>
                                        <div className='card_logo'>
                                            <img src="/images/master_card.svg" alt="" />
                                        </div>
                                        <div className='user_card_img'>
                                            <h3>Jacky Rewet</h3>
                                            <p>******** 2564</p>
                                        </div>
                                    </div>
                                    <div className='delete_card'>
                                        <button type='button' className='delete_card_icon' onClick={() => setDeleteCampaignsShow(true)}><RiDeleteBinLine /></button>
                                    </div>
                                </div>
                            </label>
                        </div>
                        <div className='card_payment'>
                            <input type="radio" id="savedcard4" name="savedcard" hidden />
                            <label for="savedcard4">
                                <div className='user_card_section'>
                                    <div className='card_user_name'>
                                        <div className='card_logo'>
                                            <img src="/images/master_card.svg" alt="" />
                                        </div>
                                        <div className='user_card_img'>
                                            <h3>Jacky Rewet</h3>
                                            <p>******** 2564</p>
                                        </div>
                                    </div>
                                    <div className='delete_card'>
                                        <button type='button' className='delete_card_icon' onClick={() => setDeleteCampaignsShow(true)}><RiDeleteBinLine /></button>
                                    </div>
                                </div>
                            </label>
                        </div>
                        <div className='card_payment'>
                            <input type="radio" id="savedcard5" name="savedcard" hidden />
                            <label for="savedcard5">
                                <div className='user_card_section'>
                                    <div className='card_user_name'>
                                        <div className='card_logo'>
                                            <img src="/images/master_card.svg" alt="" />
                                        </div>
                                        <div className='user_card_img'>
                                            <h3>Jacky Rewet</h3>
                                            <p>******** 2564</p>
                                        </div>
                                    </div>
                                    <div className='delete_card'>
                                        <button type='button' className='delete_card_icon' onClick={() => setDeleteCampaignsShow(true)}><RiDeleteBinLine /></button>
                                    </div>
                                </div>
                            </label>
                        </div>
                        <div className='card_payment'>
                            <input type="radio" id="savedcard6" name="savedcard" hidden />
                            <label for="savedcard6">
                                <div className='user_card_section'>
                                    <div className='card_user_name'>
                                        <div className='card_logo'>
                                            <img src="/images/master_card.svg" alt="" />
                                        </div>
                                        <div className='user_card_img'>
                                            <h3>Jacky Rewet</h3>
                                            <p>******** 2564</p>
                                        </div>
                                    </div>
                                    <div className='delete_card'>
                                        <button type='button' className='delete_card_icon' onClick={() => setDeleteCampaignsShow(true)}><RiDeleteBinLine /></button>
                                    </div>
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
            </div>


            <CommonModal
                show={AddNewCardShow}
                onHide={() => setAddNewCardShow(false)}
                title="Add New Card"
                body={
                    <NewCardModal
                        onClickButton1={() => setAddNewCardShow(false)}
                    />
                }
            />

            <CommonModal
                show={DeleteCampaignsShow}
                onHide={() => setDeleteCampaignsShow(false)}
                modalClass="delete_Campaigns_modal"
                body={
                    <DeleteCampaigns
                        heading="Do you want to delete this card?"
                        onClickButton1={() => setDeleteCampaignsShow(false)}
                        btn1="Cancel"
                        btn2="Yes, Delete"
                    />
                }
            />

        </>
    )
}

export default PaymentMethod;