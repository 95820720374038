import { React, useEffect, useState } from 'react';
import '../../../pages/Style.scss';
import { TransactionManagementAction } from '../../../redux/AdminSlices/Common';
import NoDataFound from '../../../components/NoDataFound/NoDataFound';
import { Pagination } from "../../../components/Hooks/Pagination"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { DownloadFileInNewWindow } from '../../../utils/Function';
import { Button } from '../../../components/Common/Button';

function TransactionManagementList() {
    const [loader, setLoader] = useState(false);
    const [listDataArr, setListDataArr] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [perPageCount, setPerPageCount] = useState(10);
    const [transactionType, setTransactionType] = useState("");
    const [downloadLoader, setDownloadLoader] = useState(false)

    const [dateRange, setDateRange] = useState([new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date()]);
    const [startDate, endDate] = dateRange;
    useEffect(() => {
        let payload = {
            type: transactionType,
            perPage: perPageCount,
            page: currentPage,
        }
        let data = {
            startDate: startDate !== null ? startDate.getFullYear() + "-" + (startDate.getMonth() + 1) + "-" + startDate.getDate() : new Date(),
            endDate: endDate !== null ? endDate.getFullYear() + "-" + (endDate.getMonth() + 1) + "-" + endDate.getDate() : new Date(),
        }
        setLoader(true)
        TransactionManagementAction(payload, data, (response) => {
            if (response?.status === true) {
                setListDataArr(response)
            }
            setLoader(false)
        })();
    }, [TransactionManagementAction, perPageCount, currentPage, transactionType, startDate, endDate])

    const handleDateRange = (date) => {
        setDateRange(date);
    }

    const handleDownloadExcel = () => {
        let payload = {
            type: transactionType,
            perPage: perPageCount,
            page: currentPage,
            download: "csv"
        }
        let data = {
            startDate: startDate !== null ? startDate.getFullYear() + "-" + (startDate.getMonth() + 1) + "-" + startDate.getDate() : new Date(),
            endDate: endDate !== null ? endDate.getFullYear() + "-" + (endDate.getMonth() + 1) + "-" + endDate.getDate() : new Date(),
        }
        setDownloadLoader(true)
        TransactionManagementAction(payload, data, (response) => {
            if (response?.status === true) {
                DownloadFileInNewWindow(response?.data?.url)
            }
            setDownloadLoader(false)
        })();
    }

    return (
        <>
            <div className='top_heading_button'>
                <h1 className='main_heading_top'> Transaction Management </h1>
                <div className='d-flex align-items-center'>
                    <h2 className='me-4'> Total Commission: ${listDataArr?.data?.totalcommission || 0} </h2>
                    <Button
                        className="btn_blue"
                        text="Download Excel"
                        type="submit"
                        onClick={handleDownloadExcel}
                        loader={downloadLoader}
                        disabled={downloadLoader}
                    />
                </div>
            </div>

            <div className='admin_list_table'>
                <div className='filter_number_search_input'>
                    <div className='filter_select_number filter_search_common '>
                        <label className='common_label'>Show:</label>
                        <select className='common_input_filed' onChange={(e) => setPerPageCount(e.target.value)}>
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                        </select>
                    </div>

                    <div className='search_table_content filter_search_common'>

                        <label className='common_label'>Type:</label>
                        <select className='common_input_filed' onChange={(e) => setTransactionType(e.target.value)}>
                            <option value={0}> All types </option>
                            <option value={1}> Add money </option>
                            <option value={3}> Earn money PPC </option>
                            <option value={6}> Commission </option>
                            <option value={2}> Withdrawal </option>
                            <option value={4}> Campaign deduction </option>
                            <option value={5}> Campaign Refund </option>
                        </select>



                        <label className='common_label'> Select Date Range :</label>
                        <div className="headerSearchBx">
                            <DatePicker
                                className="form-control"
                                selectsRange={true}
                                startDate={startDate}
                                endDate={endDate}
                                onChange={(update) => {
                                    handleDateRange(update);
                                }}
                                isClearable={true}
                            />
                        </div>
                    </div>
                </div>
                <div className='request_campaigns_list'>
                    <div className='content_campaigns_user table-responsive'>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th> Order Id </th>
                                    <th> User Name </th>
                                    <th> Amount </th>
                                    <th> type </th>
                                    <th> Status </th>
                                    <th> Created At </th>
                                </tr>
                            </thead>
                            <tbody>

                                {
                                    !loader ?
                                        listDataArr?.data?.transaction_list?.length > 0 ?
                                            listDataArr?.data?.transaction_list?.map((value, index) => (
                                                <tr key={index}>
                                                    <td> {(currentPage - 1) * Number(listDataArr?.data?.pagination?.perPage) + (index + 1)}. </td>
                                                    <td> {value?.order_id ? value?.order_id : ""}</td>
                                                    <td> {value?.user_id?.user_name ? value?.user_id?.user_name : "-"} </td>
                                                    <td> {value?.amount ? `$${value?.amount}` : "-"} </td>
                                                    <td>
                                                        {value?.type == 1 ? "Addmoney" :
                                                            value?.type == 2 ? "Withdrawal" :
                                                                value?.type == 3 ? "Earn money PPC" :
                                                                    value?.type == 4 ? "Campaign deduction" :
                                                                        value?.type == 5 ? "Campaign Refund" :
                                                                            value?.type == 6 ? "Commission" :
                                                                                null
                                                        }
                                                    </td>
                                                    <td> {value?.status ? value?.status : "-"} </td>
                                                    <td>{moment(value?.updatedAt).format("DD MMM, YYYY")}</td>
                                                </tr>
                                            ))
                                            :
                                            <tr>
                                                <td colSpan={7}>
                                                    <NoDataFound />
                                                </td>
                                            </tr>
                                        :
                                        <tr>
                                            <td colSpan={7}>Loading...</td>
                                        </tr>
                                }
                            </tbody>
                        </table>

                        <div className='pagination_entries_section'>
                            <div className='entries_text'>
                                Showing {(currentPage - 1) * Number(listDataArr?.data?.pagination?.perPage) + 1} of {listDataArr?.data?.pagination?.totalItems} entries
                            </div>

                            {listDataArr?.data?.transaction_list?.length > 0 ?
                                <Pagination
                                    className="pagination-bar"
                                    currentPage={currentPage}
                                    totalCount={listDataArr?.data?.pagination?.totalItems}
                                    pageSize={listDataArr?.data?.pagination?.perPage}
                                    onPageChange={(page) => setCurrentPage(page)}
                                />
                                :
                                null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TransactionManagementList;