import { initializeApp } from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { GoogleAuthProvider, getAuth } from "firebase/auth";


// const firebaseConfig = initializeApp({
//     apiKey: "AIzaSyBMGjE-9Ip3z_LQ1OKTMJOe4-Pj4Vfdh1I",
//     authDomain: "glidesacledummy.firebaseapp.com",
//     projectId: "glidesacledummy",
//     storageBucket: "glidesacledummy.appspot.com",
//     messagingSenderId: "514475514673",
//     appId: "1:514475514673:web:abbfd544e0d6bb9e40ee10",
//     measurementId: "G-G5FPEV75LX"
// })

const firebaseConfig = initializeApp({
    apiKey: "AIzaSyA0O6yutVxLuPnIVkA2y3y1SnYiQcuT31M",
    authDomain: "glidescale-20ee2.firebaseapp.com",
    projectId: "glidescale-20ee2",
    storageBucket: "glidescale-20ee2.appspot.com",
    messagingSenderId: "694572138174",
    appId: "1:694572138174:web:f8370cc2ffd54dc97068ef",
    measurementId: "G-13KR19XW61"
  })

// Initialize Firebase
// export const db = firebaseConfig.firestore()
export const auth = getAuth();
export const googleProvider = new GoogleAuthProvider();
