import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
//import { HelmetProvider } from 'react-helmet-async';

/* Common Pages */
import ScrollToTop from './components/ScrollEffects/ScrollToTp';
import PageNotFound from './pages/PageNotFound/PageNotFound';

/* Layout */
import TheLayout from './components/Layouts/TheLayout';
import SuperAdminLayout from './components/Layouts/SuperAdminLayout';

/* Auth Page */
import AccountSelect from './pages/Auth/AccountSelect';
import Register from './pages/Auth/Register';
import Login from './pages/Auth/Login';
import ForgotPassword from './pages/Auth/ForgotPassword';
import Otp from './pages/Auth/Otp';
import ResetPassword from './pages/Auth/ResetPassword';

/* Front end Pages */
import Campaigns from './pages/Campaigns/Campaigns';
import ContactSupport from './pages/ContactSupport/ContactSupport';
import CheckQA from './pages/CheckQ&A/CheckQA';
import WatchTutorials from './pages/WatchTutorials/WatchTutorials';
import List from './pages/Campaigns/List';
import AboutUs from './pages/CMS//AboutUs';
import TermsAndConditions from './pages/CMS/TermsAndConditions';
import PrivacyPolicy from './pages/CMS/PrivacyPolicy';
import Profile from './pages/Profile/Profile';
import CampaignsReport from './pages/Report/CampaignsReport';
import MarketingReport from './pages/Report/MarketingReport';
import RevenueReport from './pages/Report/RevenueReport';
import Report from './pages/Report/Report';
import PaymentMethod from './pages/Payment/PaymentMethod';


/* Super Admin Pages */
import Dashboard from './pages/SuperAdminPage/Dashboard/Dashboard';
import CmsList from './pages/SuperAdminPage/CMS/CmsList';
import CampaignManagementList from "./pages/SuperAdminPage/CampaignsManagement/List"
import View from './pages/SuperAdminPage/CMS/View';
import Edit from './pages/SuperAdminPage/CMS/Edit';
import './App.scss';
import { setCurrentUser } from './redux/Slices/AuthSlice';
import store from './redux/Store';
import { AdminPrivateRoutes, PrivateRoutes } from './components/Common/PrivateRoutes';
import UsersManagementList from "./pages/SuperAdminPage/UsersManagement/List";
import UsersManagementListView from "./pages/SuperAdminPage/UsersManagement/View"
import TicketSupportManagementList from './pages/SuperAdminPage/TicketSupportManagement/List';
import AdminProfile from './pages/SuperAdminPage/AdminProfile/Profile';
import Wallet from './pages/Wallet/Wallet';
import BrowserCampaingnsView from "./pages/Campaigns/BrowserCampaingnsView"
import CampaingnsView from './pages/Campaigns/CampaingnsView';
import WatchTutorialList from './pages/SuperAdminPage/WatchTutorial/WatchTutorialList';
import Addvideo from './pages/SuperAdminPage/WatchTutorial/Addvideo';
import ContactTicket from './pages/ContactTicket/ContactTicket';
import SupportDetail from './pages/ContactTicket/SupportDetail';
import TicketDetail from './pages/SuperAdminPage/TicketSupportManagement/TicketDetail';
import QAList from './pages/SuperAdminPage/QaManagement/List';
import AddEditQAManagement from './pages/SuperAdminPage/QaManagement/AddEdit';
import TypeOfProductList from './pages/SuperAdminPage/TypeOfProductManagement/List';
import CampaignManagementDetails from './pages/SuperAdminPage/CampaignsManagement/Detail';
import ReportsManagementListing from './pages/SuperAdminPage/ReportsManagement/List';
import TransactionManagementList from './pages/SuperAdminPage/TransactionManagement/List';
import CampaignPPCRequest from './pages/Campaigns/CampaignPPCRequest';
import NotificationManagement from './pages/SuperAdminPage/NotificationManagement/List';
import EmailManagement from './pages/SuperAdminPage/EmailManagement/List';
import EditEmailManagement from './pages/SuperAdminPage/EmailManagement/Edit';
import EditNotificationManagement from './pages/SuperAdminPage/NotificationManagement/Edit';


var CryptoJS = require("crypto-js");


if (localStorage.glidescale_security) {
    var token = CryptoJS.AES.decrypt(localStorage.glidescale_security, process.env.REACT_APP_JWT_SECRET);
    var decryptedData = JSON.parse(token.toString(CryptoJS.enc.Utf8));
    store.dispatch(setCurrentUser(decryptedData?.user));
}


function App() {
    return (
        <Router>
            <ScrollToTop />
            <Routes>

                {/* PageNotFound */}
                <Route path="*" element={<PageNotFound />} />

                {/* Auth Page */}
                <Route path="/" element={<AccountSelect />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/forgot-password" element={<ForgotPassword />} exact />
                <Route path="/otp" element={<Otp />} exact />
                <Route path="/reset-password" element={<ResetPassword />} exact />
                <Route path='/campaign-request' element={<CampaignPPCRequest />} exact />

                {/* Pages front end */}
                <Route element={<PrivateRoutes />}>
                    <Route path='/' element={<TheLayout />}>
                        <Route path="/campaigns" element={<Campaigns />} exact />
                        <Route path="/campaigns/list" element={<List />} exact />
                        <Route path="/browse-campaigns/:name/:id" element={<BrowserCampaingnsView />} exact />
                        <Route path="/my-campaigns/:name/:id" element={<CampaingnsView />} exact />
                        <Route path="/joined-campaigns/:name/:id" element={<BrowserCampaingnsView />} exact />
                        <Route path="/request-campaigns/:name/:id" element={<BrowserCampaingnsView />} exact />
                        <Route path="/contact-support" element={<ContactSupport />} exact />
                        <Route path="/check-QA" element={<CheckQA />} exact />
                        <Route path="/watch-tutorials" element={<WatchTutorials />} exact />
                        <Route path="/terms-and-conditions" element={<TermsAndConditions />} exact />
                        <Route path="/privacy-policy" element={<PrivacyPolicy />} exact />
                        <Route path="/about-us" element={<AboutUs />} exact />
                        <Route path="/contact-ticket" element={<ContactTicket />} exact />
                        <Route path="/contact-ticket/detail/:title/:id" element={<SupportDetail />} exact />
                        <Route path="/profile" element={<Profile />} exact />
                        <Route path="/campaigns-report" element={<CampaignsReport />} exact />
                        <Route path="/marketing-report" element={<MarketingReport />} exact />
                        <Route path="/revenue-report" element={<RevenueReport />} exact />
                        <Route path="/report" element={<Report />} exact />
                        <Route path="/payment" element={<PaymentMethod />} exact />
                        <Route path="/wallet" element={<Wallet />} exact />
                    </Route>
                </Route>


                {/* Pages admin */}
                <Route element={<AdminPrivateRoutes />}>
                    <Route element={<SuperAdminLayout />}>
                        <Route path="/admin" element={<Dashboard />} exact />
                        <Route path="/campaigns-management" element={<CampaignManagementList />} exact />
                        <Route path="/campaigns-management/detail/:name/:id" element={<CampaignManagementDetails />} exact />
                        <Route path="/cms-list" element={<CmsList />} exact />
                        <Route path="/cms-list/view/:id" element={<View />} exact />
                        <Route path="/cms-list/edit/:id" element={<Edit />} exact />
                        <Route path="/users-management" element={<UsersManagementList />} exact />
                        <Route path="/report-management" element={<ReportsManagementListing />} exact />
                        <Route path="/users-management/:name/:id" element={<UsersManagementListView />} exact />
                        <Route path="/ticket-support-management" element={<TicketSupportManagementList />} exact />
                        <Route path="/ticket-support-management/detail/:title/:id" element={<TicketDetail />} exact />
                        <Route path="/type-of-product-management" element={<TypeOfProductList />} exact />
                        <Route path="/watch-tutorial-list" element={<WatchTutorialList />} exact />
                        <Route path="/watch-tutorial-list/add" element={<Addvideo />} exact />
                        <Route path="/watch-tutorial-list/edit" element={<Addvideo />} exact />
                        <Route path="/watch-tutorial-list/view" element={<Addvideo />} exact />
                        <Route path="/admin-profile" element={<AdminProfile />} exact />

                        <Route path="/qa-management" element={<QAList />} exact />
                        <Route path="/qa-management/view" element={<AddEditQAManagement />} exact />
                        <Route path="/qa-management/edit" element={<AddEditQAManagement />} exact />
                        <Route path="/qa-management/add" element={<AddEditQAManagement />} exact />

                        <Route path="/transaction-list" element={<TransactionManagementList />} exact />

                        <Route path="/email-management" element={<EmailManagement />} exact />
                        <Route path="/email-management/edit/:id" element={<EditEmailManagement />} exact />

                        <Route path="/notification-management" element={<NotificationManagement />} exact />
                        <Route path="/notification-management/edit/:id" element={<EditNotificationManagement />} exact />
                    </Route>
                </Route>
            </Routes>
        </Router>
    );
}

export default App;
