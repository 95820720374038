import { React } from 'react';
import '../../pages/Style.scss';


function MarketingReport() {

  return (
    <>
      <div className='top_heading_button'>
        <h1 className='main_heading_top'>Marketing Report</h1>
      </div>

    </>
  )
}

export default MarketingReport;