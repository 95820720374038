import { createSlice } from '@reduxjs/toolkit';
import api from '../Api';
import { authHeader } from '../authHeader';
import { ToastOverError, ToastOverSuccess } from '../../components/Common/ToastOver';


const initialState = {
	adminProfile: null,
	commonLoading: false,
	commonError: null,
};

const commonSlice = createSlice({
	name: 'commonSlice',
	initialState,
	reducers: {
		fetchDataStart(state) {
			state.commonLoading = true;
		},
		fetchAdminProfile(state, action) {
			state.commonLoading = false;
			state.adminProfile = action.payload
		},
		fetchDataError(state) {
			state.commonLoading = false;
		},
	},
});

export const {
	fetchDataStart,
	fetchAdminProfile,
	fetchDataError,
} = commonSlice.actions;

export const UserManagementListAction = (params, callback) => async () => {
	try {
		const data = await api?.api.get(`admin/user_management_list?type=${params.type}&perPage=${params.perPage}&page=${params.page}&searchText=${params.searchText}`, {
			headers: authHeader(),
		});
		callback(data)
	} catch (error) {
		ToastOverError(error?.response?.data?.message)
		callback(error?.response?.data)
	}
};

export const UserManagementStatusUpdateAction = (value, callback) => async () => {
	try {
		const data = await api?.api.post(`admin/user_status_update`, value, {
			headers: authHeader(),
		});
		callback(data)
		ToastOverSuccess(data?.message)
	} catch (error) {
		ToastOverError(error?.response?.data?.message)
		callback(error?.response?.data)
	}
};

export const AdminProfileAction = () => async (dispatch) => {
	try {
		dispatch(fetchDataStart());
		const data = await api?.api.get('admin/profile-view', {
			headers: authHeader(),
		});
		dispatch(fetchAdminProfile(data?.data));
	} catch (error) {
		dispatch(fetchDataError(error.message));
	}
};

export const AdminProfileUpdateAction = (value, callback) => async () => {
	try {
		const data = await api?.api.post('admin/profile-update', value, {
			headers: authHeader(),
		});
		ToastOverSuccess(data?.message)
		callback(data)
	} catch (error) {
		ToastOverError(error?.response?.data?.message)
		callback(error?.response?.data)
	}
};


export const fileUploadAction = (value, callback) => async () => {
	try {
		const data = await api?.api.post('app/users/upload_file', value, {
			headers: authHeader(),
		});
		ToastOverSuccess(data?.message)
		callback(data)
	} catch (error) {
		ToastOverError(error?.response?.data?.message)
		callback(error?.response?.data)
	}
};

export const CampaignManagementListAction = (params, callback) => async () => {
	try {
		const data = await api?.api.get(`admin/campaign_management_list?perPage=${params.perPage}&page=${params.page}&searchText=${params.searchText}`, {
			headers: authHeader(),
		});
		callback(data)
	} catch (error) {
		ToastOverError(error?.response?.data?.message)
		callback(error?.response?.data)
	}
};

export const CampaignManagementDetailAction = (value, callback) => async () => {
	try {
		const data = await api?.api.post(`admin/campaign_management_view?perPage=${value.perPage}&page=${value.page}&searchText=${value.searchText}`, value, {
			headers: authHeader(),
		});
		callback(data)
	} catch (error) {
		ToastOverError(error?.response?.data?.message)
		callback(error?.response?.data)
	}
};

export const CampaignStatusUpdateAction = (value, callback) => async () => {
	try {
		const data = await api?.api.post(`admin/campaign_status_update`, value, {
			headers: authHeader(),
		});
		callback(data)
		ToastOverSuccess(data?.message)
	} catch (error) {
		ToastOverError(error?.response?.data?.message)
		callback(error?.response?.data)
	}
};

export const SupportManagementListAction = (params, callback) => async () => {
	try {
		const data = await api?.api.get(`admin/contact-support-list?perPage=${params.perPage}&page=${params.page}&searchText=${params.searchText}`, {
			headers: authHeader(),
		});
		callback(data)
	} catch (error) {
		ToastOverError(error?.response?.data?.message)
		callback(error?.response?.data)
	}
};

export const SupportManagementStatusUpdateAction = (value, callback) => async () => {
	try {
		const data = await api?.api.post(`admin/ticket_status_update`, value, {
			headers: authHeader(),
		});
		callback(data)
		ToastOverSuccess(data?.message)
	} catch (error) {
		ToastOverError(error?.response?.data?.message)
		callback(error?.response?.data)
	}
};

export const DashboardAction = (callback) => async () => {
	try {
		const data = await api?.api.get(`admin/dashboard`, {
			headers: authHeader(),
		});
		callback(data)
	} catch (error) {
		ToastOverError(error?.response?.data?.message)
		callback(error?.response?.data)
	}
};

export const CreateWatchTutorialAction = (value, callback) => async () => {
	try {
		const data = await api?.api.post('admin/watch_tutorial_create', value, {
			headers: authHeader(),
		});
		ToastOverSuccess(data?.message)
		callback(data)
	} catch (error) {
		ToastOverError(error?.response?.data?.message)
		callback(error?.response?.data)
	}
};

export const WatchTutorialUpdateAction = (value, callback) => async () => {
	try {
		const data = await api?.api.post('admin/watch_tutorial_update', value, {
			headers: authHeader(),
		});
		ToastOverSuccess(data?.message)
		callback(data)
	} catch (error) {
		ToastOverError(error?.response?.data?.message)
		callback(error?.response?.data)
	}
};

export const WatchTutorialListAction = (params, callback) => async () => {
	try {
		const data = await api?.api.get(`admin/watch_tutorial_list?perPage=${params.perPage}&page=${params.page}&searchText=${params.searchText}`, {
			headers: authHeader(),
		});
		callback(data)
	} catch (error) {
		ToastOverError(error?.response?.data?.message)
		callback(error?.response?.data)
	}
};

export const WatchTutorialDeleteAction = (value, callback) => async () => {
	try {
		const data = await api?.api.post('admin/watch_tutorial_delete', value, {
			headers: authHeader(),
		});
		ToastOverSuccess(data?.message)
		callback(data)
	} catch (error) {
		ToastOverError(error?.response?.data?.message)
		callback(error?.response?.data)
	}
};



export const QAListAction = (params, callback) => async () => {
	try {
		const data = await api?.api.get(`admin/QA-list?perPage=${params.perPage}&page=${params.page}&searchText=${params.searchText}`, {
			headers: authHeader(),
		});
		callback(data)
	} catch (error) {
		ToastOverError(error?.response?.data?.message)
		callback(error?.response?.data)
	}
};
export const CreateQAAction = (value, callback) => async () => {
	try {
		const data = await api?.api.post('admin/QA_create', value, {
			headers: authHeader(),
		});
		ToastOverSuccess(data?.message)
		callback(data)
	} catch (error) {
		ToastOverError(error?.response?.data?.message)
		callback(error?.response?.data)
	}
};

export const QAUpdateAction = (value, callback) => async () => {
	try {
		const data = await api?.api.post('admin/QA-update', value, {
			headers: authHeader(),
		});
		ToastOverSuccess(data?.message)
		callback(data)
	} catch (error) {
		ToastOverError(error?.response?.data?.message)
		callback(error?.response?.data)
	}
};

export const QADeleteAction = (value, callback) => async () => {
	try {
		const data = await api?.api.post('admin/QA-delete', value, {
			headers: authHeader(),
		});
		ToastOverSuccess(data?.message)
		callback(data)
	} catch (error) {
		ToastOverError(error?.response?.data?.message)
		callback(error?.response?.data)
	}
};

export const ProudctTypeListingAction = (params, callback) => async () => {
	try {
		const data = await api?.api.get(`admin/product_type_list?perPage=${params.perPage}&page=${params.page}&searchText=${params.searchText}`, {
			headers: authHeader(),
		});
		callback(data)
	} catch (error) {
		ToastOverError(error?.response?.data?.message)
		callback(error?.response?.data)
	}
};
export const ProudctTypeAddAction = (value, callback) => async () => {
	try {
		const data = await api?.api.post('admin/product_type_create', value, {
			headers: authHeader(),
		});
		ToastOverSuccess(data?.message)
		callback(data)
	} catch (error) {
		ToastOverError(error?.response?.data?.message)
		callback(error?.response?.data)
	}
};
export const ProudctTypeUpdateAction = (value, callback) => async () => {
	try {
		const data = await api?.api.post('admin/product_type_update', value, {
			headers: authHeader(),
		});
		ToastOverSuccess(data?.message)
		callback(data)
	} catch (error) {
		ToastOverError(error?.response?.data?.message)
		callback(error?.response?.data)
	}
};
export const ProudctTypeStatusUpdateAction = (value, callback) => async () => {
	try {
		const data = await api?.api.post('admin/product_type_status_update', value, {
			headers: authHeader(),
		});
		ToastOverSuccess(data?.message)
		callback(data)
	} catch (error) {
		ToastOverError(error?.response?.data?.message)
		callback(error?.response?.data)
	}
};



export const ReportsManagementAction = (params, callback) => async () => {
	try {
		const data = await api?.api.get(`admin/reports_management_list?perPage=${params.perPage}&page=${params.page}&searchText=${params.searchText}&download=${params.download}`, {
			headers: authHeader(),
		});
		callback(data)
	} catch (error) {
		ToastOverError(error?.response?.data?.message)
		callback(error?.response?.data)
	}
};


export const TransactionManagementAction = (params, values, callback) => async () => {
	try {
		const data = await api?.api.post(`admin/all_transaction_history?perPage=${params.perPage}&page=${params.page}&type=${params.type}&download=${params.download}`, values, {
			headers: authHeader(),
		});
		callback(data)
	} catch (error) {
		ToastOverError(error?.response?.data?.message)
		callback(error?.response?.data)
	}
};


export const EmailManagementListAction = (callback) => async () => {
	try {
		const data = await api?.api.get(`admin/email_management_list`, {
			headers: authHeader(),
		});
		callback(data)
	} catch (error) {
		ToastOverError(error?.response?.data?.message)
		callback(error?.response?.data)
	}
};
export const emailViewAction = (value, callback) => async () => {
	try {
		const data = await api?.api.post('admin/email_management_view', value, {
			headers: authHeader(),
		});
		callback(data)
	} catch (error) {
		callback(error?.response?.data)
	}
};
export const emailUpdateAction = (value, callback) => async () => {
	try {
		const data = await api?.api.post('admin/email_management_update', value, {
			headers: authHeader(),
		});
		ToastOverSuccess(data?.message)
		callback(data)
	} catch (error) {
		ToastOverError(error?.response?.data?.message)
		callback(error?.response?.data)
	}
};



export const NotificationManagementListAction = (callback) => async () => {
	try {
		const data = await api?.api.get(`admin/notification_management_list`, {
			headers: authHeader(),
		});
		callback(data)
	} catch (error) {
		ToastOverError(error?.response?.data?.message)
		callback(error?.response?.data)
	}
};
export const NotificationUpdateAction = (value, callback) => async () => {
	try {
		const data = await api?.api.post('admin/notification_management_update', value, {
			headers: authHeader(),
		});
		ToastOverSuccess(data?.message)
		callback(data)
	} catch (error) {
		ToastOverError(error?.response?.data?.message)
		callback(error?.response?.data)
	}
};
export default commonSlice.reducer;
