import React, { useEffect, useState } from 'react';
import '../../pages/CMS/cms.scss';
import { CMSFrontViewAction } from '../../redux/Slices/CommonSlice';


function TermsAndConditions() {
    const [CmsViewData, setCmsViewData] = useState()
    useEffect(() => {
        CMSFrontViewAction("terms-and-conditions", (response) => {
            if (response?.status === true) {
                setCmsViewData(response.data)
            } else {
            }
        })();
    }, [CMSFrontViewAction])
    return (
        <>
            <div className='top_heading_button'>
                <h1 className='main_heading_top'> {CmsViewData?.title} </h1>
            </div>

            <div className='cms_page_common_section'>
                {(CmsViewData && Object.keys(CmsViewData)?.length > 0) &&
                    <p dangerouslySetInnerHTML={{ __html: CmsViewData?.description }} />
                }
            </div>
        </>
    )
}

export default TermsAndConditions;