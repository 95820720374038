import { React, useEffect, useState } from 'react';
import '../../../pages/Style.scss';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { SupportManagementListAction } from '../../../redux/AdminSlices/Common';
import NoDataFound from '../../../components/NoDataFound/NoDataFound';
import { Pagination } from "../../../components/Hooks/Pagination"
import { HasConsecutiveSpaces, debounceSearch } from '../../../utils/Function';
import { Link } from 'react-router-dom';


function TicketSupportManagementList() {
    const [loader, setLoader] = useState(false);
    const [listDataArr, setListDataArr] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [perPageCount, setPerPageCount] = useState(10);
    const [searchString, setSearchString] = useState("");

    useEffect(() => {
        let payload = {
            searchText: searchString,
            perPage: perPageCount,
            page: currentPage,
        }
        SupportManagementListAction(payload, (response) => {
            if (response?.status === true) {
                setListDataArr(response)
                setLoader(false)
            } else {
                setLoader(false)
            }
        })();
    }, [SupportManagementListAction, perPageCount, currentPage, searchString])

    const handleSearchFilter = debounceSearch((e) => {
        if (HasConsecutiveSpaces(e.target.value)) return <></>;
        setSearchString(e.target.value)
    }, 300);

    return (
        <>
            <div className='top_heading_button'>
                <h1 className='main_heading_top'> Support Management </h1>
            </div>

            <div className='admin_list_table'>
                <div className='filter_number_search_input'>
                    <div className='filter_select_number filter_search_common '>
                        <label className='common_label'>Show:</label>
                        <select className='common_input_filed' onChange={(e) => setPerPageCount(e.target.value)}>
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                        </select>
                    </div>

                    <div className='search_table_content filter_search_common'>
                        <label className='common_label'>Search:</label>
                        <div className="headerSearchBx">
                            <input
                                placeholder="Search"
                                aria-label="Search"
                                type="search"
                                className="common_input_filed"
                                onChange={handleSearchFilter}
                            />
                        </div>
                    </div>
                </div>
                <div className='request_campaigns_list'>
                    <div className='content_campaigns_user table-responsive'>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th> Name </th>
                                    <th> Email </th>
                                    <th> Title </th>
                                    <th> Message </th>
                                    <th> Unread count </th>
                                    <th> Status </th>
                                    <th className='text-end'>Action</th>
                                </tr>
                            </thead>
                            <tbody>

                                {
                                    !loader ?
                                        listDataArr?.data?.list?.length > 0 ?
                                            listDataArr?.data?.list?.map((value, index) => (
                                                <tr key={index}>
                                                    <td> {(currentPage - 1) * Number(listDataArr?.data?.pagination?.perPage) + (index + 1)}. </td>
                                                    <td> {value?.name ? value?.name : "-"} </td>
                                                    <td> {value?.email ? value?.email : "-"} </td>
                                                    <td> {value?.title ? value?.title : "-"} </td>
                                                    <td> {value?.message ? value?.message?.length > 50 ? `${value?.message.substring(0, 50)}...` : value?.message : "-"} </td>
                                                    <td> {value?.unread_message_count} </td>
                                                    <td>
                                                        <button
                                                            type='button'
                                                            className={`table_btn ${value?.status == 0 ? "pending" : "resolved"}`}
                                                        >
                                                            {value?.status == 0 ? "Pending" : "Resolved"}
                                                        </button>
                                                    </td>
                                                    <td className='text-end fixed-side'>
                                                        <Link to={`/ticket-support-management/detail/${value?.title?.replace(/[\/\s]+/g, '_').toLowerCase()}/${value?._id}`} type='button' className='common_btn_admin eye_btn_admin'><MdOutlineRemoveRedEye /></Link>
                                                    </td>
                                                </tr>
                                            ))
                                            :
                                            <tr>
                                                <td colSpan={7}>
                                                    <NoDataFound />
                                                </td>
                                            </tr>
                                        :
                                        <tr>
                                            <td colSpan={5}>Loading...</td>
                                        </tr>
                                }
                            </tbody>
                        </table>

                        <div className='pagination_entries_section'>
                            <div className='entries_text'>
                                Showing {(currentPage - 1) * Number(listDataArr?.data?.pagination?.perPage) + 1} of {listDataArr?.data?.pagination?.totalItems} entries
                            </div>
                            {listDataArr?.data?.list?.length > 0 ?
                                <Pagination
                                    className="pagination-bar"
                                    currentPage={currentPage}
                                    totalCount={listDataArr?.data?.pagination?.totalItems}
                                    pageSize={listDataArr?.data?.pagination?.perPage}
                                    onPageChange={(page) => setCurrentPage(page)}
                                />
                                :
                                null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TicketSupportManagementList;