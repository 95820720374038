import React, { useState } from 'react';
import { IoMdLock } from 'react-icons/io';
import './Auth.scss';
import { Form, Formik } from 'formik';
import * as Yup from "yup";
import { ResetPassAction } from '../../redux/Slices/AuthSlice';
import { Button } from '../../components/Common/Button';
import { InputText } from '../../components/Common/InputText';
import { useLocation, useNavigate } from 'react-router-dom';

function ResetPassword() {
    const [loader, setLoader] = useState(false)
    const location = useLocation()
    const navigate = useNavigate()
    return (

        <>
            <section className='login_register_section'>
                <div className='left_side_content_filed'>
                    <div className='login_input_filed'>
                        <div className='logo_images'>
                            <img src="/images/glide_scale_logo.svg" alt="" />
                        </div>
                        <div className='text_login_description'>
                            <h1>Welcome to Glidescale</h1>
                            <p>Kindly choose what you prefer</p>
                        </div>

                        <Formik
                            initialValues={{
                                password: '',
                            }}
                            validationSchema={Yup.object().shape({
                                password: Yup.string().required("Password is Required!"),
                                confirm_password: Yup.string().required("Confirm password is Required!")
                                .oneOf([Yup.ref('password'), null], "Confirm Password not matched!")                                ,
                            })}
                            onSubmit={(value) => {
                                const payload = {
                                    password: value?.password,
                                    confirm_password: value?.confirm_password,
                                    token: location?.search.split("?token=").pop()
                                }
                                setLoader(true)
                                ResetPassAction(payload, (response) => {
                                    if (response?.status === true) {
                                        setLoader(false)
                                        navigate("/login")
                                    } else {
                                        setLoader(false)
                                    }
                                })();
                            }}
                        >
                            {(formik) => {
                                return (
                                    <Form>

                                        <div className='input_filed_login_register'>
                                            <InputText
                                                name="password"
                                                type="password"
                                                placeholder='New Password'
                                                value={formik.values?.password}
                                                FieldIcon={<IoMdLock />}
                                                className="common_input_filed"
                                            />
                                            <InputText
                                                name="confirm_password"
                                                type="password"
                                                placeholder='Confirm New Password'
                                                value={formik.values?.confirm_password}
                                                FieldIcon={<IoMdLock />}
                                                className="common_input_filed"
                                            />
                                        </div>

                                        <div className='sign_in_btn'>
                                            <Button
                                                className="sign-in"
                                                text='Submit'
                                                type="submit"
                                                loader={loader}
                                                disabled={!(formik.isValid && formik.dirty) || loader}
                                            />
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>

                    </div>
                </div>

                <div className='right_side_content'>
                    <div className='right_login_description'>
                        <div className='img_right_side'>
                            <img src="/images/login_right_side.svg" alt="" />
                        </div>
                        <div className='content_right_side'>
                            <h2>Your Security First, Efficiency Paramount.</h2>
                            <p> Glidescale employs an array of defensive measures to ensure you and your marketing is as safe and reliable as possible. Freely create your own or join a PPC Affiliate Program without the hassle or worry.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ResetPassword