import React from 'react';
import "./Modal.scss";
import { DotLoader } from '../Common/DotLoader';
import { MdDeleteForever } from 'react-icons/md';
import { IoAlertOutline } from 'react-icons/io5';


function DeleteCampaigns(props) {

    const { btn1, btn2, heading, onClickButton1, onClickButton2, description_text, loader, deleteIcon = false, logOutIcon= false,  ...rest } = props

    return (
        <>
            <div className='delete_content_camp'>
                {deleteIcon ?
                    <div className='delete_cion_box'>
                        <MdDeleteForever />
                    </div>
                    : null}

                {logOutIcon ?
                    <div className='delete_cion_box'>
                        <IoAlertOutline />
                    </div>
                    : null}
                <h1>{heading}</h1>
                <p className='description_text'>{description_text}</p>
            </div>
            <div className='new_Campaigns_input tow_btn_row'>
                <button type='button' className='btn_blue grey_btn' onClick={onClickButton1}>{btn1}</button>
                <button type='button' className='btn_blue delete_btn' disabled={loader ? true : false} onClick={onClickButton2}>{loader ? <DotLoader /> : btn2}</button>
            </div>
        </>
    )
}

export default DeleteCampaigns;