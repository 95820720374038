import React, { useState } from 'react';
import "./Modal.scss";
import { Button } from '../Common/Button';
import { Form, Formik } from 'formik';
import * as Yup from "yup";
import { ChangePassAction } from '../../redux/Slices/AuthSlice';
import { InputText } from '../Common/InputText';


function ChangePassword({ hideModal }) {
    // const [passwordType, setPasswordType] = useState("password");
    // const [passwordInput, setPasswordInput] = useState("");
    const [loader, setLoader] = useState(false)

    // const handlePasswordChange = (evnt) => {
    //     setPasswordInput(evnt.target.value);
    // }
    // const togglePassword = () => {
    //     if (passwordType === "password") {
    //         setPasswordType("text")
    //         return;
    //     }
    //     setPasswordType("password")
    // }

    return (
        <>
            <Formik
                initialValues={{
                    current_password: '',
                    password: '',
                    confirm_password: '',
                }}
                validationSchema={Yup.object().shape({
                    current_password: Yup.string().required("Current Password is Required!"),
                    password: Yup.string().required("Password is Required!"),
                    confirm_password: Yup.string().required("Confirm Password is Required!")
                        .oneOf([Yup.ref('password'), null], "Confirm Password not matched!"),
                })}
                onSubmit={(value) => {
                    const payload = {
                        current_password: value?.current_password,
                        password: value?.password,
                        confirm_password: value?.confirm_password,
                    }
                    setLoader(true)
                    ChangePassAction(payload, (response) => {
                        if (response?.status === true) {
                            setLoader(false)
                            hideModal()
                        } else {
                            setLoader(false)
                        }
                    })();
                }}
            >
                {(formik) => {
                    return (
                        <Form>
                            <div className='delete_content_camp'>
                                <h1>Change Password</h1>
                            </div>
                            <div className='change_password_filed'>
                                <div className='row gy-5 gy-md-0'>
                                    <div className='col-md-4'>
                                        <InputText
                                            name="current_password"
                                            type="password"
                                            placeholder='Enter Current Password'
                                            label="Current Password"
                                            value={formik.values?.current_password}
                                            className="common_input_filed"
                                        />
                                    </div>
                                    <div className='col-md-4'>
                                        <InputText
                                            name="password"
                                            type="password"
                                            placeholder='Enter New Password'
                                            label="New Password"
                                            value={formik.values?.password}
                                            className="common_input_filed"
                                        />
                                    </div>
                                    <div className='col-md-4'>
                                        <InputText
                                            name="confirm_password"
                                            type="password"
                                            placeholder='Re-Enter New Password'
                                            label="New Password"
                                            value={formik.values?.confirm_password}
                                            className="common_input_filed"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='new_Campaigns_input tow_btn_row'>
                                <Button
                                    className="btn_blue"
                                    text='Update'
                                    type="submit"
                                    loader={loader}
                                    disabled={!(formik.isValid && formik.dirty) || loader}
                                />
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </>
    )
}
export default ChangePassword;