import { createSlice } from '@reduxjs/toolkit';
import api from '../Api';
import { authHeader } from '../authHeader';
import { ToastOverError, ToastOverSuccess } from '../../components/Common/ToastOver';


const initialState = {
	cmsList: null,
	cmsLoading: false,
	cmsError: null,
};

const cmsSlice = createSlice({
	name: 'cmsSlice',
	initialState,
	reducers: {
		fetchDataStart(state) {
			state.cmsLoading = true;
		},
		fetchCmsList(state, action) {
			state.cmsLoading = false;
			state.cmsList = action.payload
		},
		fetchDataError(state) {
			state.cmsLoading = false;
		},
	},
});

export const {
	fetchDataStart,
	fetchCmsList,
	fetchDataError,
} = cmsSlice.actions;

export const cmsListAction = () => async (dispatch) => {
	try {
		dispatch(fetchDataStart());
		const data = await api?.api.get('admin/cms-list', {
			headers: authHeader(),
		});
		dispatch(fetchCmsList(data?.data));
	} catch (error) {
		dispatch(fetchDataError(error.message));
	}
};

export const cmsViewAction = (value, callback) => async () => {
	try {
		const data = await api?.api.post('admin/cms-view', value, {
			headers: authHeader(),
		});
		callback(data)
	} catch (error) {
		callback(error?.response?.data)
	}
};

export const cmsUpdateAction = (value, callback) => async () => {
	try {
		const data = await api?.api.post('admin/cms-update', value, {
			headers: authHeader(),
		});
		ToastOverSuccess(data?.message)
		callback(data)
	} catch (error) {
		ToastOverError(error?.response?.data?.message)
		callback(error?.response?.data)
	}
};

export default cmsSlice.reducer;
