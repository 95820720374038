import React from 'react';
import { Modal } from 'react-bootstrap';

export const CommonModal = ({ show, onHide, title, body, modalClass }) => {
    return (
        <Modal show={show} onHide={onHide} className={`new_Campaigns_modal ${modalClass}`} backdrop="static" keyboard={false} centered>
            <Modal.Header closeButton>
                <Modal.Title><h4>{title}</h4></Modal.Title>
            </Modal.Header>
            <Modal.Body>{body}</Modal.Body>
        </Modal>
    );
};