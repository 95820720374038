import React from 'react'; 
import "../../components/Modal/Modal.scss";


function NewCardModal({onClickButton1}) {
  return (
    <>
        <div className='new_Campaigns_create_section'>
          <div className='new_Campaigns_input'>
            <label className='common_label'>Name on Card</label>
            <input type="text" className='common_input_filed' />
          </div>
          <div className='new_Campaigns_input'>
            <label className='common_label'>Card Number</label>
            <input type="text" className='common_input_filed' />
          </div>
          <div className='new_Campaigns_input'>
            <label className='common_label'>Expiry date</label>
            <input type="text" className='common_input_filed' />
          </div>
          <div className='new_Campaigns_input'>
            <label className='common_label'>CVV</label>
            <input type="text" className='common_input_filed' />
          </div>

          <div className='new_Campaigns_input tow_btn_row'>
          <button type='button' className='btn_blue'>Add Card</button>
          </div>
        </div>
    </>
  )
}

export default NewCardModal;