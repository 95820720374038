import { React, useEffect, useState } from 'react';
import '../../pages/Style.scss';
import { BiMessage } from "react-icons/bi";
import { Link, useParams } from 'react-router-dom';
import { UserSupportChatAction, UserSupportDetailAction } from '../../redux/Slices/CommonSlice';
import moment from 'moment';
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { Button } from '../../components/Common/Button';
import NoDataFound from '../../components/NoDataFound/NoDataFound';
import { fileUploadAction } from '../../redux/AdminSlices/Common';
import { LuDownload } from 'react-icons/lu';
import { TfiClip } from 'react-icons/tfi';
import { ToastOverError } from '../../components/Common/ToastOver';

function SupportDetail() {
    const { id } = useParams()
    const [supportDetailData, setSupportDetailData] = useState({});
    const [replySubmit, setReplySubmit] = useState(false)
    const [statusUpdate, setStatusUpdate] = useState(1)
    const SUPPORTED_FILE_FORMAT = ["image/jpg", "image/png", "image/jpeg", "pdf", "application/pdf"]

    useEffect(() => {
        UserSupportDetailAction({ support_id: id }, (response) => {
            if (response?.status === true) {
                setSupportDetailData(response?.data)
            }
        })();
    }, [UserSupportDetailAction, statusUpdate])

    const handleUplaodFile = (e) => {
        if (!SUPPORTED_FILE_FORMAT.includes(e.target.files[0]?.type)) {
            ToastOverError("Unsupported file format")
            e.target.value = null
            return <></>
        }
        let formData = new FormData();
        formData.append("file", e.target.files[0]);
        fileUploadAction(formData, (response) => {
            if (response?.status === true) {
                setReplySubmit(true)
                UserSupportChatAction({ type: 2, doc_url: response?.data[0]?.file_url, doc_name: response?.data[0]?.name, doc_size: response?.data[0]?.size, support_id: id }, (response) => {
                    if (response?.status === true) {
                        setStatusUpdate(statusUpdate + 1)
                    }
                    setReplySubmit(false)
                })();
            }
        })();
    }

    function convertFileSizeToMB(fileSizeInBytes) {
        var fileSizeInKB = fileSizeInBytes / 1024;
        return fileSizeInKB.toFixed(2);
    }

    return (
        <>
            <div className='top_heading_button'>
                <h1 className='main_heading_top'>Support</h1>

                <Link className='btn_blue' to="/contact-ticket">Back</Link>
            </div>

            <div className='contact_ticket_page'>
                <div className='row g-3 g-md-5'>
                    <div className='col-12'>
                        <div className='content_box_ticket'>
                            <h3> {supportDetailData?.title} </h3>
                            <p> {supportDetailData?.message} </p>

                            <div className='date_comment_box'>
                                <div className='date'> {moment(supportDetailData?.createdAt).format("DD MMM, YYYY")} </div>
                                <div className='comment_number'> <BiMessage /> {supportDetailData?.chat_list?.length} </div>
                            </div>

                            <div className='date_comment_box ticket_code_status'>
                                <div className='ticket_code'>
                                    Ticket : <span> {supportDetailData?._id} </span>
                                </div>
                                <button className={`ticket_status_btn ${supportDetailData?.status == 0 ? "active" : "closed"}`} type='button'>
                                    {supportDetailData?.status == 0 ? "Active" : "Closed"}
                                </button>
                            </div>
                        </div>

                        <div className="user_card_admin_message">
                            <div className="massage_admin_user">
                                {supportDetailData.chat_list?.length > 0 ?
                                    supportDetailData.chat_list?.map((value, index) => (
                                        <div className='card_admin_box' key={index}>
                                            <div className='img_content_box'>
                                                <div className='user_profile'>
                                                    <img
                                                        src={value?.sender_id?.profile ?
                                                            value?.sender_id?.profile
                                                            : "/images/dummy-image.jpg"}
                                                        referrerPolicy="no-referrer"
                                                        alt={value?.sender_id?.user_name}
                                                    />
                                                </div>
                                                <div className='content_user_box'>
                                                    <div className='top_name_date'>
                                                        <h3> {value?.sender_id?.user_name} </h3>
                                                        <p> {moment(value?.createdAt).format("DD MMM'YY, hh:mm a")} </p>
                                                    </div>
                                                    {value?.message ?
                                                        <p> {value?.message} </p>
                                                        :
                                                        <div className='chat-document-file'>
                                                            <div className='icon_name_img'>
                                                                <div className='icon'>
                                                                    {value?.doc_name?.split('.').pop('') === "pdf" ?
                                                                        <img className="img-fluid" referrerPolicy="no-referrer" src="/images/file/PDF_file_icon.svg" alt="PDF" />
                                                                        :
                                                                        <img className="img-fluid" referrerPolicy="no-referrer" src="/images/dummy-image.jpg" alt="Image" />
                                                                    }
                                                                </div>
                                                                <div className='detail'>
                                                                    <p> {value?.doc_name} </p>
                                                                    <span> {convertFileSizeToMB(value?.doc_size)}KB </span>
                                                                </div>
                                                            </div>
                                                            <div className='down_load_btn'>
                                                                <div className='download'>
                                                                    <a href={value?.doc_url} target='_blank' className='download_icon'> <LuDownload /> </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                    :
                                    <div className='message_not_found_img'>
                                        <NoDataFound />
                                    </div>
                                }
                            </div>
                            <div className="typing_message_box_main">
                                {supportDetailData?.status == 0 ?
                                    <Formik
                                        initialValues={{
                                            message: "",
                                        }}
                                        validationSchema={() => Yup.object({
                                            message: Yup.string().required("Message is required!"),
                                        })}
                                        onSubmit={(values, { resetForm }) => {
                                            if (values.message.trim().length > 0) {
                                                setReplySubmit(true)
                                                UserSupportChatAction({ ...values, type: 1, support_id: id }, (response) => {
                                                    if (response?.status === true) {
                                                        resetForm()
                                                        setStatusUpdate(statusUpdate + 1)
                                                    }
                                                    setReplySubmit(false)
                                                })()
                                            }
                                        }}
                                    >
                                        {(formik) => (
                                            <Form className='typing_message_box'>
                                                <div className='typing_main_box'>
                                                    <Field
                                                        rows="5"
                                                        placeholder="Write message here"
                                                        name="message"
                                                        className="typing_input"
                                                        maxLength="5000"
                                                    />
                                                    <Button
                                                        text={
                                                            <>
                                                                <svg id="Group_49773" data-name="Group 49773" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path id="Path_80030" data-name="Path 80030" d="M0,0H24V24H0Z" fill="none"></path><line id="Line_150" data-name="Line 150" y1="11" x2="11" transform="translate(10 3)" fill="none" stroke="Currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"></line><path id="Path_80031" data-name="Path 80031" d="M21,3,14.5,21a.55.55,0,0,1-1,0L10,14,3,10.5a.55.55,0,0,1,0-1L21,3" fill="none" stroke="Currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"></path></svg>
                                                            </>
                                                        }
                                                        loader={replySubmit}
                                                        className="sent_message_icon"
                                                        type="submit"
                                                        disabled={!formik.isValid || (replySubmit)}
                                                    />

                                                    <div className='uploadFile-block'>
                                                        <input type='file' id='uploadfile' onChange={handleUplaodFile} accept=".jpg, .jpeg, .png, .gif, .pdf" hidden />
                                                        <label htmlFor='uploadfile'> <TfiClip /> </label>
                                                    </div>
                                                </div>
                                                <small className='mt-2 d-block'> Attach Files (pdf, png, jpg and jpeg files only) </small>
                                            </Form>
                                        )}
                                    </Formik>
                                    :
                                    <div className='closed_card'> Your ticket was closed by admin, so now you can`t reply on this. </div>
                                }
                            </div>
                        </div>


                    </div>
                </div>
            </div >

        </>
    )
}

export default SupportDetail;