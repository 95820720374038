import { ErrorMessage, Field } from "formik";
import React from "react";
import TextErrorMsg from "./TextErrorMsg";


export const InputText = ({
    name,
    type,
    value,
    label,
    placeholder,
    disabled,
    autoComplete,
    maxLength,
    minLength,
    max,
    min,
    hidden,
    id,
    FieldIcon,
    inputClass,
    className,
    inputDivClass = null,
}) => {
    return (
        <>
            <div className={`form_filed_common ${inputDivClass}`}>
                <div className={`user_input ${inputClass}`}>
                    {label && <label className='common_label'> {label}</label>}
                    {disabled ?
                        <Field
                            value={value}
                            name={name}
                            type={type}
                            className={className}
                            placeholder={placeholder}
                            disabled={disabled}
                            onChange={null}
                            autoComplete={autoComplete}
                            maxLength={maxLength}
                            minLength={minLength}
                            max={max}
                            min={min}
                            id={id}
                            hidden={hidden}
                        />
                        :
                        <Field
                            value={value}
                            name={name}
                            type={type}
                            className={className}
                            placeholder={placeholder}
                            autoComplete={autoComplete}
                            maxLength={maxLength}
                            minLength={minLength}
                            max={max}
                            min={min}
                            id={id}
                            hidden={hidden}
                        />
                    }
                    <div className='user_icon'>{FieldIcon}</div>
                </div>
                <ErrorMessage name={name} component={TextErrorMsg} />
            </div>
        </>
    );
};


export const SelectBox = ({
    name,
    value,
    className,
    disabled,
    options,
    label,
    id,
    inputDivClass = null
}) => {
    return (
        <>
            <div className={`form_filed_common ${inputDivClass}`}>
                <div className="user_input">
                    {label && <label className='common_label'>
                        {label}
                    </label>}
                    <Field as="select" className={className} disabled={disabled} value={value} name={name} id={id}>
                        {options}
                    </Field>
                </div>
                <ErrorMessage name={name} component={TextErrorMsg} />
            </div>
        </>
    );
};

export const CheckBox = ({
    name,
    value,
    className,
    disabled,
    type,
    subLabel,
    label,
    id,
    defaultChecked,
}) => {
    return (
        <>
            <label className={className}>
                <Field type={type} name={name} id={id} value={value} disabled={disabled} defaultChecked={defaultChecked} />
                <span>{subLabel}</span>
            </label>
            <ErrorMessage name={name} component={TextErrorMsg} />
        </>
    );
};

export const TextArea = ({
    name,
    value,
    disabled,
    label,
    rows,
    className,
    placeholder,
    id,
    maxlength
}) => {
    return (
        <>
            {label && <label className='common_label'>
                {label}
            </label>}
            <Field as="textarea" placeholder={placeholder} maxLength={maxlength} name={name} id={id} value={value} rows={rows} className={className} disabled={disabled} />
            <ErrorMessage name={name} component={TextErrorMsg} />
        </>
    );
};


// export const MultiselectBox = ({
//     name,
//     label,
//     options,
//     className,
//     FieldOnchange,
// }) => {
//     return (
//         <>
//             <Field name={name}>
//                 {({ field }) => {
//                     const isOptionsEmpty = !options || options.length === 0;

//                     if (isOptionsEmpty && field.value !== null) {
//                         field.onChange({
//                             target: {
//                                 name: name,
//                                 value: null,
//                             },
//                         });
//                     }

//                     return (
//                         <div>
//                             {label && <label className='form-lable' htmlFor={name}>
//                                 {label}
//                             </label>}
//                             <MultiSelect
//                                 options={options}
//                                 className={className}
//                                 value={field.value || []}
//                                 onChange={(selectedList) => {
//                                     const selectedIds = selectedList?.map((value) => value?.value)
//                                     FieldOnchange(name, selectedIds)
//                                     field.onChange({
//                                         target: {
//                                             name: name,
//                                             value: selectedList,
//                                         },
//                                     });
//                                 }}
//                             />
//                         </div>
//                     )
//                 }}
//             </Field>
//             <ErrorMessage name={name} component={TextErrorMsg} />
//         </>
//     );
// };