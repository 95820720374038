import React, { useEffect } from 'react';
import { BsArrowRight } from 'react-icons/bs';
import './Auth.scss';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

function AccountSelect() {
    const { isAuthenticated, login } = useSelector((state) => state.auth)
    const navigate = useNavigate()
    useEffect(() => {
        if (isAuthenticated && login?.role_id === 2) {
            navigate("/campaigns")
        } else if(isAuthenticated && login?.role_id === 1){
            navigate("/admin")
        }
    }, [isAuthenticated])
    return (
        <>
            <section className='login_register_section'>
                <div className='left_side_content_filed'>
                    <div className='login_input_filed'>
                        <div className='logo_images'>
                            <img src="/images/glide_scale_logo.svg" alt="" />
                        </div>
                        <div className='text_login_description'>
                            <h1>Welcome to Glidescale</h1>
                            <p>Kindly choose what you prefer</p>
                        </div>

                        <div className='login_button'>
                            <Link to="/login" className='btn_login_button' >
                                <div className='icon_text'>
                                    {/* <div className='icon_plus'>
                                        <AiOutlineCheckCircle />
                                    </div> */}
                                    Login to my account
                                </div>
                                <div className='arrow_right_icon'>
                                    <BsArrowRight />
                                </div>
                            </Link>
                            <Link to="/register" className='btn_login_button' >
                                <div className='icon_text'>
                                    {/* <div className='icon_plus'>
                                        <AiOutlinePlusCircle />
                                    </div> */}
                                    Sign up new account
                                </div>
                                <div className='arrow_right_icon'>
                                    <BsArrowRight />
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>

                <div className='right_side_content'>
                    <div className='right_login_description'>
                        <div className='img_right_side'>
                            <img src="/images/login_right_side.svg" alt="" />
                        </div>
                        <div className='content_right_side'>
                            <h2>Your Security First, Efficiency Paramount.</h2>
                            <p> Glidescale employs an array of defensive measures to ensure you and your marketing is as safe and reliable as possible. Freely create your own or join a PPC Affiliate Program without the hassle or worry.
</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AccountSelect