import React, { useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from "yup";
import "../../components/Modal/Modal.scss"
import { InputText, SelectBox } from '../../components/Common/InputText';
import { CompleteProfileAction, ViewProfileAction } from '../../redux/Slices/AuthSlice';
import { Button } from '../../components/Common/Button';
import { ToastOverError } from '../../components/Common/ToastOver';
import { useDispatch } from 'react-redux';
import { fileUploadAction } from '../../redux/AdminSlices/Common';
import ImagePreview from '../../components/ImagePreview';
import TextErrorMsg from '../../components/Common/TextErrorMsg';


function CompleteProfile(props) {
    const dispatch = useDispatch()
    const { hideModal, ...rest } = props
    const [loader, setLoader] = useState(false)
    const [image, setImage] = useState(null)
    const [imageFile, setImageFile] = useState(null)

    // const handleChange = (e) => {
    //     const { name } = e.target
    //     if (name === "profile_photo") {
    //         const extension = e?.target?.files[0]?.name?.split('.')?.pop('')
    //         setImageFile(e?.target?.files[0])
    //         if (e?.target?.files[0] && (extension.includes("png") || extension.includes("jpg") || extension.includes("jpeg") || extension.includes("webp"))) {
    //             setImage(URL.createObjectURL(e.target.files[0]));
    //         } else if (e?.target?.files[0]) {
    //             ToastOverError("Only jpeg, png, webp allowed!")
    //         }
    //     }
    // }

    const SUPPORTED_FILE_FORMAT = ["image/jpg", "image/png", "image/jpeg"]

    return (
        <>
            <Formik
                initialValues={{
                    full_name: '',
                    experience: '',
                    certificate: '',
                    company_name: '',
                    // bank_name: '',
                    // bank_holder_name: '',
                    // account_number: '',
                    // branch_code: '',
                    // account_type: '',
                    // cpf_number: '',
                    profile_image: '',
                }}
                validationSchema={Yup.object().shape({
                    // bank_name: Yup.string().required("Bank Name is Required!"),
                    // bank_holder_name: Yup.string().required("Bank Holder_name is Required!"),
                    // account_number: Yup.string().required("Account Number is Required!"),
                    // branch_code: Yup.string().required("Branch Code is Required!"),
                    // account_type: Yup.string().required("Account Type is Required!"),
                    // cpf_number: Yup.string().required("CPF Number is Required!"),
                    profile_image: Yup.mixed().nullable().notRequired()
                        .test(
                            "FILE_FORMAT",
                            "Unsupported file format",
                            (value) => !value || (value && (typeof value === "object" ? SUPPORTED_FILE_FORMAT.includes(value?.type) : true))
                        ),
                })}
                onSubmit={(value) => {
                    const payload = {
                        full_name: value?.full_name,
                        experience: value?.experience,
                        certificate: '',
                        company_name: value?.company_name,
                        // bank_name: value?.bank_name,
                        // bank_holder_name: value?.bank_holder_name,
                        // account_number: value?.account_number,
                        // branch_code: value?.branch_code,
                        // account_type: value?.account_type,
                        // cpf_number: value?.cpf_number,
                        profile: value?.profile_image
                    }
                    setLoader(true)
                    CompleteProfileAction(payload, (response) => {
                        if (response?.status === true) {
                            dispatch(ViewProfileAction())
                            hideModal()
                            setLoader(false)
                        } else {
                            setLoader(false)
                        }
                    })();
                }}
            >
                {(formik) => {
                    return (
                        <Form>
                            <div className='complete_profile_section'>
                                <h1>Complete Profile</h1>
                                <div className='complete_profile_input'>

                                    <div className='profile-edit'>
                                        <Field name="profile_image">
                                            {({ form, field }) => {
                                                return (
                                                    <>
                                                        <input name={field.name} type="file" hidden={true} id='profileEdit'
                                                            onChange={(e) => {
                                                                if (SUPPORTED_FILE_FORMAT.includes(e.target.files[0]?.type)) {
                                                                    setLoader(true)
                                                                    let formData = new FormData();
                                                                    formData.append("file", e.target.files[0]);
                                                                    fileUploadAction(formData, (response) => {
                                                                        if (response?.status === true) {
                                                                            setLoader(false)
                                                                            form.setFieldValue(field.name, response?.data[0].file_url)
                                                                        } else {
                                                                            setLoader(false)
                                                                            ToastOverError(response?.data?.message)
                                                                        }
                                                                    })();
                                                                } else {
                                                                    form.setFieldValue(field.name, "")
                                                                    e.target.value = null
                                                                    ToastOverError("Unsupported file format")
                                                                }
                                                            }} />
                                                        <label htmlFor="profileEdit" className='edit-label'>Change</label>
                                                    </>
                                                )
                                            }}
                                        </Field>

                                        <div className='img-container'>
                                            {formik.values.profile_image ?
                                                <ImagePreview file={formik.values.profile_image} imageAlt={formik.values.full_name} />
                                                :
                                                <ImagePreview imageAlt={formik.values.full_name} />
                                            }
                                        </div>
                                    </div>
                                    <div className='text-center'> <ErrorMessage name="profile_image" component={TextErrorMsg} /> </div>

                                    <div className='row g-4 g-md-5'>
                                        <div className='col-md-6'>
                                            <InputText
                                                name="full_name"
                                                type="text"
                                                placeholder='Full Name'
                                                label="Full Name"
                                                value={formik.values?.full_name}
                                                className="common_input_filed"
                                            />
                                        </div>
                                        <div className='col-md-6'>
                                            <InputText
                                                name="company_name"
                                                type="text"
                                                placeholder='Company Name'
                                                label="Company Name"
                                                value={formik.values?.company_name}
                                                className="common_input_filed"
                                            />
                                        </div>
                                        <div className='col-md-6'>
                                            <InputText
                                                name="certificate"
                                                type="file"
                                                label="Resume"
                                                inputClass="common_input_filed_file_common"
                                            />
                                        </div>
                                        <div className='col-md-6'>
                                            <InputText
                                                name="experience"
                                                type="text"
                                                label="Total Experience"
                                                placeholder="Experience"
                                                value={formik.values?.experience}
                                                className="common_input_filed"
                                            />
                                        </div>
                                        {/* <div className='col-12'>
                                            <h3 className='middle_heading'>Add Bank Account</h3>
                                        </div>
                                        <div className='col-md-6'>
                                            <InputText
                                                name="bank_name"
                                                type="text"
                                                placeholder='Bank Name*'
                                                label="Bank Name"
                                                value={formik.values?.bank_name}
                                                className="common_input_filed"
                                            />
                                        </div>
                                        <div className='col-md-6'>
                                            <InputText
                                                name="bank_holder_name"
                                                type="text"
                                                placeholder='Account Holder Name*'
                                                label="Account Holder Name"
                                                value={formik.values?.bank_holder_name}
                                                className="common_input_filed"
                                            />
                                        </div>
                                        <div className='col-md-6'>
                                            <InputText
                                                name="account_number"
                                                type="text"
                                                placeholder='Account Number*'
                                                label="Account Number"
                                                value={formik.values?.account_number}
                                                className="common_input_filed"
                                            />
                                        </div>
                                        <div className='col-md-6'>
                                            <InputText
                                                name="branch_code"
                                                type="text"
                                                placeholder='Branch Code*'
                                                label="Branch Code"
                                                value={formik.values?.branch_code}
                                                className="common_input_filed"
                                            />
                                        </div>
                                        <div className='col-md-6'>
                                            <SelectBox
                                                name="account_type"
                                                label="Account Type"
                                                value={formik.values.account_type}
                                                className="common_input_filed"
                                                options={
                                                    <>
                                                        <option value={1}>Account Type*</option>
                                                        <option value={2}>Account Type2</option>
                                                        <option value={3}>Account Type3</option>
                                                        <option value={4}>Account Type4</option>
                                                        <option value={5}>Account Type5</option>
                                                    </>
                                                }
                                            />
                                        </div>
                                        <div className='col-md-6'>
                                            <InputText
                                                name="cpf_number"
                                                type="text"
                                                placeholder='CPF Number*'
                                                label="CPF Number"
                                                value={formik.values?.cpf_number}
                                                className="common_input_filed"
                                            />
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className='new_Campaigns_input tow_btn_row p-0'>
                                <Button
                                    className="btn_blue"
                                    text='Submit'
                                    type="submit"
                                    loader={loader}
                                    disabled={!(formik.isValid && formik.dirty) || loader}
                                />
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </>
    )
}

export default CompleteProfile;