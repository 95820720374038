import React, { useState } from 'react';
import { MdEmail } from 'react-icons/md';
import './Auth.scss';
import { Form, Formik } from 'formik';
import * as Yup from "yup";
import { ForgotPassAction } from '../../redux/Slices/AuthSlice';
import { InputText } from '../../components/Common/InputText';
import { Button } from '../../components/Common/Button';
import { useNavigate } from 'react-router-dom';

function ForgotPassword() {
    const [loader, setLoader] = useState(false)
    const navigate = useNavigate()
    return (

        <>
            <section className='login_register_section'>
                <div className='left_side_content_filed'>
                    <div className='login_input_filed'>
                        <div className='logo_images'>
                            <img src="/images/glide_scale_logo.svg" alt="" />
                        </div>
                        <div className='text_login_description'>
                            <h1>Welcome to Glidescale</h1>
                            <p>Kindly choose what you prefer</p>
                        </div>
                        <Formik
                            initialValues={{
                                email: '',
                            }}
                            validationSchema={Yup.object().shape({
                                email: Yup.string().required("Email/Phone Number is Required!"),
                            })}
                            onSubmit={(value, actions) => {
                                if (value?.email) {
                                    const isEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value?.email);
                                    const isPhone = /^\d{10}$/.test(value?.email);
                                    if (!isEmail && !isPhone) {
                                        actions.setFieldError("email", "Invalid email address or phone number")
                                        return <></>
                                    }
                                }
                                const payload = {
                                    email: value?.email,
                                }
                                setLoader(true)
                                ForgotPassAction(payload, (response) => {
                                    if (response?.status === true) {
                                        setLoader(false)
                                        navigate("/login")
                                    } else {
                                        setLoader(false)
                                    }
                                })();
                            }}
                        >
                            {(formik) => {
                                return (
                                    <Form>

                                        <div className='input_filed_login_register'>

                                            <InputText
                                                name="email"
                                                type="text"
                                                placeholder='Email / Phone Number'
                                                value={formik.values?.email}
                                                FieldIcon={<MdEmail />}
                                                className="common_input_filed"
                                            />
                                        </div>

                                        <div className='sign_in_btn'>
                                            <Button
                                                className="sign-in"
                                                text='Submit'
                                                type="submit"
                                                loader={loader}
                                                disabled={!(formik.isValid && formik.dirty) || loader}
                                            />
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>

                    </div>
                </div>

                <div className='right_side_content'>
                    <div className='right_login_description'>
                        <div className='img_right_side'>
                            <img src="/images/login_right_side.svg" alt="" />
                        </div>
                        <div className='content_right_side'>
                            <h2>Your Security First, Efficiency Paramount.</h2>
                            <p> Glidescale employs an array of defensive measures to ensure you and your marketing is as safe and reliable as possible. Freely create your own or join a PPC Affiliate Program without the hassle or worry.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ForgotPassword