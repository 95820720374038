import { React, useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import DeleteCampaigns from '../Modal/DeleteCampaigns';
import { AiOutlineSearch } from 'react-icons/ai';
import { IoMdNotificationsOutline } from 'react-icons/io';
import ChangePassword from '../Modal/ChangePassword';
import './Header.scss';
import NotificationsModal from '../Modal/NotificationsModal';
import { CommonModal } from '../Modal/CommonModal';
import { useDispatch, useSelector } from 'react-redux';
import { LogoutUser, ViewProfileAction } from '../../redux/Slices/AuthSlice';
import { IoWalletOutline } from 'react-icons/io5';
import { globalCampaignSearchAction, notificationCountAction, notificationListAction } from '../../redux/Slices/CommonSlice';
import { HasConsecutiveSpaces, debounceSearch } from '../../utils/Function';

function SidebarHeader() {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [switchState, setSwitchState] = useState(false)
    const [DeleteCampaignsShow, setDeleteCampaignsShow] = useState(false)
    const [NotificationsModalShow, setNotificationsModalShow] = useState(false)
    const [ChangePasswordShow, setChangePasswordShow] = useState(false)
    const [notificationListing, setNotificationListing] = useState({})
    const [notificationBellCount, setNotificationBellCount] = useState(false);
    const [searchString, setSearchString] = useState("")
    const [globalSearchString, setGlobalSearchString] = useState("")
    const [globalSearchData, setGlobalSearchData] = useState({})

    const { AuthLoading, login } = useSelector((state) => state.auth)

    useEffect(() => {
        dispatch(ViewProfileAction())
        notificationCountAction((response) => {
            if (response?.status === true) {
                setNotificationBellCount(response.data.unread_count)
            }
        })();
    }, [ViewProfileAction, notificationCountAction])

    useEffect(() => {
        if (globalSearchString.trim().length > 0) {
            globalCampaignSearchAction(globalSearchString, (response) => {
                if (response?.status === true) {
                    setGlobalSearchData(response.data)
                }
            })();
        } else {
            setGlobalSearchData([])
        }
    }, [globalCampaignSearchAction, globalSearchString])

    useEffect(() => {
        if (switchState) {
            document.body.classList.add('side_nav_open');
        } else {
            document.body.classList.remove('side_nav_open')
        }

    }, [switchState]);

    const LogoutUserAction = () => dispatch(LogoutUser())

    const handleToggleButton = () => {
        setSwitchState(!switchState)
    }

    const handleSearchFilter = debounceSearch((e) => {
        if (HasConsecutiveSpaces(e.target.value)) return <></>;
        setGlobalSearchString(e.target.value)
    }, 300);

    return (
        <>
            <header className='main_header_top'>
                <div className='top-header-sidebar'>
                    <div className='top_header_logo'>
                        <img className='header_logo_img' src="/images/logo_mini.svg" alt="" />
                    </div>
                    <button type='btn' className='toggle_btn_button' onClick={handleToggleButton}>
                        <span className='line_btn'></span>
                        <span className='line_btn'></span>
                        <span className='line_btn'></span>
                    </button>
                    <div className='search_input_profile'>
                        <div className="headerSearchBx">
                            <input
                                placeholder="Find campaigns"
                                type="search"
                                className="common_input_filed"
                                onChange={(e) => {
                                    handleSearchFilter(e)
                                    setSearchString(e.target.value)
                                }}
                                value={searchString}
                            />
                            <div className="search_header_icon"><AiOutlineSearch /></div>
                            {searchString?.length > 0 ?
                                <div className='globalSearch-suggestion-wrap'>
                                    <ul>
                                        {globalSearchData?.length > 0 ?
                                            globalSearchData.map((value, index) => (
                                                <li
                                                    key={index}
                                                    onClick={() => {
                                                        setGlobalSearchData([])
                                                        setSearchString("")
                                                        setGlobalSearchString("")
                                                        value?.own_campaign == 1 ?
                                                            navigate(`/my-campaigns/${value?.campaign_name?.replace(/[\/\s]+/g, '_').toLowerCase()}/${value?._id}`, { state: { mode: "my-campaigns" } })
                                                            :
                                                            navigate(`/browse-campaigns/${value?.campaign_name?.replace(/[\/\s]+/g, '_').toLowerCase()}/${value?._id}`, { state: { mode: "browse-campaigns" } })
                                                    }}
                                                >
                                                    <div className='search_title_icon'>
                                                        <span> {value.campaign_name} </span>
                                                        <div className='share_next_page'> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                                            <g id="Group_50385" data-name="Group 50385" transform="translate(-1512.25 -556.75)">
                                                                <path id="Path_135412" data-name="Path 135412" d="M8.636,3.5a.5.5,0,0,0-.5-.5H1.5A1.5,1.5,0,0,0,0,4.5v10A1.5,1.5,0,0,0,1.5,16h10A1.5,1.5,0,0,0,13,14.5V7.864a.5.5,0,0,0-1,0V14.5a.5.5,0,0,1-.5.5H1.5a.5.5,0,0,1-.5-.5V4.5A.5.5,0,0,1,1.5,4H8.136A.5.5,0,0,0,8.636,3.5Z" transform="translate(1512.5 556.5)" stroke="#000" strokeWidth="0.5" fillRule="evenodd" />
                                                                <path id="Path_135413" data-name="Path 135413" d="M16,.5a.5.5,0,0,0-.5-.5h-5a.5.5,0,0,0,0,1h3.793L6.146,9.146a.5.5,0,0,0,.708.708L15,1.707V5.5a.5.5,0,0,0,1,0Z" transform="translate(1512 557)" stroke="#000" strokeWidth="0.5" fillRule="evenodd" />
                                                            </g>
                                                        </svg>
                                                        </div>
                                                    </div>
                                                    <p>  {value.description} </p>
                                                </li>
                                            ))
                                            :
                                            <li> No data found </li>
                                        }
                                    </ul>
                                </div>
                                :
                                null}
                        </div>
                        <div className='notification_profile_button'>
                            <Link className='notifications_btn' to="/wallet"><IoWalletOutline />  </Link>
                            <button
                                className='notifications_btn'
                                type='button'
                                onClick={() => {
                                    notificationListAction((response) => {
                                        if (response?.status === true) {
                                            setNotificationListing(response.data)
                                        }
                                    })();
                                    setNotificationBellCount(0)
                                    setNotificationsModalShow(true)
                                }}
                            >
                                <IoMdNotificationsOutline />
                                {notificationBellCount ? <div className='notification_count'>  {notificationBellCount > 99 ? `99+` : notificationBellCount} </div> : ""}
                            </button>

                            <div className='profile drop_card_common'>
                                <Dropdown>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        <img src={login?.profile ? login?.profile : "/images/dummy-image.jpg"} alt="" />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item as={Link} className="nav-link" to="/profile">Profile</Dropdown.Item>
                                        {/* <Dropdown.Item as={Link} className="nav-link" onClick={() => setNotificationsModalShow(true)}>Notifications</Dropdown.Item> */}
                                        <Dropdown.Item as={Link} className="nav-link" onClick={() => setChangePasswordShow(true)}>Change Password</Dropdown.Item>
                                        <Dropdown.Item as={Link} className="nav-link" onClick={() => setDeleteCampaignsShow(true)}>Log Out</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <CommonModal
                show={DeleteCampaignsShow}
                onHide={() => setDeleteCampaignsShow(false)}
                modalClass="delete_Campaigns_modal"
                body={
                    <DeleteCampaigns
                        heading="Do you want to exit?"
                        logOutIcon={true}
                        description_text="Are you sure want to log out? All your unsaved data will be lost."
                        btn1="Not Now"
                        btn2="Yes, Exit"
                        loader={AuthLoading}
                        onClickButton1={() => setDeleteCampaignsShow(false)}
                        onClickButton2={LogoutUserAction}
                    />
                }
            />

            <NotificationsModal
                show={NotificationsModalShow}
                data={notificationListing}
                onHide={() => setNotificationsModalShow(false)}
            />

            <CommonModal
                show={ChangePasswordShow}
                onHide={() => setChangePasswordShow(false)}
                modalClass="delete_Campaigns_modal change_password_modal"
                body={
                    <ChangePassword
                        hideModal={() => setChangePasswordShow(false)}
                    />
                }
            />
        </>
    )
}

export default SidebarHeader