import { React, useEffect, useState } from 'react';
import '../../../pages/Style.scss';
import { Link, useNavigate } from 'react-router-dom';
import { AiOutlinePlus } from 'react-icons/ai';
import { FaRegEdit, FaEye } from 'react-icons/fa';
import { RiDeleteBinLine } from 'react-icons/ri';
import { CommonModal } from '../../../components/Modal/CommonModal';
import DeleteCampaigns from '../../../components/Modal/DeleteCampaigns';
import { QADeleteAction, QAListAction } from '../../../redux/AdminSlices/Common';
import { HasConsecutiveSpaces, debounceSearch } from '../../../utils/Function';
import NoDataFound from '../../../components/NoDataFound/NoDataFound';
import { Pagination } from '../../../components/Hooks/Pagination';


function QAList() {
    const navigate = useNavigate()
    const [loader, setLoader] = useState(false);
    const [DeleteQAShow, setDeleteQAShow] = useState(false)
    const [DeleteQAID, setDeleteQAID] = useState();
    const [listDataArr, setListDataArr] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [perPageCount, setPerPageCount] = useState(10);
    const [statusUpdate, setStatusUpdate] = useState(1)
    const [searchString, setSearchString] = useState("")

    useEffect(() => {
        let payload = {
            searchText: searchString,
            perPage: perPageCount,
            page: currentPage,
        }
        QAListAction(payload, (response) => {
            if (response?.status === true) {
                setListDataArr(response)
            }
        })();
    }, [QAListAction, perPageCount, currentPage, statusUpdate, searchString])

    const handleSearchFilter = debounceSearch((e) => {
        if (HasConsecutiveSpaces(e.target.value)) return <></>;
        setSearchString(e.target.value)
    }, 300);

    const DeleteCampaings = () => {
        setLoader(true)
        QADeleteAction({ QA_id: DeleteQAID }, (response) => {
            if (response?.status === true) {
                setDeleteQAID("")
                setDeleteQAShow(false)
                setStatusUpdate(statusUpdate + 1)
            }
            setLoader(false)
        })();
    }

    return (
        <>
            <div className='top_heading_button'>
                <h1 className='main_heading_top'> Q&A Management </h1>
                <Link className='btn_blue' to="/qa-management/add"><AiOutlinePlus /> Add New </Link>
            </div>

            <div className='admin_list_table'>
                <div className='filter_number_search_input'>
                    <div className='filter_select_number filter_search_common '>
                        <label className='common_label'>Show:</label>
                        <select className='common_input_filed' onChange={(e) => setPerPageCount(e.target.value)}>
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                        </select>
                    </div>

                    <div className='search_table_content filter_search_common'>
                        <label className='common_label'>Search:</label>
                        <div className="headerSearchBx">
                            <input
                                placeholder="Search"
                                aria-label="Search"
                                type="search"
                                className="common_input_filed"
                                onChange={handleSearchFilter}
                            />
                        </div>
                    </div>
                </div>
                <div className='request_campaigns_list'>
                    <div className='content_campaigns_user table-responsive'>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th> Question </th>
                                    <th> Answer </th>
                                    <th className='text-end'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    listDataArr?.data?.QA_list?.length > 0 ?
                                        listDataArr?.data?.QA_list?.map((value, index) => (
                                            <tr key={index}>
                                                <td> {(currentPage - 1) * Number(listDataArr?.data?.pagination?.perPage) + (index + 1)}. </td>
                                                <td> {value?.question ? value?.question : "-"} </td>
                                                <td> {value?.answer ? value?.answer : "-"} </td>

                                                <td className='text-end fixed-side'>
                                                    <button type='button' onClick={() => navigate("/qa-management/view", { state: { ...value, mode: "view" } })} className='common_btn_admin edit_btn_admin'>
                                                        <FaEye />
                                                    </button>

                                                    <button type='button' onClick={() => navigate("/qa-management/edit", { state: { ...value, mode: "edit" } })} className='common_btn_admin edit_btn_admin me-3'>
                                                        <FaRegEdit />
                                                    </button>

                                                    <button type='button' className='common_btn_admin edit_btn_admin delete_btn_admin'
                                                        onClick={() => {
                                                            setDeleteQAShow(true)
                                                            setDeleteQAID(value?._id)
                                                        }}>
                                                        <RiDeleteBinLine />
                                                    </button>
                                                </td>
                                            </tr>
                                        ))
                                        :
                                        <tr>
                                            <td colSpan={7}>
                                                <NoDataFound />
                                            </td>
                                        </tr>
                                }
                            </tbody>
                        </table>
                        <div className='pagination_entries_section'>
                            <div className='entries_text'>
                                Showing {(currentPage - 1) * Number(listDataArr?.data?.pagination?.perPage) + 1} of {listDataArr?.data?.pagination?.totalItems} entries
                            </div>
                            {listDataArr?.data?.QA_list?.length > 0 ?
                                <Pagination
                                    className="pagination-bar"
                                    currentPage={currentPage}
                                    totalCount={listDataArr?.data?.pagination?.totalItems}
                                    pageSize={listDataArr?.data?.pagination?.perPage}
                                    onPageChange={(page) => setCurrentPage(page)}
                                />
                                :
                                null
                            }
                        </div>
                    </div>
                </div>
            </div>

            <CommonModal
                show={DeleteQAShow}
                onHide={() => {
                    setDeleteQAShow(false)
                    setDeleteQAID("")
                }}
                modalClass="delete_Campaigns_modal"
                body={
                    <DeleteCampaigns
                        heading="Are you sure you want to Delete?"
                        btn1="Not Now"
                        btn2="Yes, Delete"
                        onClickButton1={() => {
                            setDeleteQAShow(false)
                            setDeleteQAID("")
                        }}
                        loader={loader}
                        onClickButton2={() => DeleteCampaings()}
                    />
                }
            />
        </>
    )
}

export default QAList;