import { useEffect, useState } from "react"

const ImagePreview = ({ file, imageAlt }) => {
    const [preview, setPreview] = useState(null);
    const SUPPORTED_FILE_FORMAT = ["jpeg", "png", "jpg"]
    useEffect(() => {
        if (typeof file === "object") {
            const render = new FileReader();
            render.readAsDataURL(file);
            render.onload = () => {
                setPreview(render.result)
            }
        } else {
            setPreview(file ? file : "images/dummy-image.jpg")
        }
    }, [file])
    return (
        SUPPORTED_FILE_FORMAT.includes(file?.split('.').pop('')) ?
            <img src={preview} className="img-fluid" referrerPolicy="no-referrer" alt={imageAlt} />
            :
            file?.split('.').pop('') === "pdf" ?
                <img className="img-fluid" referrerPolicy="no-referrer" src="/images/file/PDF_file_icon.svg" alt="PDF" />
                :
                file?.split('.').pop('') === "doc" || file?.split('.').pop('') === "docx" ?
                    <img className="img-fluid" referrerPolicy="no-referrer" src="/images/file/word_icon.svg" alt="DOC" />
                    :
                    file?.split('.').pop('') === "ppt" ?
                        <img className="img-fluid" referrerPolicy="no-referrer" src="/images/file/microsoft_icon.svg" alt="PPT" />
                        :
                        <img className="img-fluid" referrerPolicy="no-referrer" src="images/dummy-image.jpg" alt="Image" />
    )
}

export default ImagePreview