
import '../../../pages/Style.scss';
import { React, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Form, Formik, useFormikContext } from 'formik';
import * as Yup from "yup";
import { InputText, TextArea } from '../../../components/Common/InputText';
import { Button } from '../../../components/Common/Button';
import { useDispatch } from 'react-redux';
import { CreateQAAction, CreateWatchTutorialAction, QAUpdateAction, WatchTutorialUpdateAction } from '../../../redux/AdminSlices/Common';


function AddEditQAManagement() {
    const navigate = useNavigate()
    const { state } = useLocation()

    const [loader, setLoader] = useState(false)
    const [formDataSaved, setFormDataSaved] = useState(false)


    const FormikFromFunc = () => {
        const formikFrom = useFormikContext();
        useEffect(() => {
            if (state && formDataSaved === false) {
                formikFrom.setValues({
                    question: state?.question,
                    answer: state?.answer,
                })
                setFormDataSaved(true)
            }
        }, [state])
    }

    const getHeadingText = () => {
        if (state?.mode === "edit") {
            return "Edit Q&A";
        } else if (state?.mode === "view") {
            return "View Q&A";
        } else {
            return "Add New Q&A";
        }
    };

    return (
        <>
            <div className='top_heading_button'>
                <h1 className='main_heading_top'> {getHeadingText()} </h1>
            </div>

            <div className='content_campaigns_user add_video common_tab_section'>

                <Formik
                    initialValues={{
                        question: "",
                        answer: ""
                    }}
                    validationSchema={Yup.object().shape({
                        question: Yup.string().required("Question is Required!"),
                        answer: Yup.string().required("Answer is Required!"),
                    })}
                    onSubmit={(value) => {
                        setLoader(true)
                        if (state?.mode == "edit") {
                            QAUpdateAction({ ...value, QA_id: state?._id }, (response) => {
                                if (response?.status === true) {
                                    navigate("/qa-management")
                                }
                                setLoader(false)
                            })();
                        } else {
                            CreateQAAction(value, (response) => {
                                if (response?.status === true) {
                                    navigate("/qa-management")
                                }
                                setLoader(false)
                            })();
                        }
                    }}
                >
                    {(formik) => {
                        return (
                            <Form>
                                <FormikFromFunc />
                                <div className='row g-5'>
                                    <div className='col-12'>
                                        <InputText
                                            name="question"
                                            type="text"
                                            placeholder='Question'
                                            label="Question"
                                            maxlength={200}
                                            value={formik.values?.question}
                                            className="common_input_filed"
                                            disabled={state?.mode == "view"}
                                        />
                                        <small> 200 characters limit </small>
                                    </div>

                                    <div className='col-12'>
                                        <TextArea
                                            label="Answer"
                                            name="answer"
                                            placeholder='Answer'
                                            className='text_area_common'
                                            rows="4"
                                            maxlength={5000}
                                            value={formik.values.answer}
                                            disabled={state?.mode == "view"}
                                        />
                                        <small> 5000 characters limit </small>
                                    </div>

                                    {state?.mode !== "view" ?
                                        <div className='col-12'>
                                            <Button
                                                className="btn_blue"
                                                text='Submit'
                                                type="submit"
                                                loader={loader}
                                                disabled={!(formik.isValid && formik.dirty) || loader}
                                            />
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </>
    )
}

export default AddEditQAManagement;