import { React } from 'react';
import '../../pages/Style.scss';


function RevenueReport() {

  return (
    <>
      <div className='top_heading_button'>
        <h1 className='main_heading_top'>Revenue Report</h1>
      </div>

    </>
  )
}

export default RevenueReport;