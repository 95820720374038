import React from 'react'
import { useSelector } from 'react-redux';
import { Navigate, Outlet, } from "react-router-dom";

export const PrivateRoutes = () => {
    const { isAuthenticated, login } = useSelector((state) => state.auth)

    return isAuthenticated && login?.role_id === 2 && login?.is_verify === 1 ? <Outlet /> : <Navigate to="/login" />
}

export const AdminPrivateRoutes = () => {
    const { isAuthenticated, login } = useSelector((state) => state.auth)

    return isAuthenticated && login?.role_id === 1 ? <Outlet /> : <Navigate to="/login" />
}