import { signInWithPopup } from "firebase/auth";
import { auth } from "./firebase";


const socialMediaAuth = async (provider) => {
    return await signInWithPopup(auth, provider).then((response) => {
        return response
    }).catch((error) => {
        return error
    })
}

export default socialMediaAuth