import React, { useEffect, useState } from 'react';
import { MdEmail } from 'react-icons/md';
import { IoMdLock } from 'react-icons/io';
import './Auth.scss';
import { Link, useNavigate } from 'react-router-dom';
import { InputText } from '../../components/Common/InputText';
import { Form, Formik, useFormikContext } from 'formik';
import * as Yup from "yup";
import { Button } from '../../components/Common/Button';
import { useDispatch, useSelector } from 'react-redux';
import { LoginAction } from '../../redux/Slices/AuthSlice';
import SocialLoginButton from './SocialLoginButton';

function Login() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [rememberMe, setRememberMe] = useState(false);
    const [savedLoginDetails, setSavedLoginDetails] = useState(false);

    const { login, AuthLoading, isAuthenticated } = useSelector((state) => state?.auth)
    const storedLoginDetails = localStorage.getItem('glideLoginDetails');

    const FormikFromFunc = () => {
        const formikFrom = useFormikContext();
        if (storedLoginDetails && !savedLoginDetails) {
            const parsedLoginDetails = JSON.parse(storedLoginDetails);
            formikFrom.setValues({
                email: parsedLoginDetails?.email,
                password: parsedLoginDetails?.password
            })
            setSavedLoginDetails(true);
            setRememberMe(true);
        }
    }

    useEffect(() => {
        if (isAuthenticated) {
            if (login?.is_verify === 0 && login?.role_id === 2) {
                navigate("/otp", { replace: true, state: { email: login?.email, phone: login?.phone, login_verify: 1 } })
            } else if (login?.role_id === 2 && login?.is_verify === 1) {
                navigate("/campaigns")
            } else if (login?.role_id === 1) {
                navigate("/admin")
            }
        }
    }, [isAuthenticated, login])

    return (

        <>
            <section className='login_register_section'>
                <div className='left_side_content_filed'>
                    <div className='login_input_filed'>
                        <div className='logo_images'>
                            <img src="/images/glide_scale_logo.svg" alt="" />
                        </div>
                        <div className='text_login_description'>
                            <h1>Welcome to Glidescale</h1>
                            <p>Kindly choose what you prefer</p>
                        </div>

                        <Formik
                            initialValues={{
                                email: '',
                                password: '',
                            }}
                            validationSchema={Yup.object().shape({
                                email: Yup.string().required("Email/Phone Number is Required!"),
                                password: Yup.string().required("Password is Required!"),
                            })}
                            onSubmit={(value, actions) => {
                                if (value?.email) {
                                    const isEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value?.email);
                                    const isPhone = /^\d{10}$/.test(value?.email);
                                    if (!isEmail && !isPhone) {
                                        actions.setFieldError("email", "Invalid email address or phone number")
                                        return <></>
                                    }
                                }
                                const payload = {
                                    email: value?.email,
                                    password: value?.password
                                }
                                dispatch(LoginAction(payload))
                                if (rememberMe) {
                                    const loginDetails = {
                                        email: value?.email,
                                        password: value?.password
                                    };

                                    localStorage.setItem('glideLoginDetails', JSON.stringify(loginDetails));
                                } else {
                                    localStorage.removeItem('glideLoginDetails');
                                }
                            }}
                        >
                            {(formik) => {
                                return (
                                    <Form>
                                        <FormikFromFunc />
                                        <div className='input_filed_login_register'>
                                            <InputText
                                                name="email"
                                                type="text"
                                                placeholder='Email / Phone Number'
                                                value={formik.values?.email}
                                                FieldIcon={<MdEmail />}
                                                className="common_input_filed"
                                            />

                                            <InputText
                                                name="password"
                                                type="password"
                                                placeholder='Password'
                                                value={formik.values?.password}
                                                FieldIcon={<IoMdLock />}
                                                className="common_input_filed"
                                            />
                                        </div>

                                        <div className='forgot_password'>
                                            <div className='remember_me_check check_box_common'>
                                                <input type="checkbox" id="remember" checked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)} />
                                                <label htmlFor="remember">Remember Me</label>
                                            </div>
                                            <Link className='btn_forgot' to="/forgot-password">Forgot your password?</Link>
                                        </div>

                                        <div className='sign_in_btn'>
                                            <Button
                                                className="sign-in"
                                                text='Log In'
                                                type="submit"
                                                loader={AuthLoading}
                                                disabled={!(formik.isValid && formik.dirty) || AuthLoading}
                                            />
                                            <SocialLoginButton btnText="Login" />
                                        </div>
                                        <div className='new_user_button'>
                                            New User ?  <Link to="/register" className='btn_new_member'>Register</Link>
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                </div>

                <div className='right_side_content'>
                    <div className='right_login_description'>
                        <div className='img_right_side'>
                            <img src="/images/login_right_side.svg" alt="" />
                        </div>
                        <div className='content_right_side'>
                            <h2>Your Security First, Efficiency Paramount.</h2>
                            <p> Glidescale employs an array of defensive measures to ensure you and your marketing is as safe and reliable as possible. Freely create your own or join a PPC Affiliate Program without the hassle or worry.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Login