import { React, useEffect, useState } from 'react';
import '../../pages/Style.scss';
import { useSelector } from 'react-redux';
import { CompleteProfileAction ,ViewProfileAction, CountryListAction} from '../../redux/Slices/AuthSlice';
import { ErrorMessage, useFormikContext } from 'formik';
import { Field, Form, Formik } from 'formik';
import * as Yup from "yup";
import { InputText, SelectBox } from '../../components/Common/InputText';
import { Button } from '../../components/Common/Button';
import { fileUploadAction } from '../../redux/AdminSlices/Common';
import { ToastOverError } from '../../components/Common/ToastOver';
import ImagePreview from '../../components/ImagePreview';
import TextErrorMsg from '../../components/Common/TextErrorMsg';
import { Regex } from '../../utils/Regex';
import { useDispatch } from 'react-redux';
function Profile() {
    const [formDataSaved, setFormDataSaved] = useState(false)
    const [loader, setLoader] = useState(false)
    const dispatch = useDispatch()

    const { profile } = useSelector((state) => state?.auth)

    const [CountryList, setCountryList] = useState({});
    const [selectedContry, setselectedContry] = useState(null);

    const SUPPORTED_FILE_FORMAT = [
        "image/jpeg",
        "image/jpg", "image/png",
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.ms-powerpoint",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ]

    useEffect(() => {
        CountryListAction((response) => {
            if (response) {
                setCountryList(response)
            }
            // setLoader(false)
        })();
    }, []);


    // useEffect(()=>{
    //     setselectedContry(profile?.country_code)
    // }, [])

    console.log('prfikeleee',profile)

    const FormikFromFunc = () => {
        const formikFrom = useFormikContext();
        useEffect(() => {
            if (profile && formDataSaved === false) {
                console.log("profile>>",profile)
                formikFrom.setValues({
                    user_name: profile?.user_name,
                    email: profile?.email,
                    country_code: profile?.country_code,
                    phone: profile?.phone,
                    full_name: profile?.full_name,
                    experience: profile?.experience,
                    certificate: profile?.certificate,
                    company_name: profile?.company_name,
                    // bank_name: profile?.bank_name,
                    // bank_holder_name: profile?.bank_holder_name,
                    // account_number: profile?.account_number,
                    // branch_code: profile?.branch_code,
                    // account_type: profile?.account_type,
                    // cpf_number: profile?.cpf_number,
                    profile_image: profile?.profile
                })
                setFormDataSaved(true)
            }
        }, [profile])
    }


    const handleChange = (e) => {
        const { name, value } = e.target
        setselectedContry(value);

    }
    return (
        <>
            <Formik
                initialValues={{
                    user_name: '',
                    email: '',
                    full_name: '',
                    experience: '',
                    certificate: '',
                    company_name: '',
                    // bank_name: '',
                    // bank_holder_name: '',
                    // account_number: '',
                    // branch_code: '',
                    // account_type: '',
                    // cpf_number: '',
                    profile_image: '',
                }}
                validationSchema={Yup.object().shape({
                    // bank_name: Yup.string().required("Bank Name is Required!"),
                    // bank_holder_name: Yup.string().required("Bank Holder_name is Required!"),
                    // account_number: Yup.string().required("Account Number is Required!"),
                    // branch_code: Yup.string().required("Branch Code is Required!"),
                    // account_type: Yup.string().required("Account Type is Required!"),
                    // cpf_number: Yup.string().required("CPF Number is Required!"),
                    profile_image: Yup.mixed().nullable().notRequired()
                        .test(
                            "FILE_FORMAT",
                            "Unsupported file format",
                            (value) => !value || (value && (typeof value === "object" ? ["image/jpeg", "image/jpg", "image/png"].includes(value?.type) : true))
                        ),
                        phone:Yup.string().min(9, "Phone number must be at least 9 number").max(9, "Phone number must be at least 9 number").matches(Regex.MOBILE_NUMBER_REGEX, 'Phone number is not valid').required('Phone Number is Required!'),
                })}
                onSubmit={(value) => {
                    const payload = {
                        user_name: value.user_name,
                        full_name: value.full_name,
                        experience: value.experience,
                        certificate: value.certificate,
                        company_name: value.company_name,
                        country_code: selectedContry,
                        phone: value.phone,
                        // bank_name: value.bank_name,
                        // bank_holder_name: value.bank_holder_name,
                        // account_number: value.account_number,
                        // branch_code: value.branch_code,
                        // account_type: value.account_type,
                        // cpf_number: value.cpf_number,
                        profile: value.profile_image,
                    }
                    setLoader(true)
                    CompleteProfileAction(payload, (response) => {
                        if (response?.status === true) {
                            dispatch(ViewProfileAction())
                            setLoader(false)
                        } else {
                            setLoader(false)
                        }
                    })();
                }}
            >
                {(formik) => {
                    return (
                        <Form onChange={handleChange}>
                            <FormikFromFunc />
                            <div className='top_heading_button'>
                                <h1 className='main_heading_top'>Profile</h1>
                            </div>

                            <div className='profile_page'>
                                <div className='profile-edit'>
                                    <Field name="profile_image">
                                        {({ form, field }) => {
                                            return (
                                                <>
                                                    <input name={field.name} type="file" hidden={true} id='profileEdit'
                                                        onChange={(e) => {
                                                            if (["image/jpeg", "image/jpg", "image/png"].includes(e.target.files[0]?.type)) {
                                                                setLoader(true)
                                                                let formData = new FormData();
                                                                formData.append("file", e.target.files[0]);
                                                                fileUploadAction(formData, (response) => {
                                                                    if (response?.status === true) {
                                                                        setLoader(false)
                                                                        form.setFieldValue(field.name, response?.data[0].file_url)
                                                                    } else {
                                                                        setLoader(false)
                                                                        ToastOverError(response?.data?.message)
                                                                    }
                                                                })();
                                                            } else {
                                                                form.setFieldValue(field.name, "")
                                                                e.target.value = null
                                                                ToastOverError("Unsupported file format")
                                                            }
                                                        }} />
                                                    <label htmlFor="profileEdit" className='edit-label'>Change</label>
                                                </>
                                            )
                                        }}
                                    </Field>

                                    <div className='img-container'>
                                        {formik.values.profile_image ?
                                            <ImagePreview file={formik.values.profile_image} imageAlt={formik.values.user_name} />
                                            :
                                            <ImagePreview imageAlt={formik.values.user_name} />
                                        }
                                    </div>
                                </div>
                                <div className='text-center'> <ErrorMessage name="profile_image" component={TextErrorMsg} /> </div>

                                <div className='basic_details_user'>
                                    <div className='row gx-5'>
                                        <div className='col-md-6 col-lg-4 mb-md-5 mb-4'>
                                            <InputText
                                                name="user_name"
                                                type="text"
                                                placeholder='User Name'
                                                label="User Name"
                                                value={formik.values?.user_name}
                                                className="common_input_filed"
                                            />
                                        </div>
                                        <div className='col-md-6 col-lg-4 mb-md-5 mb-4'>
                                            <InputText
                                                name="full_name"
                                                type="text"
                                                placeholder='Full Name'
                                                label="Full Name"
                                                value={formik.values?.full_name}
                                                className="common_input_filed"
                                            />
                                        </div>
                                        <div class="col-md-6 col-lg-4 mb-md-5 mb-4">
                                            <div class="form_filed_common null">
                                                <div class="user_input undefined">
                                                    <label class="common_label"> Country Code</label>
                                                    <select
                                            name="country_code"
                                            className="common_input_filed register_input"
                                            value={formik.values.country_code}
                                            onChange={formik.handleChange} // Handle changes with Formik
                                            onBlur={formik.handleBlur} // Blur handler for touch events
                                            >
                                            <option value="">Country Code</option>
                                            {CountryList?.data?.map((country, index) => (
                                                <option key={index} value={country.dial_code}>
                                                {country.name} ({country.dial_code})
                                                </option>
                                            ))}
                                            </select>
                                                    <div class="user_icon"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-6 col-lg-4 mb-md-5 mb-4'>
                                            <InputText
                                                name="phone"
                                                type="text"
                                                placeholder='Phone Number'
                                                label="Phone Number"
                                                //disabled={true}
                                                value={formik.values?.phone}
                                                className="common_input_filed"
                                            />
                                        </div>
                                        <div className='col-md-6 col-lg-4 mb-md-5 mb-4'>
                                            <InputText
                                                name="email"
                                                type="text"
                                                placeholder='Email'
                                                label="Email"
                                                disabled={true}
                                                value={formik.values?.email}
                                                className="common_input_filed"
                                            />
                                        </div>
                                        <div className='col-md-6 col-lg-4 mb-md-5 mb-4'>
                                            <InputText
                                                name="company_name"
                                                type="text"
                                                placeholder='Company Name'
                                                label="Company Name"
                                                value={formik.values?.company_name}
                                                className="common_input_filed"
                                            />
                                        </div>
                                        <div className='col-md-6 col-lg-4 mb-md-5 mb-4'>
                                            <div className='document_img_upload'>
                                                <Field name="certificate">
                                                    {({ form, field }) => {
                                                        return (
                                                            <div className={`form_filed_common`}>
                                                                <div className={`user_input common_input_filed_file_common`}>
                                                                    <label className='common_label'> Resume </label>
                                                                    <input name={field.name} type="file" id='profileEdit'
                                                                        onChange={(e) => {
                                                                            if (SUPPORTED_FILE_FORMAT.includes(e.target.files[0]?.type)) {
                                                                                setLoader(true)
                                                                                let formData = new FormData();
                                                                                formData.append("file", e.target.files[0]);
                                                                                fileUploadAction(formData, (response) => {
                                                                                    if (response?.status === true) {
                                                                                        setLoader(false)
                                                                                        form.setFieldValue(field.name, response?.data[0].file_url)
                                                                                    } else {
                                                                                        setLoader(false)
                                                                                        ToastOverError(response?.data?.message)
                                                                                    }
                                                                                })();
                                                                            } else {
                                                                                form.setFieldValue(field.name, "")
                                                                                e.target.value = null
                                                                                ToastOverError("Unsupported file format")
                                                                            }
                                                                        }} />
                                                                </div>
                                                                <ErrorMessage name={field.name} component={TextErrorMsg} />
                                                            </div>
                                                        )
                                                    }}
                                                </Field>

                                                <div className='img-container'>
                                                    {formik.values.certificate ?
                                                        <ImagePreview file={formik.values.certificate} imageAlt={formik.values.user_name} />
                                                        :
                                                        null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-6 col-lg-4 mb-md-5 mb-4'>
                                            <InputText
                                                name="experience"
                                                type="text"
                                                placeholder='Total Experience'
                                                label="Total Experience"
                                                value={formik.values?.experience}
                                                className="common_input_filed"
                                            />
                                        </div>

                                        {/* <div className='col-12 mb-md-5 mb-4'>
                                            <h3 className='main_heading_top'>Buy Credits</h3>
                                        </div>

                                        <div className='col-md-6 col-lg-4 mb-md-5 mb-4'>
                                            <InputText
                                                name="bank_name"
                                                type="text"
                                                placeholder='Bank Name*'
                                                label="Bank Name"
                                                value={formik.values?.bank_name}
                                                className="common_input_filed"
                                            />
                                        </div>

                                        <div className='col-md-6 col-lg-4 mb-md-5 mb-4'>
                                            <InputText
                                                name="bank_holder_name"
                                                type="text"
                                                placeholder='Account Holder Name*'
                                                label="Account Holder Name"
                                                value={formik.values?.bank_holder_name}
                                                className="common_input_filed"
                                            />
                                        </div>

                                        <div className='col-md-6 col-lg-4 mb-md-5 mb-4'>
                                            <InputText
                                                name="account_number"
                                                type="text"
                                                placeholder='Account Number*'
                                                label="Account Number"
                                                value={formik.values?.account_number}
                                                className="common_input_filed"
                                            />
                                        </div>

                                        <div className='col-md-6 col-lg-4 mb-md-0 mb-4'>
                                            <InputText
                                                name="branch_code"
                                                type="text"
                                                placeholder='Branch Code*'
                                                label="Branch Code"
                                                value={formik.values?.branch_code}
                                                className="common_input_filed"
                                            />
                                        </div>

                                        <div className='col-md-6 col-lg-4 mb-md-0 mb-4'>
                                            <SelectBox
                                                name="account_type"
                                                label="Account Type"
                                                value={formik.values.account_type}
                                                className="common_input_filed"
                                                options={
                                                    <>
                                                        <option value={1}>Account Type*</option>
                                                        <option value={2}>Account Type2</option>
                                                        <option value={3}>Account Type3</option>
                                                        <option value={4}>Account Type4</option>
                                                        <option value={5}>Account Type5</option>
                                                    </>
                                                }
                                            />
                                        </div>

                                        <div className='col-md-6 col-lg-4 mb-md-0 mb-4'>
                                            <InputText
                                                name="cpf_number"
                                                type="text"
                                                placeholder='CPF Number*'
                                                label="CPF Number"
                                                value={formik.values?.cpf_number}
                                                className="common_input_filed"
                                            />
                                        </div> */}

                                        <div className='col-12'>
                                            <div className='profile_save_btn'>
                                                <Button
                                                    className="btn_blue"
                                                    text='Save'
                                                    type="submit"
                                                    loader={loader}
                                                    disabled={!(formik.isValid && formik.dirty) || loader}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </>
    )
}

export default Profile;