import React, { useState } from 'react';
import "./Modal.scss";
import { Button } from '../Common/Button';
import { Form, Formik } from 'formik';
import * as Yup from "yup";
import { ManualInviteEmailSentAction } from '../../redux/Slices/AuthSlice';
import { InputText } from '../Common/InputText';
import { MuiChipsInput } from 'mui-chips-input';
import { toast, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Regex } from '../../utils/Regex';
toast.configure();

function ManualInviteEmailModal({ onClickButton1, campaign_data }) {
    const [loader, setLoader] = useState(false);
    const [emails, setEmails] = useState([]);

    const handleEmailChange = (newEmails) => {
        let isValid = 1
        newEmails.map((val) => {
            if (!Regex?.email.test(val)) {
                isValid = 0
                toast.error("Invalid Email", { theme: "colored", toastId: 'error', transition: Flip })
            }
           
        })
        if(new Set(newEmails).size !== newEmails.length){
            toast.error("Email already exist", { theme: "colored",toastId: 'error', transition: Flip })
            isValid = 0
            
        }
        if (isValid == 1)
            setEmails(newEmails);
    };

    return (
        <>
            <Formik
                initialValues={{
                    email: emails, // Initialize email field
                }}
                onSubmit={(values, { setSubmitting }) => {
                    if (emails.length == 0) {
                        toast.error("Please enter at least one email address", { theme: "colored", toastId: 'error', transition: Flip })
                        setSubmitting(false)
                    } else {
                        const payload = {
                            campaign_id: campaign_data?._id,
                            email: emails,
                            shareUrl: campaign_data?.genrate_link
                        }
                        setLoader(true);
                        ManualInviteEmailSentAction(payload, (response) => {
                            if (response?.status === true) {
                                setLoader(false);
                                onClickButton1(false);
                            } else {
                                setLoader(false);
                            }
                        })();
                        setSubmitting(false);
                    }
                }}

            >
                {(formik) => (
                    <Form onSubmit={formik.handleSubmit}>
                        <div className='delete_content_camp'>
                            <h1>Email</h1>
                        </div>
                        <div className='change_password_filed'>
                            <div className='row gy-5 gy-md-0'>
                                <div className='col-12 multi_select_email type_input'>
                                    <MuiChipsInput
                                        name={"email"}
                                        value={emails}
                                        onChange={handleEmailChange}
                                        placeholder='Add email addresses'
                                        inputProps={{ placeholder: 'Add email addresses' }}
                                        chipRender={(chip, index, onRemove) => (
                                            <div key={index} className="mui-chip">
                                                <span>{chip}</span>
                                                <span
                                                    className="mui-chip-remove"
                                                    onClick={() => {
                                                        const updatedEmails = [...emails];
                                                        updatedEmails.splice(index, 1);
                                                        setEmails(updatedEmails);
                                                        onRemove();
                                                    }}
                                                >
                                                </span>
                                            </div>
                                        )}
                                    />
                                    {formik.touched.email && formik.errors.email ? (
                                        <div className="error">{formik.errors.email}</div>
                                    ) : null}
                                </div>
                                <small> <strong> </strong>Note: Please press Enter ↵ for add the emails  </small>
                            </div>
                        </div>
                        <div className='new_Campaigns_input tow_btn_row'>
                            <Button
                                className="btn_blue"
                                text='Cancel'
                                type='button'
                                onClick={onClickButton1}
                            />
                            <Button
                                className="btn btn_blue"
                                text='Submit'
                                type="submit"
                                disabled={!formik.isValid || loader}
                            />

                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
}

export default ManualInviteEmailModal;
