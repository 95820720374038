import React from 'react';
import './Footer.scss';
import { Link, NavLink } from 'react-router-dom';

function Footer() {
    return (
        <>
            <div className='footer_section'>
                <div className='copy_right_text'>
                    © Copyright {new Date().getFullYear()} Glidescale. All Rights Reserved.
                </div>
                <div className='footer_menu'>
                    <NavLink className='footer_menu_link' to="terms-and-conditions">Terms and Conditions</NavLink>
                    <NavLink className='footer_menu_link' to="privacy-policy">Privacy Policy</NavLink>
                </div>
            </div>
        </>
    )
}

export default Footer;