import React, { useEffect, useState } from 'react';
import '../../pages/Payment/PaymentMethod.scss';
import { IoWalletOutline } from 'react-icons/io5';
import { BsCurrencyDollar } from "react-icons/bs";
import { CommonModal } from '../../components/Modal/CommonModal';
import AddAmount from './AddAmount';
import { PaymentTransactionListAction } from '../../redux/Slices/CommonSlice';
import { Pagination } from '../../components/Hooks/Pagination';
import NoDataFound from '../../components/NoDataFound/NoDataFound';
import moment from 'moment';
import Withdrawal from './Withdrawal';


function Wallet() {
    const [addMoneyModalShow, setAddMoneyModalShow] = useState(false);
    const [withdrawalModalShow, setWithdrawalModalShow] = useState(false)
    const [walletDetailsData, setWalletDetailsData] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [perPageCount, setPerPageCount] = useState(10);
    const [searchString, setSearchString] = useState("");
    const [statusUpdate, setStatusUpdate] = useState(1)
    const [userType, setUserType] = useState(0);

    useEffect(() => {
        let payload = {
            searchText: searchString,
            perPage: perPageCount,
            page: currentPage,
            type: Number(userType)
        }
        PaymentTransactionListAction(payload, (response) => {
            if (response?.status === true) {
                setWalletDetailsData(response?.data)
            }
            // setLoader(false)
        })();
    }, [PaymentTransactionListAction, perPageCount, currentPage, searchString, userType, statusUpdate])

    return (
        <>
            <div className='top_heading_button'>
                <h1 className='main_heading_top'>Wallet</h1>
            </div>

            <div className='wallet_page_section'>
                <div className='row'>
                    <div className='choose_method_section'>
                        <div className='card_wallet'>
                            <div className='icon_balance_heading'>
                                <div className='wallet_icon'>
                                    <IoWalletOutline />
                                </div>
                                <div>
                                    <h3> Available Balance</h3>
                                    <h1 className='balance'>${walletDetailsData?.wallet_balances?.available_bal ? walletDetailsData?.wallet_balances?.available_bal : 0}</h1>
                                </div>
                            </div>
                            <div className='add_amount_btn'>
                                <button type='button' className='btn_blue' onClick={() => setAddMoneyModalShow(true)}>Add Amount</button>
                                <button type='button' className='btn_blue' onClick={() => setWithdrawalModalShow(true)}>Withdrawal</button>
                            </div>
                        </div>

                        <div className='three_card_withdrawal'>
                            <div className='three_card_section'>
                                <div className='card_three_box'>
                                    <div className='icon_heading_price'>
                                        <div className='icon_box earn'>
                                            <BsCurrencyDollar />
                                        </div>
                                        <div className='heading_three_text'>
                                            <h3>Earned</h3>
                                            <p>${walletDetailsData?.wallet_balances?.earn_bal ? walletDetailsData?.wallet_balances?.earn_bal : 0}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className='card_three_box deposit'>
                                    <div className='icon_heading_price'>
                                        <div className='icon_box deposit'>
                                            <BsCurrencyDollar />
                                        </div>
                                        <div className='heading_three_text'>
                                            <h3>Deposited</h3>
                                            <p>${walletDetailsData?.wallet_balances?.deposit_bal ? walletDetailsData?.wallet_balances?.deposit_bal : 0}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className='card_three_box withdrawal'>
                                    <div className='icon_heading_price'>
                                        <div className='icon_box withdrawal'>
                                            <BsCurrencyDollar />
                                        </div>
                                        <div className='heading_three_text'>
                                            <h3>Withdrawn</h3>
                                            <p>${walletDetailsData?.wallet_balances?.withdrawal_bal ? walletDetailsData?.wallet_balances?.withdrawal_bal : 0}</p>
                                        </div>
                                    </div>
                                </div>

                                {walletDetailsData?.wallet_balances?.campaign_deduction ?
                                    <div className='card_three_box campaign_deduction'>
                                        <div className='icon_heading_price'>
                                            <div className='icon_box campaign_deduction'>
                                                <BsCurrencyDollar />
                                            </div>
                                            <div className='heading_three_text'>
                                                <h3> Campaigns </h3>
                                                <p>${walletDetailsData?.wallet_balances?.campaign_deduction ? walletDetailsData?.wallet_balances?.campaign_deduction : 0}</p>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                        </div>
                    </div>

                    <div className='col-12'>
                        <div className='choose_method_section'>
                            <div className='payment_history_heading'>
                                <h2> Transaction History</h2>
                                <div className='filter_payment_history'>
                                    <div className='filter_select_number filter_search_common'>
                                        <select className='common_input_filed' onChange={(e) => setUserType(e.target.value)}>
                                            <option value={0}> All types </option>
                                            <option value={3}> Earn </option>
                                            <option value={1}> Deposit </option>
                                            <option value={2}> Withdrawal </option>
                                            <option value={4}> Campaign deduction </option>
                                            <option value={5}> Campaign Refund </option>
                                            <option value={6}> Platform fee </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className='history_table table-responsive'>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th> User</th>
                                            <th> Date & Time</th>
                                            <th> Type </th>
                                            <th> Transaction ID </th>
                                            <th className='text-end'> Amout </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            walletDetailsData?.transaction_list?.length > 0 ?
                                                walletDetailsData?.transaction_list?.map((value, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            <div className='history_user_name_profile'>
                                                                <div className='profile_img_history'>
                                                                    <img
                                                                        src={value?.user_id?.profile ? value?.user_id?.profile : "/images/dummy-image.jpg"}
                                                                        alt={value?.user_id?.user_name}
                                                                    />
                                                                </div>
                                                                <div className='name_use'> {value?.user_id?.user_name} </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            {moment(value?.createdAt).format("DD MMM'YY")} at {moment(value?.createdAt).format("LT")}
                                                        </td>
                                                        <td>
                                                            {value?.type == 1 ? "Addmoney" :
                                                                value?.type == 2 ? "Withdrawal" :
                                                                    value?.type == 3 ? "Earn money" :
                                                                        value?.type == 4 ? "Campaign deduction" :
                                                                            value?.type == 5 ? "Campaign Refund" :
                                                                                value?.type == 6 ? "Platform fee" :
                                                                                    null
                                                            }
                                                            {/* {value?.type == 1 ? "Addmoney" : value?.type == 2 ? "Withdrawl" : value?.type == 3 ? "Earn money" : "Campaign deduction"} */}
                                                        </td>
                                                        <td>ID Number: #{value?.order_id}</td>
                                                        <td className={`${value?.type == 1 || value?.type == 3 || value?.type == 5 ? "credit" : "debit"} text-end`}>
                                                            {value?.type == 1 || value?.type == 3 || value?.type == 5 ? "+" : "-"}${value?.amount ? value?.amount : 0}
                                                        </td>
                                                    </tr>
                                                ))
                                                :
                                                <tr>
                                                    <td colSpan={5}>
                                                        <NoDataFound />
                                                    </td>
                                                </tr>
                                        }
                                    </tbody>
                                </table>

                                <div className='pagination_entries_section'>
                                    <div className='entries_text'>
                                        Showing {(currentPage - 1) * Number(walletDetailsData?.pagination?.perPage) + 1} of {walletDetailsData?.pagination?.totalItems} entries
                                    </div>
                                    {walletDetailsData?.transaction_list?.length > 0 ?
                                        <Pagination
                                            className="pagination-bar"
                                            currentPage={currentPage}
                                            totalCount={walletDetailsData?.pagination?.totalItems}
                                            pageSize={walletDetailsData?.pagination?.perPage}
                                            onPageChange={(page) => setCurrentPage(page)}
                                        />
                                        :
                                        null
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <CommonModal
                show={addMoneyModalShow}
                onHide={() => setAddMoneyModalShow(false)}
                modalClass="delete_Campaigns_modal"
                body={
                    <AddAmount
                        heading="Add money to my wallet"
                        onClickButton1={() => setAddMoneyModalShow(false)}
                        btn1="Submit"
                        setStatusUpdate={setStatusUpdate}
                    />
                }
            />

            <CommonModal
                show={withdrawalModalShow}
                onHide={() => setWithdrawalModalShow(false)}
                modalClass="delete_Campaigns_modal"
                body={
                    <Withdrawal
                        heading="Withdrawal"
                        onClickButton1={() => setWithdrawalModalShow(false)}
                        btn1="Submit"
                        setStatusUpdate={setStatusUpdate}
                    />
                }
            />

        </>
    )
}

export default Wallet;