import React, { useEffect, useState } from 'react';
import '../../pages/CMS/cms.scss';
import { CMSFrontViewAction } from "./../../redux/Slices/CommonSlice"

function AboutUs() {
    const [CmsViewData, setCmsViewData] = useState()
    useEffect(() => {
        CMSFrontViewAction("about-us", (response) => {
            if (response?.status === true) {
                setCmsViewData(response.data)
            } else {
            }
        })();
    }, [CMSFrontViewAction])

    return (
        <>
            <div className='top_heading_button about_page'>
                <h1 className='main_heading_top'> {CmsViewData?.title} </h1>
            </div>

            <div className='about_us_page_content mt-0'>
                <div className='second_section_about pt-5'>
                    {(CmsViewData && Object.keys(CmsViewData)?.length > 0) &&
                        <p dangerouslySetInnerHTML={{ __html: CmsViewData?.description }} />
                    }
                </div>
                {/* <div className='row align-items-center g-4'>
                    <div className='col-md-6 col-12'>
                        <div className='img_right_side_about'>
                            <img src="/images/about-us.svg" alt="" />
                        </div>
                    </div>
                    <div className='col-md-6 col-12'>
                        <h2>OUR MISSION</h2>
                        <h3>Lorem ipsum dolor sit amet.</h3>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero corporis veniam repellat sed sapiente est doloribus pariatur quidem repellendus laborum minima officiis maiores, modi possimus velit vitae a aut fugiat, placeat iste quia consequatur ipsa! Distinctio minus quidem incidunt, aspernatur id, tenetur suscipit perspiciatis omnis iure delectus impedit nostrum voluptate.</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Facilis, ducimus magni debitis quisquam pariatur, necessitatibus molestias temporibus, praesentium possimus consequatur aperiam adipisci consequuntur asperiores autem? Aliquid porro placeat modi quam.</p>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias repellendus ipsam libero, laboriosam aut ea!</p>
                    </div>
                </div> */}

                {/* <div className='second_section_about'>
                    <div className='row align-items-center g-4'>
                        <div className='col-12'>
                            <div className='center_text_about'>
                                <p> {CmsViewData?.description} </p>
                            </div>
                        </div>
                        <div className='col-md-6 col-12 order-2 order-md-1'>
                            <div className='left_side_list'>
                                <div className='list_first_about'>
                                    <div className='goal_undo'></div>
                                    <div>
                                        <h5>Lorem ipsum dolor sit amet consectetur.</h5>
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias repellendus ipsam libero, laboriosam aut ea! laboriosam aut ea!</p>
                                    </div>
                                </div>
                                <div className='list_first_about'>
                                    <div className='goal_undo'></div>
                                    <div>
                                        <h5>Lorem ipsum dolor sit amet consectetur.</h5>
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.  laboriosam aut ea!</p>
                                    </div>
                                </div>
                                <div className='list_first_about'>
                                    <div className='goal_undo'></div>
                                    <div>
                                        <h5>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia.</h5>
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias repellendus ipsam libero, laboriosam aut ea! laboriosam aut ea!</p>
                                    </div>
                                </div>
                                <div className='list_first_about'>
                                    <div className='goal_undo'></div>
                                    <div>
                                        <h5>Lorem ipsum dolor.</h5>
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias repellendus ipsam libero, laboriosam aut ea! laboriosam aut ea!</p>
                                    </div>
                                </div>
                                <div className='list_first_about'>
                                    <div className='goal_undo'></div>
                                    <div>
                                        <h5>Lorem ipsum dolor sit amet consectetur adipisicing elit.</h5>
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias repellendus ipsam libero, laboriosam aut ea! laboriosam aut ea!</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-6 col-12 order-1 order-md-2'>
                            <div className='img_right_side_about'>
                                <img src="/images/about-us.svg" alt="" />
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </>
    )
}

export default AboutUs;